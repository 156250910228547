@import "../../../assets/styles/global.scss";

.a-notifications-settings-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  .a-notifications-settings-content {
    padding-right: 8px;
    @media (max-width: 600px) {
      padding-right: 0px;
      width: 100%;
      margin-bottom: 16px;
    }
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 15px;
      margin: 0px 0px 4px;
      padding-top: 4px;
    }
    &__subtitle {
      color: var(--secondary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 17px;
    }
    .a-notifications-settings-group-options-wrapper {
      margin-top: 0.5rem;
      .a-notifications-settings-group-options-list {
        display: flex;
        flex-direction: column;
        .a-notifications-settings-group-options-item {
          display: flex;
          align-items: center;
          span {
            padding-left: 0.5rem;
            padding-top: 2px;
          }
        }
      }
    }

    &__email-frequency {
      margin-top: 20px;
      font-size: 15px;
      font-family: "TTCommons-Regular";
      color: var(--primary-font-color);

      &__title {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
      }

      &__options {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 18px;

        li {
          display: flex;
          align-items: center;
          margin-right: 35px;
          span {
            margin: 0px;
          }
        }

        &__name {
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 16px;
          letter-spacing: 0;
          line-height: 17px;
          margin-left: 10px;
          padding: 3px 0 0 0;
        }
      }
    }
  }
  .a-notifications-settings-controls {
    min-width: 300px;
    display: flex;
    white-space: nowrap;
    .a-notifications-settings-controls-row {
      display: flex;
      align-items: center;
      box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
      border-radius: 100px;
      padding: 18px 20px 13px;
      span {
        color: var(--primary-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 19px;
        + div {
          margin-top: -3px;
          margin-left: 12px;
        }
      }
      + div {
        margin-left: 10px;
      }
    }
  }
}
