.m-user-widget-details {
  position: relative;
  &__back {
    position: absolute;
    top: 0px;
    left: 15px;
    cursor: pointer;
    z-index: 1;
    span {
      color: #002f6f;
      font-family: "TTCommons-Medium";
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      margin-left: 5px;
    }
    img {
      transform: rotate(180deg);
      width: 12px;
      height: 8px;
    }
  }
  &__loader {
    width: 80%;
    margin: 0 auto;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    margin: 0 auto;
    &__title {
      margin: 9px 0 0 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
    }
    &__types {
      display: flex;
      gap: 10px;
      &__type {
        padding: 3px 10px 0 10px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        height: 20px;
        border-radius: 12.5px;
        background-color: var(--gray-100);
      }
    }
    &__year {
      margin: 3px 0 0 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
    }
    &__country {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        height: 20px;
      }
      span {
        display: inline-block;
        height: 20px;
        padding-top: 4px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
    &__intro {
      margin: 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }
    &__academic {
      margin-top: 10px;
      &__header {
        margin-bottom: -3px;
        display: block;
        color: #002f6f;
        font-family: "TTCommons-Medium";
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
      }
      &__title {
        margin: 13px 0 0 0;
        display: block;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
      }
      &__value {
        text-align: center;
        display: block;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
    &__linkedin {
      margin-top: 10px;
      height: 50px;
      svg {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
    &__chat {
      margin-top: 10px;
      button {
        width: fit-content !important;
        height: 60px !important;
        font-size: 22px !important;
        padding: 2px 20px 0px 20px !important;
        max-width: 350px !important;
      }
    }
  }
  &__no-user {
    text-align: center;
    font-family: "TTCommons-Medium";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    color: var(--primary-font-color);
  }
}
