@import "../../../assets/styles/global.scss";

.m-dashboard-link {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    margin-bottom: 30px;
    margin-top: 15px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 8px 0px;
    @media (max-width: 767px) {
      margin: 4px 0px;
    }
    &__link {
      margin: 0px 4px;
      color: var(--secondary-font-color);
      @include TTCommonsRegular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      text-decoration: none;
      @media (max-width: 1400px) {
        font-size: 15px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }
      svg {
        margin: 0px 0px 0px 5px;
        width: 12px;
      }
    }
  }
}
