@import "../../../assets/styles/global.scss";

.m-my-topic-middle-post-wrapper {
  border: 2px solid #d1dde5;
  border-radius: 10px;
  padding: 15px;
  &:focus-within {
    border-color: rgb(0, 12, 61) !important;
  }
  &:hover {
    border-color: rgb(191, 206, 216);
  }
  &__text {
    > div {
      width: 100%;
      > div {
        padding: 0;
      }
    }
    textarea {
      font-size: 18px;
      line-height: 19px;
      color: var(--primary-font-color) !important;
      font-family: "TTCommons-Regular";
      font-weight: 500;
      word-break: break-word;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        border: none;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.2);
        &:hover {
          background: #555;
        }
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--field-label-color) !important ;
        opacity: 1 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--field-label-color) !important;
        opacity: 1 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--field-label-color) !important;
        opacity: 1 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--field-label-color) !important;
        opacity: 1 !important;
      }
    }
  }
  .m-my-topic-middle-post-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5rem;
    position: relative;
    button {
      height: 35px;
    }
  }
}
.m-my-topic-middle-post-wrapper-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.m-my-topic-middle-no-post {
  text-align: center;
  font-family: "TTCommons-Medium";
  font-size: 22px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
  h2 {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    margin: 16px auto 8px;
    max-width: 440px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
  h3 {
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    margin: 0 auto;
    font-weight: 500;
    margin: 0px auto 30px;
    max-width: 440px;
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  svg {
    max-width: 100%;
    margin-top: 15px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.delete-post-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__submit-button {
    margin-top: 18px;
  }

  &__cancel-link {
    cursor: pointer;
    color: $grey-text-color;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 17px;
    text-decoration: none;
    margin-top: 30px;
    border-bottom: 1px solid $grey-text-color;
    text-align: center;
  }
}
