@import "../../../../assets/styles/global.scss";

.o-sign-out-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__submit-button {
    margin-top: 24px;
  }

  &__cancel-link {
    color: $grey-text-color;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 17px;
    text-decoration: none;
    margin-top: 24px;
    border-bottom: 1px solid $grey-text-color;
    text-align: center;
  }
}
