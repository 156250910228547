@import "/src/assets/styles/global.scss";
.m-preview-container {
  &__item {
    padding: 10px;
    @include radius(12px);
    box-shadow: var(--box-shadow);
  }
  &__searchbox {
    margin-bottom: 10px;
    margin-top: -65px;
    @media (max-width: 992px) {
      margin-top: 0;
    }
  }
  &__contacts-list {
    margin-top: 15px;
    max-height: calc(100vh - 172px);
    overflow-y: auto;
    padding: 10px;
    @media (max-width: 992px) {
      max-height: calc(100vh - 280px);
    }
    scrollbar-width: thin !important;
    -ms-overflow-style: auto;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: $color-grey transparent; /* scroll thumb and track */
    transition: all ease-in-out 1000ms;
    // @media(max-width:1024px){
    //   max-height: 420px;
    // }
    &:hover {
      overflow-y: auto;
      &::-webkit-scrollbar {
        // width: 5px; /* width of the entire scrollbar */
        height: 50px;
        transition: all ease-in-out 1000ms;
      }
    }
    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
      height: 50px;
      transition: all ease-in-out 1000ms;
    }
    &::-webkit-scrollbar-track {
      background: transparent !important; /* color of the tracking area */
      border-radius: 50px;
      width: 7px;

      -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px
        0px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-grey !important; /* color of the scroll thumb */
      border-radius: 50px; /* roundness of the scroll thumb */
      //border: none;
      height: 150px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0;
    }
  }
}
