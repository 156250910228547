@import "../../../assets/styles/global.scss";
.a-discover-network-card {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  overflow: hidden;
  padding: 14px;
  background: #fff;
  height: 172px;
  width: 288px;
  @media (min-width: 321px) {
    width: 325px;
  }
  &--large {
    height: 185px;
  }
  &__profile {
    display: flex;
    &__pic {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    &__info {
      margin: 0 0 0 10px;
      width: 200px;
      position: relative;
      &--small {
        width: 180px;
      }
      &__line_one {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        span:nth-child(1) {
          margin: 0 7px 0 0 !important;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 92px;
          @media (min-width: 321px) {
            max-width: 128px;
          }
        }
        span:nth-child(2) {
          margin: 0 0 2px 0 !important;
          border-radius: 10.5px;
          background: var(--primary-color);
          opacity: 0.4;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
          color: #ffffff;
          display: flex;
          align-items: center;
          padding: 4px 7px 0px 7px;
          min-height: 20px;
          @include TTCommonsRegular;
        }
      }
      &__line_two {
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 0 4px 0 !important;
        width: 100%;
      }
      &__line_three {
        display: flex;
        align-items: center;
        width: 100%;
        img {
          width: 28px;
          height: 20px;
          border-radius: 2px;
        }
        span {
          height: 15px;
          margin: 0 0 0 10px !important;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &__body {
    margin: 8px 0 5px 0;
    p {
      color: var(--primary-font-color);
      margin: 0 0 0 0;
      font-size: 15px;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--large {
      p {
        min-height: 34px;
        white-space: initial !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  &__bottom {
    &__line-one {
      display: flex;
      justify-content: space-between;
      &__view-details {
        cursor: pointer;
        span {
          color: #002f6f;
          font-family: "TTCommons-Medium";
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
          margin-right: 5px;
        }
        img {
          width: 12px;
          height: 8px;
        }
      }
      &__active-text {
        color: var(--secondary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        display: flex;
        &__bullet {
          width: 6px;
          height: 6px;
          background-color: #3ec460;
          border-radius: 50%;
          margin: 4px 5px 4px 0px;
        }
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      margin-top: 6px;
      &__request-meeting {
        color: var(--primary-color);
        margin: 2px 0 0 12px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        cursor: pointer;
      }
      &--center {
        justify-content: center;
      }
    }
  }
}
