@import "../../../assets/styles/global.scss";

.m-widget-webinars {
  width: 100%;
  margin: 0;
  position: relative;
  min-height: 300px;
  &__listing {
    max-width: 670px;
    margin: 0 auto;
    padding: 15px 0 0px 0px;

    > div {
      padding: 20px 30px;
      margin-bottom: 10px;
      position: relative;
      @media (max-width: 767px) {
        padding: 16px;
      }
    }

    &__overlay {
      background-color: rgba(241, 244, 247, 0.9);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      box-shadow: var(--box-shadow);
    }

    .m-widget-webinars-no-item {
      p {
        color: var(--primary-font-color);
        text-align: center;
        font-family: "TTCommons-Medium";
        font-size: 22px;
        line-height: 21px;
        font-weight: 500;
        letter-spacing: 0;
        word-break: break-word;
        margin: 22px 0 18px;
      }
    }

    .m-widget-webinars-item-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      .m-widget-webinars-item-img-left {
        max-width: 114px;
        min-width: 114px;
        padding-right: 30px;
        @media (max-width: 767px) {
          max-width: 80px;
          min-width: 80px;
          padding-right: 16px;
        }
        img {
          width: 100%;
          vertical-align: middle;
        }
      }

      .m-widget-webinars-item-content-wrapper {
        width: 100%;
        @media (max-width: 767px) {
          width: calc(100% - 100px);
        }
        h2 {
          @include TTCommonsMedium;
          color: var(--primary-font-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          margin: 0 0 4px 0px;
          padding-right: 148px;
          padding-top: 4px;
          @media (max-width: 767px) {
            padding-right: 0px;
          }
        }
        h3 {
          @include TTCommonsRegular;
          color: var(--primary-font-color);
          font-weight: 400;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0 0 8px 0px;
          padding-right: 148px;
          padding-right: 148px;
          padding-top: 4px;
          @media (max-width: 767px) {
            padding-right: 0px;
          }
        }
        .m-widget-webinars-item-content-wrapper-duration-time {
          display: flex;
          align-items: center;
          margin: 0 0 3px 0px;
          padding-right: 148px;
          @media (max-width: 767px) {
            padding-right: 0px;
          }
          svg {
            width: 15px;
            height: 15px;
            margin-right: 6px;
            vertical-align: middle;
            fill: var(--secondary-font-color);
            margin-top: -5px;
          }
          span {
            @include TTCommonsRegular;
            font-weight: 400;
            color: var(--secondary-font-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
            margin-bottom: 3px;
          }
        }
        .m-widget-webinars-item-content-wrapper-webinar-presenter {
          display: flex;
          align-items: center;
          margin: 0 0 4px 0px;
          padding-right: 148px;
          @media (max-width: 767px) {
            padding-right: 0px;
          }
          img {
            width: 13px;
            height: 13px;
            margin-right: 6px;
            vertical-align: middle;
            fill: var(--secondary-font-color);
            margin-top: -5px;
          }
          span {
            @include TTCommonsRegular;
            color: var(--secondary-font-color);
            font-size: 15px;
            font-weight: 400;
            line-height: 15px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-bottom: 3px;
          }
        }
        .m-widget-webinars-item-content-wrapper-description {
          text-align: left;
          font-size: 15px;
          line-height: 17px;
          color: var(--primary-font-color);
          @include TTCommonsRegular;
          margin: 0;
          p {
            text-align: left;
            font-size: 15px;
            line-height: 17px;
            color: var(--primary-font-color);
            @include TTCommonsRegular;
            margin: 0;
          }
        }
      }
      button {
        position: absolute;
        right: 0;
        top: 14px;
        width: 100%;
        max-width: 145px;
        font-family: "TTCommons-Medium";
        @media (max-width: 767px) {
          position: initial;
          margin-left: 80px;
          margin-top: 8px;
        }
      }
    }

    &--overlay {
    }
  }
}
