@import "../../../assets/styles/global.scss";

.a-single-select-card {
  display: flex;
  flex-direction: column;
  &__header {
    color: var(--field-label-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    display: block;
    padding: 5px 0 0px 0px;
    margin: 0 0 12px 0;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 12px;
  }
  &__options {
    display: flex;
    justify-content: center;
    margin: -6px;
  }
}
