@import "../../../assets/styles/global.scss";

.m-resources {
  position: relative;
  margin-bottom: 48px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 0 auto;
  }
  @media (max-width: 1170px) {
    padding-bottom: 0px;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &__no-saved-resources {
    width: 100% !important;
    text-align: center;
    h3 {
      margin: 19px 0 9px 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
    }
    h4 {
      margin: 0 0 0 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
    }
  }
  &__header {
    margin: 30px 0 30px 0;
    @media (max-width: 992px) {
      margin: 30px 0 15px 0;
    }
    h2 {
      margin: 0 auto;
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      max-width: calc(100% - 580px);
      @media (max-width: 890px) {
        max-width: 100%;
      }
    }
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
    &__input {
      margin-right: 10px;
      height: 50px;
      width: 285px;
      input {
        border-radius: 45px;
        background-color: #fff;
        padding: 16.65px 20px 12.65px !important;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 19px;
        box-sizing: border-box;
        min-height: 50px;
        margin: 0;
        height: 50px;
      }
    }
    &__controls {
      height: 50px;
      position: relative;
      @media (max-width: 767px) {
        display: flex;
        align-items: center;
      }
      svg {
        cursor: pointer;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        img {
          vertical-align: middle;
        }
        &:focus {
          box-shadow: 0 0 0px 3px var(--light-gray-color) !important;
        }
      }
      &__clear {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 15px;
        left: 63px;
        border-radius: 0 !important;
        letter-spacing: 0;
        font-size: 18px;
        line-height: 16px;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--secondary-font-color);
        cursor: pointer;
        padding-top: 3px;
        white-space: nowrap;
        transition: all 0.3s;
        @media (max-width: 767px) {
          position: initial;
          margin-bottom: 0;
          margin-left: 8px;
        }
        &:focus {
          color: var(--link-color) !important;
          border-color: var(--link-color) !important;
          opacity: 0.9 !important;
          outline: 2px solid var(--primary-font-color) !important;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &__body {
    &__saved {
      &__title {
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        margin: 20px auto 0;
        justify-content: center;
        h2 {
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          margin: 0;
        }
        &__view-all {
          margin-left: 14px;
          span {
            cursor: pointer;
            color: var(--link-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            border-bottom: 1px solid var(--link-color);
          }
        }
      }
      &__body {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 30px -10px 0;
        > div {
          padding: 0 10px;
          margin: 0 0 20px 0;
          width: 25%;
          @media (max-width: 1366px) {
            width: 33.33%;
          }
          @media (max-width: 767px) {
            width: 50%;
            padding: 0 8px;
          }
          @media (max-width: 480px) {
            width: 100%;
          }
          > a {
            > div {
              padding: 16px 16px 24px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: -14px;
    @media (max-width: 890px) {
      position: initial;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
    }
    &__back-to-categories {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 0 15px 20px;
      width: 187px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      text-decoration: none;
      transition: all 0.3s;
      span {
        margin-left: 3.5px;
        height: 16.5px;
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
      img {
        width: 13px;
      }
      &--saved {
        width: 92px;
      }
      &:hover,
      &:focus {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
      }
      @media (max-width: 890px) {
        position: absolute;
        left: 0;
        top: -15px;
      }
      @media (max-width: 890px) {
        position: initial;
      }
    }
    &__to-saved-service {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 20px;
      width: 285px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
      }
      span {
        margin-inline: 5px;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        height: 17px;
      }
    }
  }
  &__filters {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    flex-direction: column;
    @media (max-width: 767px) {
      margin: 16px 0 16px 0;
    }
    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        margin-bottom: 8px;
      }
      &__input {
        margin-right: 10px;
        height: 50px;
        width: 285px;
        input {
          border-radius: 45px;
          background-color: #fff;
          padding: 16.65px 20px 12.65px !important;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 19px;
          box-sizing: border-box;
          min-height: 50px;
          margin: 0;
          height: 50px;
        }
      }
      &__controls {
        height: 50px;
        position: relative;
        @media (max-width: 767px) {
          display: flex;
          align-items: center;
        }
        svg {
          cursor: pointer;
        }
        &__clear {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 15px;
          left: 63px;
          @media (max-width: 767px) {
            position: initial;
            margin-bottom: 0;
            margin-left: 8px;
          }
          a {
            letter-spacing: 0;
            font-size: 18px;
            line-height: 16px;
            color: var(--secondary-font-color);
            font-family: "TTCommons-Medium";
            font-weight: 500;
            text-decoration: none;
            border-bottom: 2px solid var(--secondary-font-color);
            cursor: pointer;
            padding-top: 3px;
            white-space: nowrap;
            border-radius: 0 !important;
            transition: all 0.3s;
            &:focus {
              color: var(--link-color) !important;
              border-color: var(--link-color) !important;
              opacity: 0.9 !important;
              outline: 2px solid var(--primary-font-color) !important;
            }
            &:hover {
              opacity: 0.8;
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          img {
            vertical-align: middle;
          }
          &:focus {
            box-shadow: 0 0 0px 3px var(--light-gray-color) !important;
          }
        }
      }
    }
    &__type {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      @media (max-width: 767px) {
        margin-top: 8px;
        width: 100%;
      }
      &__items {
        max-width: 565px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;
        position: relative;
        align-items: center;
        @media (max-width: 767px) {
          gap: 8px;
        }
        &__heading {
          position: absolute;
          top: 8px;
          left: -58px;
          @media (max-width: 767px) {
            left: initial;
            top: -28px;
            position: initial;
          }
          span {
            color: var(--field-label-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
          }
        }
        > div {
          &:first-child {
            span {
              color: var(--field-label-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              position: relative;
              top: 2px;
            }
          }
        }
        &__item {
          height: 35px;
          width: 129px;
          border-radius: 40px;
          background-color: #ffffff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @media (max-width: 767px) {
            width: auto;
            padding: 0 12px;
            min-width: 80px;
          }
          span {
            height: 14px;
            color: var(--primary-font-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            text-transform: capitalize;
          }
          &--active {
            background-color: var(--primary-color);
            span {
              color: #ffffff;
            }
          }
        }
        &__clear-all {
          position: initial;
          font-size: 18px;
          line-height: 16px;
          color: var(--secondary-font-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          text-decoration: none;
          border-bottom: 2px solid var(--secondary-font-color);
          cursor: pointer;
          transition: all 0.3s;
          @media (max-width: 319px) {
            top: 203px;
          }
          @media (min-width: 320px) {
            top: 203px;
          }
          @media (min-width: 449px) {
            top: 150px;
          }
          @media (min-width: 594px) {
            top: 100px;
          }
          @media (min-width: 767px) {
            top: 9px;
            right: -76px;
          }
          span {
            letter-spacing: 0;
            font-size: 18px;
            line-height: 16px;
            color: var(--secondary-font-color);
            font-family: "TTCommons-Medium";
            font-weight: 500;
            text-decoration: none;
            border-bottom: 2px solid var(--secondary-font-color);
            cursor: pointer;
            padding-top: 3px;
            transition: all 0.3s;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    &__sort {
      margin-top: 16px;
      @media (min-width: 1366px) {
        margin-top: 0px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
      > div > div {
        margin-bottom: 0px !important;
      }
      > div > div > div > div {
        width: 150px !important;
        min-height: 35px !important;
        box-shadow: var(--box-shadow);
        border: none !important;
      }
    }
  }
  &__listing {
    &__loaders {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 40px;
    }
    &__cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 30px -10px 75px;
      @media (max-width: 1366px) {
        margin-bottom: 50px;
      }
      @media (max-width: 992px) {
        margin-bottom: 30px;
      }
      > div {
        padding: 0 10px;
        margin: 0 0 20px 0;
        width: 25%;
        @media (max-width: 1366px) {
          width: 33.33%;
          margin-bottom: 0;
        }
        @media (max-width: 767px) {
          width: 50%;
          padding: 0 8px;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }
}
