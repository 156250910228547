@import "/src/assets/styles/global.scss";
.init-chat-options {
  animation: fadeIn 0.4s;
  -webkit-animation: fadeIn 0.4s;
  -moz-animation: fadeIn 0.4s;
  -o-animation: fadeIn 0.4s;
  -ms-animation: fadeIn 0.4s;
  z-index: 999;
  position: absolute;
  right: 0;
  min-width: 250px;
  padding: 0 20px;
  text-align: center;
  border-radius: 10px 1px 10px 10px;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  top: 50px;
  color: var(--primary-font-color);
}

.init-chat-options__item {
  cursor: pointer;
  color: #000c3d;
  @include TTCommonsMedium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 22px 0 18px;
  margin: 0;
  @media (max-width: 767px) {
    padding: 16px 0 14px;
  }
  span {
    border-bottom: 2px solid transparent;
  }
  &:hover {
    span {
      border-color: #000c3d;
    }
  }
  + h5 {
    border-top: 2px solid #eaeef3;
  }
}
