@import "../../../assets/styles/global.scss";

.m-checklist-task {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;

    &__main {
      display: flex;
      width: 100%;
      align-items: flex-start;
      > div:first-child {
        > span {
          padding: 0px;
          margin: 0px 15px 0px 0px;
          padding: 0;
          height: 20px;
          width: 20px;
          border-radius: 100px;
          outline: 0;
          box-shadow: none;
          background-color: #f1f4f7;
          > svg {
            display: block;
            width: 24px;
            height: 24px;
            color: #000485;
          }
          span {
            svg {
              display: none;
            }
          }
        }
      }
      &__text {
        font-size: 18px;
        font-family: "TTCommons-Regular";
        line-height: 19px;
        padding: 2px 0 0 0;
        width: 100%;
        color: var(--primary-font-color);
        padding-right: 8px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        @media (max-width: 767px) {
          width: calc(100% - 35px);
          padding-right: 0;
          margin: 0 0 8px 0;
        }
        h5 {
          margin: 0;
          font-size: 18px;
          font-family: "TTCommons-Regular";
          line-height: 19px;
          padding: 0px 0 0 0;
          width: 100%;
          color: var(--primary-font-color);
          padding-right: 8px;
          font-weight: 400;
        }
        input[type="text"] {
          font-size: 18px;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          line-height: 19px;
          padding: 0px 0 0 0;
          color: var(--primary-font-color);
          outline: 0 !important;
          width: 100%;
          border: 0;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #8895a9 !important;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #8895a9 !important;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #8895a9 !important;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #8895a9 !important;
          }
        }

        &--gray {
          color: var(--secondary-font-color);
        }

        &--primary {
          color: var(--primary-font-color);
        }

        &--completed {
          text-decoration: line-through;
        }
      }

      &__link {
        font-size: 18px;
        line-height: 16px;
        color: var(--link-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--link-color) !important;
        cursor: pointer;
        padding-top: 3px;
        padding-left: 0;
        padding-right: 0;
        transition: all 0.3s;
        opacity: 1 !important;
        visibility: visible !important;
        border-bottom: 0 !important;
        text-decoration: underline;
        text-underline-offset: 4px;
        line-height: 22px;
        &:hover {
          opacity: 0.9;
        }
      }
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .MuiRadio-root {
      padding: 0;
      margin: 0 15px 0px 0;
    }

    .MuiIconButton-colorPrimary {
      &:hover {
        background-color: transparent;
      }
    }
    .MuiIconButton-root {
      &:hover {
        background-color: transparent;
      }
    }

    & span.Mui-checked + div {
      text-decoration: line-through;
      text-decoration-color: var(--secondary-color);
    }

    &__button-edit {
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      cursor: pointer;
      padding: 0;
      min-width: auto;
      box-shadow: none;
      flex: 0 0 35px;
      height: 35px;
      width: 35px;
      border-radius: 100px;
      background-color: var(--gray-100);
      transition: all 0.3s;
      opacity: 1;
      margin-left: 12px;
      &:hover {
        background-color: #eaeef3;
      }
    }

    &__button-close {
      padding: 2px 0 0 0;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      cursor: pointer;
      min-width: auto;
      box-shadow: none;
      flex: 0 0 35px;
      height: 35px;
      width: 35px;
      border-radius: 100px;
      background-color: var(--gray-100);
      transition: all 0.3s;
      opacity: 1;
      margin-left: 12px;
      &:hover {
        background-color: #eaeef3;
      }
    }

    &__button-add {
      margin-left: 12px;
      button {
        background: #fff !important;
        box-shadow: var(--box-shadow) !important;
        color: var(--primary-font-color);
        width: auto;
        padding: 18px 20px 13px;
        height: auto;
        min-height: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Medium";
        border-radius: 100px;
        text-decoration: none;
        transition: all 0.3s;
        display: inline-block;
        text-transform: initial;
        white-space: nowrap;
        margin-right: 0;
        @media (max-width: 767px) {
          padding: 12px 12px 8px;
          min-height: 35px;
        }
        img {
          margin: -4px 0 0 8px;
          transform: none;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
        }
      }
    }
  }
}
