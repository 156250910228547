@import "../../../assets/styles/global.scss";

.o-prospect-parent-invite-sign-up {
  @media (max-width: 992px) {
    margin: 0;
  }
  > div {
    @media (max-width: 992px) {
      padding: 0;
    }
  }
}
