@import "../../../assets/styles/global.scss";

.o-privacy-policy {
  margin-top: 30px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &__header {
    h2 {
      color: var(--primary-font-color);
      justify-content: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      font-size: 30px;
      margin: 0px 0px 15px 0px;
      min-height: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      padding-top: 0;
      @media (min-width: 993px) {
        display: none;
      }
      @media (max-width: 1367px) {
        font-size: 22px;
        line-height: 20px;
        min-height: auto;
      }
    }
    + div {
      @media (min-width: 993px) {
        margin-top: 65px !important;
      }
      @media (min-width: 1367px) {
        margin-top: 85px !important;
      }
    }
  }
}
