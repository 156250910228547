.m-webinar-item {
  > div {
    padding: 20px !important;
    position: relative;
    @media (max-width: 767px) {
      padding: 16px !important;
    }
  }
  &__root {
    display: flex;
    position: relative;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    &__image {
      width: 100%;
      min-height: 128px;
      background-color: #f1f4f7;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      overflow: hidden;
      max-width: 252px;
      min-width: 252px;
      @media (max-width: 767px) {
        width: 100% !important;
        margin-bottom: 16px;
        min-width: 100%;
      }
      &__webinar {
        max-width: 100%;
        max-height: 128px;
        vertical-align: middle;
      }
      &__tag {
        border: 2px solid var(--gray-100);
        border-radius: 100px;
        background-color: #ffffff;
        padding: 6px 16px 1px;
        display: flex;
        align-items: center;
        min-height: 29px;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        position: absolute;
        top: 12px;
        left: 12px;
      }
    }
    &__description {
      width: 50%;
      padding: 0 0px 0 16px;
      @media (max-width: 767px) {
        width: 100%;
        padding-left: 0px;
        width: calc(100% - 260px);
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 16;
      }
      &__rss {
        position: absolute;
        top: -12px;
        left: -12px;
        width: 15px;
      }
      &__title {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        word-break: break-word;
        margin: 0 0 5px 0;
      }
      &__admin-tag {
        margin: 0 0 5px 0;
        width: fit-content;
        span {
          border-radius: 12.5px;
          background-color: var(--gray-100);
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          padding: 4px 8px 2px 8px;
          min-width: 69px;
        }
      }
      &__summary {
        p {
          margin: 0 0 0 0;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          color: var(--secondary-font-color);
          word-wrap: break-word;
        }
      }
    }
    &__info {
      width: 100%;
      border-left: 2px solid var(--light-gray-color);
      margin-left: 16px;
      padding-left: 16px;
      min-width: 240px;
      max-width: 240px;
      @media (max-width: 600px) {
        width: 100% !important;
        border-left: none !important;
        border-top: 2px solid var(--light-gray-color) !important;
        max-width: 100%;
        padding-left: 0;
        margin-left: 0;
        padding-top: 16px;
        margin-top: 16px;
      }
      &__header {
        color: var(--primary-font-color);
        font-family: "TTCommons-DemiBold";
        font-size: 18px;
        font-weight: 600;
        line-height: 19px;
        @media (max-width: 600px) {
          margin-top: 0;
          display: flex;
          justify-content: center;
        }
      }
      &__sub-header {
        color: var(--primary-font-color);
        font-family: "TTCommons-DemiBold";
        font-size: 18px;
        font-weight: 600;
        line-height: 19px;
        @media (max-width: 600px) {
          display: flex;
          justify-content: center;
        }
      }
      &__presenter {
        margin: 4px 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          justify-content: center;
        }
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        margin: 4px 0;
        span {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          background-color: #d1dde5;
          width: 30px;
          height: 30px;
          border-radius: 100px;
          -webkit-box-pack: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      &__attachment {
        a {
          display: block;
          width: fit-content;
          color: var(--primary-font-color);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 16px;
          font-family: "TTCommons-Regular";
          text-decoration: none;
          border-bottom: 2px solid var(--primary-font-color);
        }
        @media (max-width: 600px) {
          display: flex;
          justify-content: center;
        }
      }
      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        &__with-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin-right: 4px;
            height: 21px;
          }
          img {
            margin-top: -5px;
          }
        }
      }
    }
  }
}
