@import "../../../assets/styles/global.scss";
.m-perspective-item {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    @include flex-row;
  }
  &__text {
    font-family: "TTCommons-Regular";
    font-size: 22px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    @include text-color($color-white);
    margin-bottom: 20px;
    padding-left: 15px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
      margin: 0 0 12px 0;
    }
  }
}
