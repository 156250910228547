@import "/src/assets/styles/global.scss";
.a-fileupload-container {
  display: flex;
  align-items: center;
  &__preview {
    .picture {
      @include backgroundImage;
      height: 50px;
      width: 50px;
      min-width: 50px;
    }
    margin-right: 14px;
  }
  &__button {
    @include radius(45px);
    @include wh(100%, 37px);
    @include pointer;
    @include flex-row-align-center;
    @include py($top: 3px);
    max-width: 120px;
    background-color: var(--primary-color);

    &-icon {
      margin-right: 5px;
    }
    &-text {
      @include text-color($color-white);
      @include TTCommonsMedium;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
  &__close_button {
    margin-left: 0.75rem;
    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}
.hidden-input {
  position: absolute;
  top: -99999px;
  left: -342432px;
  visibility: hidden;
  opacity: 0;
}

.a-fileupload-cropper-modal-wrapper {
  .a-fileupload-cropper-modal-img-wrapper {
    margin-top: 1rem;
    > div {
      > div {
        > div:nth-child(1) {
          opacity: 0.5 !important;
        }
        > div:nth-child(2) {
          opacity: 1 !important;
          background: transparent;
        }
      }
    }
  }
  .a-fileupload-cropper-modal-button-wrapper {
    margin-top: 2rem;
  }
}
