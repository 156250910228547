:root {
  /* --primary-color: #000485; */
  --secondary-color: #f9fbfd;
  --background-color: #f9fbfd;
  --primary-font-color: #000c3d;
  --secondary-font-color: #607790;
  --light-gray-color: #d1dde5;
  --gray-100: #f1f4f7;
  --gray-200: #ebf1f7;
  --gray-300: #fafbfd;
  --gray-400: #e5eaf7;
  --white-color: #fff;
  --main-color: #f76155;
  --link-color: #0030b5;
  --field-label-color: #8094ab;
  --box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  --box-shadow-hover: 0 1px 14px 3px #d1dde5;
}

* {
  box-sizing: border-box;
}

/* body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-error {
  color: #f7645f;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  display: block;
  margin: 4px 0 0 0;
}

.p-error-profile {
  color: #f7645f;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  display: block;
  margin: 4px 0 0 24px;
}

.kommunicate-custom-iframe {
  left: 100vw;
  margin-left: -100px;
}
.km-iframe-dimension-no-popup {
  @media (max-width: 600px) {
    transform: translateX(100px);
  }
  @media (min-width: 600px) {
    transform: translateX(-325px);
  }
}

.sb-avatar__image {
  object-fit: cover;
}
