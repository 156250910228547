@import "/src/assets/styles/global.scss";
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.chat-container__deactivated {
  height: calc(100% - 70px) !important;
}
.init-chat-container {
  display: flex;
  flex-wrap: wrap;

  .chats-section-wrapper {
    margin-bottom: 25px;
    padding: 20px 20px;
    width: 100%;
    max-width: calc(100% - 320px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 8px;
    &__full {
      max-width: 100% !important;
    }
    @media (max-width: 1367px) {
      max-width: 100%;
      flex-basis: 100%;
      margin-bottom: 16px;
    }
    @media (max-width: 767px) {
      padding: 16px 16px;
    }
  }

  .nmessage-profile-section {
    &.fullchat-view-mode {
      display: none !important;
    }
    width: 100%;
    max-width: 320px;
    padding-left: 16px;
    @media (max-width: 1367px) {
      max-width: 100%;
      flex-basis: 100%;
      padding-left: 0;
      height: 400px;
    }
    @media (max-width: 992px) {
      margin-bottom: 16px;
    }
    @media (max-width: 767px) {
      margin-bottom: 0;
      height: calc(100vh - 192px);
    }
    > div {
      overflow-x: hidden;
      overflow-y: auto;
      background-color: white;
      border-radius: 8px;
      max-width: 100%;
      @media (max-width: 767px) {
        min-height: initial;
        max-height: initial;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #bfced8;
    position: relative;
    margin: 7px 0;
    display: inline-block;
    width: 100%;
    .date {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      width: 200px;
      font-size: 16px;
      text-align: center;
      font-family: "TTCommons-Regular";
      color: #f76155 !important;
      min-width: 90px;
      font-weight: 400;
    }
  }
  .message-section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &__top-bar {
      display: flex;
      align-items: center;
      padding: 0;
      .chat-user-avatar {
        display: none;
        cursor: pointer;
        @media screen and (max-width: 992px) {
          display: block;
          margin-inline: 5px;
        }
        > div {
          > div {
            > div {
              > div {
                span {
                  span {
                    @media screen and (max-width: 992px) {
                      margin-left: 0;
                      font-size: 18px !important;
                      display: flex !important;
                      align-items: center;
                      justify-content: center;
                      line-height: 42px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .chat-user-menu-icon {
        display: flex;
        align-items: center;
        .view-toggler {
          margin-right: 12px;
        }
        .chat-user-menu-icon-v3-wrapper {
          display: flex;
          align-items: center;
          box-shadow: none;
          height: 40px;
          width: 40px;
          border-radius: 100px;
          background-color: var(--gray-100);
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          transition: all 0.3s;
          &:hover {
            background-color: #eaeef3;
          }
        }
      }

      span {
        cursor: pointer;
        display: none;
        @media screen and (max-width: 992px) {
          display: flex;
          align-items: center;
          margin-left: -10px;
        }
      }
      h4 {
        color: var(--primary-font-color);
        font-size: 22px;
        font-weight: 500;
        font-family: "TTCommons-Medium";
        margin: 0;
        padding: 0;
        @media screen and (max-width: 768px) {
          padding: 0 0 0px 0;
        }
        width: 100%;
        height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__close {
        border: 0;
        padding: 0;
        display: flex;
      }
    }

    .loading-indicator-container {
      display: block;
      padding-left: 10px;
      &__img {
        width: 30px;
        height: 30px;
      }
    }
    .chat-container {
      padding-bottom: 8px;
      width: 100%;
      position: relative;
      height: 50vh;
      max-height: 50vh;
      @media screen and (min-height: 500px) {
        height: 33vh;
        max-height: 33vh;
      }
      @media screen and (min-height: 550px) {
        height: 38vh;
        max-height: 38vh;
      }
      @media screen and (min-height: 600px) {
        height: 43vh;
        max-height: 43vh;
      }
      @media screen and (min-height: 720px) {
        height: 52vh;
        max-height: 52vh;
      }
      + div {
        margin-top: auto;
      }
    }

    p {
      width: 70%;
    }
  }
}
@media screen and (max-width: 1200px) {
  .nmessage-profile-section {
    > div {
      margin-top: 0 !important;
      > div:last-child {
        width: 100%;
        margin: 0 auto;
        max-width: 300px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .init-chat-container {
    width: 100%;
    height: 100%;
    .nmessage-profile-section {
      max-width: 100%;
      margin-top: 16px;
      padding-left: 0;
      @media (max-width: 767px) {
        margin-top: 0;
        > div {
          max-width: 100%;
          margin: 0;
        }
      }
      h4 {
        font-size: 20px;
        color: var(--primary-font-color);
      }
      p {
        font-size: 18px;
      }
      .message-section {
        width: 100%;
        .text-area {
          textarea {
            height: 300px;
          }
        }
      }
      .user-profile__container {
        width: 100%;
        margin-top: 30px;
        border: none;
        max-width: 100%;
      }
    }
  }
}
