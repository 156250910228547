@import "../../../assets/styles/global.scss";

.m-auth-invite-sign-up {
  height: 100%;
  min-height: 100vh;
  padding: 48px 16px 114px 16px;
  @media (max-width: 767px) {
    padding: 48px 16px 48px 16px;
  }
  &__content {
    max-width: 325px;
    margin: 0 auto;
    text-align: center;
    &__unlogo {
      img {
        max-width: 234px;
        max-height: 70px;
        vertical-align: middle;
      }
    }
    &__sitelogo {
      @include flex-row;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      h3 {
        @include TTCommonsRegular;
        color: var(--primary-font-color);
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-weight: 400;
        margin: 4px 8px 0 0;
      }
      img {
        max-width: 100px;
      }
    }
    h2 {
      color: var(--primary-font-color);
      @include TTCommonsMedium;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center;
      margin: 30px 0 16px;
    }
    p {
      margin: 0;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
    }
    h5 {
      color: var(--primary-font-color);
      @include TTCommonsMedium;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 30px 0;
      @media (max-width: 767px) {
        margin: 24px 0;
      }
      a {
        color: var(--link-color);
        margin-left: 4px;
        border-bottom: 1px solid var(--link-color);
        text-decoration: none;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include flex-row;
      align-items: flex-start;
      justify-content: center;
      li {
        margin: 0 19px;
        div {
          @include flex-column;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          color: var(--primary-font-color);
          a {
            width: 65px;
            height: 65px;
            min-width: 65px;
            margin: 0 auto 6px;
          }
        }
      }
    }
    &__formfield {
      margin-top: 30px;
      @media (max-width: 767px) {
        margin-top: 24px;
      }
      > div {
        + div {
          margin-top: 16px;
        }
      }
      &__keepMeSignin {
        margin: 30px 0 !important;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        &--T-C {
          display: flex;
          justify-content: flex-start;
          text-align: left;
          span {
            margin: 0;
            letter-spacing: 0;
            font-size: 15px;
            line-height: 16px;
            font-family: "TTCommons-Regular";
            color: var(--secondary-font-color);
            font-weight: 400;
            padding: 0;
            a {
              margin: 0;
              letter-spacing: 0;
              font-size: 15px;
              line-height: 16px;
              font-family: "TTCommons-Regular";
              color: var(--secondary-font-color);
              font-weight: 400;
              padding: 0;
              text-decoration: none;
              border-bottom: 2px solid #8094ab;
              display: inline-block;
            }
          }
        }
      }
      button {
        margin: 24px 0 30px;
        @media (max-width: 767px) {
          margin: 0px 0 24px;
        }
      }

      a {
        color: var(--secondary-font-color);
        @include TTCommonsRegular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        text-decoration: none;
        border-bottom: 2px solid var(--secondary-font-color);
      }
    }
  }
}
