@import "../../../assets/styles/global.scss";

.m-widget-chatlist {
  &__title {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    letter-spacing: 0;
    font-size: 30px;
    text-align: center;
    margin: 16px 0 25px 0;
    line-height: 25px;
    padding-top: 16px;
    @media (max-width: 930px) {
      font-size: 22px;
      line-height: 22px;
      align-items: center;
      margin: 0 0 16px 0;
    }
  }

  &__student-cards {
    &__wrapper {
    }
  }

  &__empty {
    text-align: center;
    width: 100%;
    h3 {
      font-size: 24px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      color: var(--primary-font-color);
    }
    svg {
      max-width: 100%;
    }
  }
}
