@import "../../../assets/styles/global.scss";

.a-widget-topic-card {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 15px 15px;
  position: relative;
  background-color: #fff;
  &__profile {
    display: flex;
    align-items: center;
    position: absolute;
    left: 15px;
    top: 15px;
    @media (max-width: 1280px) {
      left: 12px;
      top: 16px;
    }
    &__image {
      width: 65px;
      height: 65px;
      min-width: 65px;
      border-radius: 100px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        width: 62px;
        height: 62px;
        min-width: 62px;
      }
      div {
        width: 100% !important;
        height: 100% !important;
      }
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &__collapse-wrapper {
    overflow: hidden;
    margin-bottom: 0;
    max-height: 65px;
    transition: max-height 0.5s ease-out;
    @media (max-width: 767px) {
      max-height: 62px;
    }
    &--open {
      max-height: 3000px;
      transition: max-height 0.5s ease-in;
      img {
        transform: rotate(180deg);
      }
    }
    &__description {
      margin: 0;
      padding-left: 76px;
      padding-top: 4px;
      padding-bottom: 0;
      @media (max-width: 767px) {
        padding-left: 70px;
        padding-top: 0;
      }
      h2 {
        @include TTCommonsMedium;
        margin: 0 0 4px 0;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 17px;
        color: var(--primary-font-color);
        padding-top: 2px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &--tag {
        background-color: var(--gray-100);
        padding: 4px 10px 0px;
        display: inline-block;
        border-radius: 45px;
        font-size: 14px;
        height: 20px;
        margin-bottom: 6px;
      }
      &__body_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        p {
          margin: 0;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          display: flex;
          align-items: flex-start;
          min-height: 34px;
          padding-right: 15px;
          padding-top: 2px;
        }
        img {
          cursor: pointer;
          width: 24px;
          position: absolute;
          right: 15px;
          top: 62px;
          margin-top: 0;
        }
      }
    }
    &__btns-wrapper {
      display: flex;
      justify-content: center;
      margin: 15px auto 0px auto;
    }
  }
}
