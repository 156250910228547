.o-calculator {
  margin-top: 35px;
  margin-bottom: 35px;

  &__title {
    font-family: "TTCommons-DemiBold";
    color: var(--primary-font-color);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 20px 0;
    text-align: center;
  }

  &__export {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    max-width: 230px;
    margin-left: auto;
    margin-top: -50px;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }
    span {
      margin-right: 10px;
    }
  }

  h2 {
    font-family: "TTCommons-Medium";
    color: var(--primary-font-color);
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 30px 0px 10px 0px;
    img {
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }

  h2:first-child {
    margin: 0px 0px 10px 0px;
  }
}
