@import "../../../assets/styles/global.scss";
.a-accordion-flush {
  margin-bottom: 15px;
  &__accordion-item {
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    overflow: hidden;
    padding: 22px 20px 18px;
    background-color: var(--white-color);
    width: 100%;
    @media (max-width: 1280px) {
      padding: 18px 16px 14px 16px;
    }
    &__accordion-header {
      margin: 0 !important;
      display: flex;
      &__accordion-button {
        width: 100%;
        background: transparent;
        border: 0;
        text-align: left;
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        display: flex;
        cursor: pointer;
        align-items: center;
        &__accordion-arrow {
          width: 24px;
          vertical-align: middle;
          margin-left: auto;
          transform: rotate(0deg);
          transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          margin-right: 6px;
          @media (max-width: 767px) {
            width: 20px;
          }
          &--open {
            transform: rotate(180deg);
            transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }
      }
    }
    &__accordion-collapse {
      -webkit-transition: max-height 0.5s;
      -moz-transition: max-height 0.5s;
      -ms-transition: max-height 0.5s;
      -o-transition: max-height 0.5s;
      transition: max-height 0.5s;
      overflow: hidden;
      max-height: 0;
      &--open {
        -webkit-transition: max-height 1s;
        -moz-transition: max-height 1s;
        -ms-transition: max-height 1s;
        -o-transition: max-height 1s;
        transition: max-height 1s;
        max-height: 1000px;
      }
      &__accordion-body {
        margin-top: 8px;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        color: var(--primary-font-color);
        a {
          color: var(--primary-font-color);
          text-decoration: underline;
        }
      }
    }
  }
}

.a-accordion-flush__accordion-item__accordion-star {
  padding-left: 1rem;
  svg {
    width: 18px;
    height: 18px;
  }
}
