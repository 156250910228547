.middle-section {
  position: relative;
  .title-withbutton {
    display: flex;
    align-items: center;
    margin: 15px 0 30px 0 !important;
    min-height: 29px;
    .title-primary {
      margin: 0 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      width: auto;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: 500;
      display: inline-block;
      font-family: "TTCommons-Medium";
    }
    .pending-request {
      position: relative;
      margin-left: 5px;
      button {
        height: auto;
        border-radius: 45px;
        width: auto;
        padding: 4px 10px 2px 10px;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        min-width: 164px;
      }

      .card-wrapper {
        position: absolute;
        z-index: 10;
        background: #fff;
        top: 40px;
        right: 0px;
        .request-box {
          overflow: visible;
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          position: relative;
          padding: 0 21px 0 25px;
          min-width: 569px;
          .card-content {
            padding: 24.5px 0 24.5px 0;
            position: relative;
            + .card-content {
              border-top: 1px solid #e5eaf7;
            }
          }
        }
        .request {
          display: flex;
          align-items: center;
          .MuiAvatar-root {
            margin: 0 15px 0 0;
            width: 40px;
            height: 40px;
          }
          .request-header {
            width: calc(100% - 250px);
            .user-title {
              color: var(--primary-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              margin: 0 0 2px 0;
              padding-top: 4px;
            }
            .panel-sub-title {
              color: var(--primary-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
            }
          }
          .request-actions {
            margin-left: auto;
            button {
              border-radius: 45px;
              padding: 4px 10px 2px;
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              text-align: center;
              height: auto;
              min-width: 84px;
              .content {
                min-width: auto;
              }
              + button {
                margin-left: 20px;
              }
            }
          }
          + .request {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid #e5eaf7;
          }
        }
      }
    }
  }
  .posts {
    &__empty {
      margin: 18px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        max-width: 100%;
      }
      h3 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        max-width: 440px;
      }
    }
  }
}
