@import "../../../assets/styles/global.scss";

.m-saved-post-list-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  &__header {
    display: flex;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    min-height: 40px;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
    &--back-btn {
      background: #fff !important;
      box-shadow: var(--box-shadow) !important;
      color: var(--field-label-color);
      width: auto;
      padding: 18px 20px 12px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Regular";
      border-radius: 100px;
      text-decoration: none;
      transition: all 0.3s;
      display: inline-block;
      text-transform: none;
      position: absolute;
      left: 0;
      @media (max-width: 767px) {
        position: initial;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
      }
      img {
        width: 8px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        vertical-align: middle;
        margin: -2px 8px 0 0;
      }
      img {
        width: 8px;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        vertical-align: middle;
        margin: -2px 8px 0 0;
      }
    }
    &--title {
      margin: 0 auto;
      font-family: "TTCommons-Regular";
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      padding-top: 4px;
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 14px;
      }
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    &--title {
      margin: 0 auto;
      font-family: "TTCommons-Regular";
      color: var(--primary-dark-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      padding-top: 4px;
      @media (max-width: 767px) {
        width: 100%;
        margin-top: 14px;
      }
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__empty {
    color: var(--primary-font-color);
    text-align: center;
    margin: 0;
    svg {
      max-width: 100%;
      margin: 18px 0;
    }
    h2 {
      color: var(--primary-dark-color);
      font-family: TTCommons-Medium;
      font-size: 22px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      margin: 0px auto 8px;
      max-width: 440px;
    }
    h3 {
      color: var(--primary-dark-color);
      font-family: TTCommons-Regular;
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 19px;
      text-align: center;
      margin: 0px auto;
      max-width: 440px;
      font-weight: 400;
    }
  }
  &__dashboard-saved-content {
    max-width: 670px;
    margin: 0px auto;
  }
}
