@import "./breakpoints.scss";
@import "./fonts.scss";
@import "./flex.scss";
@import "./colors.scss";
@import "./mixins.scss";
@import "./typographies.scss";
@import "./common.scss";
@import "./toastify.scss";

:root {
  // Scrollbar style - START
  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
    height: 8px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1dde5 !important; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 10px !important;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 0px;
  }
  // Scrollbar style - END
}

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden !important;
  background: #fafbfd !important;
}

// responsive-css
@media (min-width: 1367px) {
  .ShowResponsiveDiv {
    display: none;
  }
}

.dashboard-post-slider-container {
  .slick-dots {
    bottom: 0;
    position: initial;
    margin: 8px 0 0 0;
    li {
      margin: 0;
      width: 15px;
      height: 10px;
      button {
        padding: 0;
        width: 15px;
        height: 10px;
        &:before {
          width: 15px;
          height: 10px;
          width: 15px;
          height: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          color: #000485 !important;
          opacity: 0.1 !important;
        }
      }
      &.slick-active {
        button {
          &:before {
            color: #000485 !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .slick-arrow {
    &:before {
      color: #01047f;
      opacity: 1;
    }
  }
}

.dashboard-promote-wrapper {
  margin-bottom: 15px;
  .slick-prev {
    left: 3px;
    z-index: 1;
    &:before {
      color: var(--light-gray-color);
      font-size: 20px;
    }
  }
  .slick-next {
    right: 3px;
    z-index: 1;
    &:before {
      color: var(--light-gray-color);
      font-size: 20px;
    }
  }
  .slick-slider {
    border-radius: 10px;
    background-color: #e4f2ff;
    box-shadow: var(--card-box-shadow);
    padding-bottom: 15px;

    .slick-dots {
      li {
        button {
          width: 8px;
          height: 8px;
          min-width: 8px;
          background: var(--gray-outline-color);
          border-radius: 100px;
          margin: 0;
          :before {
            display: none;
          }
        }
        &.slick-active {
          button {
            &:before {
              color: var(--primary-font-color);
              border-radius: 100px;
            }
          }
        }
      }
    }
    .slick-slide {
      position: relative;
      > div {
        width: 100%;
        > div {
          > div {
            padding: 12px 16px;
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            margin: 0;
            padding-bottom: 5px;
          }
        }
        .card-wrapper {
          .slider-card {
            .card-content {
              width: 100%;
            }
          }
        }
      }
    }
    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    .slick-dots {
      bottom: 0;
      position: relative;
      li {
        margin: 0 2px;
        width: auto;
        height: auto;
        button {
          padding: 0;
          width: 8px;
          height: 8px;
          &:before {
            width: 8px;
            height: 8px;
            line-height: 10px;
            font-size: 8px;
            color: #d3e0f6;
            opacity: 1;
          }
        }
      }
    }
  }
}

.link-content {
  // .link-content was not getting injected in the linkify with module.scss file for the atom hence adding to global.scss
  color: var(--link-color);
  text-decoration: none;
  cursor: pointer;
  padding-top: 3px;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
}

.m-webinar-item__root__description__summary__text-control {
  // Keeping this style out of the modularized styles as the component is an injectable
  // Due to which the style is not able to access the context in child component
  cursor: pointer;
  line-height: 16px;
  color: var(--link-color);
  font-family: "TTCommons-Medium";
  font-weight: 500;
  border-bottom: 1px solid var(--link-color) !important;
  font-size: 15px;
}

#widget-expanded-section {
  // Keeping this to provide a unique id based styling for an edge case
  width: 100%;
  [class^="WidgetEmployment_m-widget-employment__wrapper__right-sidebar__card__scholarships__entry__header__title"] {
    width: auto !important;
  }
}

.info-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
