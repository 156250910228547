@import "/src/assets/styles/global.scss";

.m-emp-table {
  margin-top: 22px;
  &__header {
    display: flex;
    flex-wrap: wrap;
    h2 {
      @media (min-width: 993px) {
        display: none;
      }
    }
    &__filter {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      &__item {
        width: 213px;
        &--widget {
          width: 180px;
        }
        @media (max-width: 767px) {
          width: 100% !important;
        }
        &--clear {
          width: 213px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 60px;
          @media (max-width: 767px) {
            height: 50px;
            width: 100% !important;
            justify-content: center;
          }
          span {
            cursor: pointer;
            font-family: "TTCommons-Medium";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            color: var(--secondary-font-color);
            height: 16px;
            margin-inline: 10px;
          }
        }
      }
    }
    > div {
      &:nth-child(1) {
        display: none;
        @media (max-width: 1180px) {
          width: 100%;
        }
      }
      &:nth-child(2) {
        display: flex;
        > div {
          margin-right: 12px;
        }
        @media (max-width: 1180px) {
          padding: 15px;
          width: 100%;
        }
        > div {
          @media (max-width: 1180px) {
            float: left;
            margin: 0 12px 8px 0;
          }
          @media (max-width: 767px) {
            float: none;
            width: 100%;
            margin: 0 0px 16px 0;
          }
        }
      }
    }
  }
  &__body {
    margin-top: 30px;
  }
}
