@import "../../../assets/styles/global.scss";

.m-dashboard-post {
  > div {
    padding: 20px 30px;
    @media (max-width: 1367px) {
      padding: 20px 30px;
      margin-bottom: 15px;
    }
    @media (max-width: 767px) {
      padding: 16px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      margin-bottom: 8px;
    }
    &__posted-info {
      display: flex;
      align-items: center;
      &__posted-image {
        margin-right: 8px;
      }
      &__posted-details {
        display: flex;
        align-items: center;
        padding: 4px 0 0 0;
        @media (max-width: 767px) {
          flex-wrap: wrap;
          flex-direction: column;
          align-items: flex-start;
          padding-top: 0;
        }
        span:first-child {
          display: block;
          padding: 0px 0px 0px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
        }
        span:nth-child(2) {
          display: block;
          color: $grey-text-color;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 15px;
          letter-spacing: 0;
          margin-left: 6px;
          @media (max-width: 767px) {
            margin-left: 0px;
          }
        }
      }
    }
    &__menu {
      margin-left: auto;
      position: relative;
      &__icon {
        height: 40px;
        width: 40px;
        border-radius: 45px;
        background-color: var(--gray-100);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        transition: all 0.3s;
        &:hover {
          background-color: #eaeef3;
        }
        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
          fill: var(--primary-font-color);
        }
      }
      &__items {
        position: absolute;
        border-radius: 10px 1px 10px 10px;
        background-color: rgb(255, 255, 255);
        box-shadow: var(--box-shadow);
        width: 265px;
        padding: 0px 20px;
        transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        z-index: 1;
        right: 0;
        margin-top: 8px;
        @media (max-width: 767px) {
          width: 200px;
        }
        span {
          cursor: pointer;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0px;
          text-align: center;
          text-decoration: none;
          display: block;
          padding: 22px 0px 16px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          line-height: 18px;
          -webkit-box-pack: center;
          justify-content: center;
          @media (max-width: 767px) {
            padding: 18px 0px 14px;
          }
          > span {
            padding: 0;
            border-bottom: 2px solid transparent;
            display: inline-block;
          }
          &:hover {
            border-bottom-color: var(--primary-dark-color);
          }
        }
        span:nth-child(2) {
          border-top: 2px solid rgb(234, 238, 243);
        }
      }
    }
  }

  &__content {
    width: 100%;
    margin-bottom: 8px;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
    &__pdf {
      cursor: pointer;
      width: 100%;
      position: relative;
      &__tag {
        border: 2px solid var(--gray-100);
        border-radius: 100px;
        background-color: #ffffff;
        color: var(--primary-font-color);
        padding: 6px 16px 1px;
        display: flex;
        align-items: center;
        min-height: 29px;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        position: absolute;
        top: 12px;
        left: 12px;
      }
      &__image {
        width: 100% !important;
        height: 100%;
        object-fit: contain;
        object-position: center;
        vertical-align: middle;
      }
    }
    &__shared-media {
      cursor: pointer;
      width: 100%;
      position: relative;
      &__image {
        width: 100% !important;
        height: 100%;
        object-fit: contain;
        object-position: center;
        vertical-align: middle;
      }
      &__play-icon {
        width: 70px !important;
        height: 70px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: initial;
      }
    }
    &__interstride-media {
      cursor: pointer;
      width: 100%;
      position: relative;
      &__video {
        width: 100%;
        vertical-align: middle;
      }
      &__play-icon {
        width: 70px !important;
        height: 70px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: initial;
      }
    }
    &__image-media {
      width: 100%;
      &__image {
        width: 100%;
        vertical-align: middle;
      }
    }
  }

  &__title {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 8px 0 0px 0;
    padding-top: 4px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 19px;
    }
  }
  &__description {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    white-space: break-spaces;
    margin-top: 8px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 17px;
    }
    &--large-font {
      font-family: "TTCommons-Regular";
      font-size: 20px;
      line-height: 22px;
    }
  }
  &__read-more-less {
    margin: 10px 0px;
    padding: 10px 12px;
    height: 35px !important;
    cursor: pointer;
    height: 35px;
    border-radius: 45px;
    background-color: #e8eef2;
    min-width: fit-content;
    height: 19px;
    width: 76px;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__modal {
    margin-top: 60px;
    margin-bottom: 30px;
    &__video {
      width: 100% !important;
      height: 100% !important;
      vertical-align: middle;
    }
    &__link-video {
      width: 100% !important;
      height: 100% !important;
      vertical-align: middle;
      iframe {
        border: 0;
        width: 100%;
        min-height: 450px;
        vertical-align: top;
      }
    }
  }
}
