@import "../../../assets/styles/global.scss";
.o-dashboard-container {
  display: flex;
  flex-direction: column;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 0 auto;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    background: var(--primary-color);
    height: 120px;
    left: 0;
    right: 0;
    z-index: 0;
  }
  &__back-section {
    margin-top: 80px;
    position: relative;
    @media (max-width: 1367px) {
      margin-top: 75px;
    }
    @media (max-width: 767px) {
      margin-top: 48px;
    }
  }

  &__main-section {
    > div {
      > div:first-child {
        @media (max-width: 1367px) {
          max-width: 35%;
          flex-basis: 35%;
          position: initial !important;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      > div:nth-child(2) {
        @media (max-width: 1367px) {
          max-width: 65%;
          flex-basis: 65%;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      > div:last-child {
        @media (max-width: 1367px) {
          display: none;
        }
      }
    }
    @media (min-width: 768px) {
      > div {
        > div:first-child {
          height: fit-content;
          position: -webkit-sticky; /* Safari */
          position: sticky;
          top: 118px;
        }

        > div:last-child {
          height: fit-content;
          position: -webkit-sticky; /* Safari */
          position: sticky;
          top: -180px;
        }
      }
    }
  }
}
