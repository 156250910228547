.discover-page {
  &__search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px 0;
    @media (max-width: 860px) {
      flex-wrap: wrap;
      margin: 12px 0 12px 0;
    }
    &__box {
      display: flex;
      align-items: center;
      &__button {
        margin-left: 10px;
        width: 50px;
        height: 50px;
        &:focus {
          opacity: 0.9;
          box-shadow: 0 0 0px 3px var(--gray-outline-color) !important;
          border-radius: 45px;
        }
        img {
          width: 50px;
          height: 50px;
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
    &__filter {
      display: flex;
      align-items: center;
      padding-left: 30px;
      @media (max-width: 860px) {
        width: 100%;
        padding: 0;
        justify-content: center;
        margin: 12px 0 0 0;
        flex-wrap: wrap;
      }
      label {
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        color: var(--field-label-color);
        margin: 0 15px 0 0;
        padding-top: 4px;
      }
      &__content {
        display: flex;
        align-items: center;
        &__item {
          border-radius: 40px;
          background-color: #ffffff;
          box-shadow: var(--box-shadow);
          padding: 10px 15px 4px;
          min-height: 35px;
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          text-align: center;
          min-width: 96px;
          margin: 4px 10px 4px 0;
          text-transform: capitalize;
          transition: all 0.3s;
          cursor: pointer;
          text-decoration: none;
          &--active {
            background-color: var(--primary-color);
            color: #fff;
          }
          &:hover {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
          }
          &:focus {
            box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
          }
        }
      }
      &__clear {
        margin-left: 10px;
        a {
          font-size: 18px;
          line-height: 16px;
          color: var(--secondary-font-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          text-decoration: none;
          border-bottom: 2px solid var(--secondary-font-color);
          cursor: pointer;
          padding-top: 3px;
          transition: all 0.3s;
        }
      }
    }
  }
  &__listing {
    max-width: 1015px;
    margin: 0 auto;
    &__topics {
      &__item {
        cursor: pointer;
        &__content {
          display: flex;
          align-items: center;
          gap: 18px;
          &__middle {
            flex-grow: 1;
            &__title {
              color: var(--primary-font-color);
              font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              margin: 0 0 8px 0;
            }
            &__description {
              color: var(--secondary-font-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              margin: 0 0 4px 0;
            }
            &__contact {
              display: flex;
              align-items: center;
              gap: 10px;
              &__member {
                font-family: "TTCommons-Medium";
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--primary-font-color);
                margin-right: 15px;
                margin-top: 3px;
              }
            }
            &__type {
              span {
                display: inline-block;
                font-family: "TTCommons-Regular";
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 17px;
                color: var(--link-color);
                margin-right: 15px;
                margin-top: 4px;
              }
            }
            &__author {
              display: flex;
              align-items: center;
              position: relative;
              > span {
                color: var(--field-label-color);
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                text-align: center;
                margin-right: 8px;
                margin-top: 3px;
                &:first-child {
                  white-space: nowrap;
                }
              }
              a {
                cursor: pointer;
                display: flex;
                align-items: center;
                text-decoration: none;
                .MuiAvatar-root {
                  width: 25px;
                  height: 25px;
                  min-width: 25px;
                  margin-right: 8px;
                }

                span {
                  font-family: "TTCommons-Regular";
                  font-size: 15px;
                  letter-spacing: 0;
                  line-height: 17px;
                  color: var(--primary-font-color);
                  margin-right: 20px;
                  margin-top: 3px;
                  margin-left: 8px;
                }
              }
              &__popup {
                position: absolute;
                top: 30px;
                right: 20px;
                width: 325px;
                z-index: 30;
              }
            }
          }
          &__action {
            opacity: 0;
            visibility: none;
            pointer-events: none;
            transition: all 0.5s;
            button {
              height: 50px;
              width: 144px;
              background: var(--primary-color);
              color: #ffffff;
            }
            &--active {
              opacity: 1;
              visibility: initial;
              pointer-events: initial;
            }
          }
        }
        &--active {
          > div {
            box-shadow: var(--box-shadow-hover);
          }
        }
        &:hover {
          > div {
            box-shadow: var(--box-shadow-hover);
          }
          .discover-page__listing__topics__item__content__action {
            opacity: 1;
            visibility: initial;
            pointer-events: initial;
          }
        }
      }
    }
  }
}
