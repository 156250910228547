@import "../../../assets/styles/global.scss";

.o-topics-container {
  margin-top: 30px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  .o-topics-header {
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: 1367px) {
      margin-bottom: 15px;
    }
    @media (max-width: 767px) {
      justify-content: center;
    }
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
    &-title {
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 0 15px 0;
      @media (max-width: 600px) {
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
      }
    }
    &-switch {
      display: flex;
      justify-content: center;
    }
  }
  .o-topics-body {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    @media (max-width: 1170px) {
      margin-bottom: 0px;
    }
    .o-topics-body-no-recommended-following-topics {
      span {
        font-family: "TTCommons-Medium";
        font-size: 22px;
        line-height: 21px;
      }
    }
  }
}
