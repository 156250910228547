@import "../../../../assets/styles/global.scss";

.o-webinars {
  margin-top: 30px;
  margin-bottom: 100px;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  &__title {
    color: var(--primary-font-color);
    font-family: "TTCommons-DemiBold";
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin: 0 0 15px 0;
  }
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
    button {
      padding: 18px 16px 14px;
      @media (max-width: 767px) {
        width: auto;
        padding: 18px 20px 15px;
        height: 44px;
      }
      @media (max-width: 370px) {
        width: 100%;
      }
      + button {
        margin-left: 10px;
        @media (max-width: 370px) {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }
  }
  &__filters {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__search {
      display: flex;
      align-items: center;
      align-items: center;
      width: 100%;
      justify-content: center;
      > div {
        width: 100%;
        max-width: 285px;
      }
      input {
        height: 50px;
        padding: 16.65px 20px 12.65px !important;
      }
      svg {
        cursor: pointer;
        margin-left: 10px;
      }
    }
    &__categories {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      h4 {
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        color: #8094ab;
        margin: 0 15px 0 0;
        padding-top: 4px;
        @media (max-width: 500px) {
          width: 100%;
          text-align: center;
          margin: 0 0 0 0;
        }
      }
      &__tag {
        border-radius: 40px;
        background-color: #ffffff;
        box-shadow: var(--box-shadow);
        padding: 10px 15px 4px;
        min-height: 35px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        min-width: 96px;
        margin: 4px 10px 4px 0;
        text-transform: capitalize;
        transition: all 0.3s;
        cursor: pointer;
        text-decoration: none;
        &--active {
          background-color: var(--primary-color);
          color: #ffffff;
        }
        &:hover {
          background-color: var(--primary-color);
          color: #ffffff;
        }
      }
    }
    &__clear-all {
      margin-top: 10px;
      span {
        font-size: 18px;
        line-height: 16px;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--secondary-font-color);
        cursor: pointer;
        transition: all 0.3s;
      }
    }
  }
  &__listing {
    max-width: 900px;
    margin: 0 auto;
    &__empty-card {
      text-align: center;
      img {
        margin: 18px 0px;
        text-align: center;
        max-width: 100%;
      }
      h3 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        margin: 0px auto 8px;
        max-width: 440px;
      }
      h4 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        margin: 0 auto;
        font-weight: 500;
        margin: 0px auto;
        max-width: 440px;
      }
    }
    &__my-webinar {
      display: flex;
      > div {
        padding: 0;
      }
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }
    &--calendar {
      max-width: none !important;
    }
  }
  &__modal-details {
    &__body {
      &__image {
        max-width: 252px;
        min-width: 252px;
        border-radius: 10px;
        background-color: var(--gray-100);
        overflow: hidden;
        position: relative;
        min-height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 16px;
        img {
          max-height: 128px;
        }
        &__tag {
          border: 2px solid var(--gray-100);
          border-radius: 100px;
          background-color: #ffffff;
          padding: 6px 16px 1px;
          display: flex;
          align-items: center;
          min-height: 29px;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }
      &__header-primary {
        margin: 0 0 8px 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        word-break: break-word;
      }
      &__description {
        font-family: "TTCommons-Medium";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        color: var(--secondary-font-color);
        margin-bottom: 8px;
      }
      &__presenter {
        margin: 0 0 8px 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        word-break: break-word;
        img {
          margin-right: 5px;
        }
      }
      &__date-time {
        margin: 0 0 0 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-DemiBold";
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
      }
      &__button {
        margin-top: 15px;
        &__with-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin-right: 4px;
            height: 21px;
          }
          img {
            margin-top: -5px;
          }
        }
      }
    }
  }
}
