.widget_signin_form_container {
  position: relative;
  text-align: center;
  &__back-btn {
    position: absolute;
    left: 30px;
    top: 80px;
    @media (max-width: 767px) {
      position: initial;
      margin-bottom: 8px;
    }
    a {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 25px;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
      height: 50px;
      width: 90px;
      -webkit-box-pack: center;
      justify-content: center;
      text-decoration: none;
      color: rgb(128, 148, 171);
      font-family: TTCommons-Regular;
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 19px;
      transition: all 0.3s ease 0s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
        background-color: rgb(255, 255, 255);
      }
      img {
        margin: 1px 4px 0px -4px;
        height: 15px;
        transform: rotate(180deg);
      }
      span {
        padding-top: 6px;
      }
    }
  }
}
