@import "/src/assets/styles/global.scss";

.m-chat-map-view {
  @include my($top: 20px);
  &__empty {
    margin-top: 70px;
    margin-bottom: 50px;
    h3 {
      margin: 0 0 20px 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      line-height: 31px;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 24px !important;
        line-height: 24px !important;
      }
    }
    h3:nth-child(2) {
      margin-bottom: 50px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      @media (min-width: 426px) {
        width: 80%;
      }
      @media (min-width: 560px) {
        width: 60%;
      }
      @media (min-width: 768px) {
        width: 400px;
      }
    }
    &_primary-text {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      line-height: 31px;
      margin-bottom: 10px;
    }
    &_secondary-text {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 20px;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
    }
  }
  &__main {
    display: flex;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    &__map {
      box-sizing: border-box;
      position: relative;
      width: calc(100% - 350px);
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &__list {
      width: 100%;
      max-width: 350px;
      padding-left: 20px;
      max-height: 600px;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin !important;
      -ms-overflow-style: auto;
      scrollbar-width: thin; /* "auto" or "thin" */
      scrollbar-color: $color-grey transparent; /* scroll thumb and track */
      transition: all ease-in-out 500ms;
      padding-right: 8px;
      &:hover {
        overflow-y: auto;
        &::-webkit-scrollbar {
          height: 50px;
          transition: all ease-in-out 500ms;
        }
      }
      &::-webkit-scrollbar {
        width: 7px; /* width of the entire scrollbar */
        height: 50px;
        transition: all ease-in-out 500ms;
      }
      &::-webkit-scrollbar-track {
        background: transparent !important; /* color of the tracking area */
        border-radius: 50px;
        width: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px
          0px;
        margin: 1px 0 1px 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-grey !important; /* color of the scroll thumb */
        border-radius: 50px; /* roundness of the scroll thumb */
        height: 150px !important;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb:hover {
        border: 0;
        background-color: $color-dark-gray !important; /* color of the scroll thumb */
      }
      @media (max-width: 767px) {
        max-width: 100%;
        padding: 0;
        max-height: initial;
        overflow: initial;
      }
      > div {
        > div {
          width: 100%;
        }
        + div {
          margin-top: 10px;
        }
      }
    }
  }
}
