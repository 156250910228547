@import "../../../assets/styles/global.scss";

.o-notifications-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 1.75rem;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  > div {
    > div {
      &:nth-child(3) {
        @media (max-width: 1367px) {
          max-width: 35%;
          flex-basis: 35%;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
          margin-bottom: 4px;
        }
      }
      &:nth-child(4) {
        @media (max-width: 1367px) {
          max-width: 65%;
          flex-basis: 65%;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
  .o-notifications-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    min-height: 50px;
    @media (max-width: 1367px) {
      margin-bottom: 24px;
    }
    @media (max-width: 992px) {
      flex-wrap: wrap;
      margin-bottom: 16px;
      min-height: auto;
    }
    h1 {
      margin: 0;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      font-family: "TTCommons-DemiBold";
      color: var(--primary-font-color);
    }
  }
  .o-notifications-title-controls {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: -80px;
    @media (max-width: 992px) {
      margin-top: 0;
      justify-content: center;
      margin-bottom: 16px;
    }
    > div {
      display: flex;
      align-items: center;
      box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
      border-radius: 100px;
      padding: 18px 20px 13px;
      span {
        color: var(--primary-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 19px;
        + div {
          margin-top: -4px;
          margin-left: 12px;
        }
      }
      + div {
        margin-left: 10px;
      }
    }
  }
  .o-notifications-categories-wrapper {
    &__back-btn {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      word-break: break-word;
      font-size: 22px;
      line-height: 20px;
      margin: 0;
      margin-bottom: 15px;
      padding-top: 4px;
      a {
        color: var(--primary-font-color);
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          width: 22px;
          margin-top: -6px;
          margin-right: 10px;
          @media (max-width: 1367px) {
            width: 18px;
            margin-top: -4px;
            margin-right: 6px;
          }
        }
      }
    }
  }
  .o-notifications-listing-wrapper {
    .o-notifications-listing-header {
      h2 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        letter-spacing: 0;
        word-break: break-word;
        font-size: 22px;
        line-height: 20px;
        margin: 0;
        margin-bottom: 15px;
        padding-top: 4px;
      }
    }
    .o-notifications-listing-list-wrapper {
      padding-bottom: 4rem;
      > div {
        padding: 15px 20px;
      }
    }
  }
}
