@import "../../../assets/styles/global.scss";

.m-forgot-password-container {
  height: 100%;
  min-height: 100vh;
  padding: 24px 0 48px 0;
  position: relative;
  @media (max-width: 767px) {
    padding: 24px 16px 24px 16px;
  }
  &__back-btn {
    position: absolute;
    left: 30px;
    top: 80px;
    @media (max-width: 767px) {
      position: initial;
      margin-bottom: 8px;
    }
    a {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 25px;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
      height: 50px;
      width: 90px;
      -webkit-box-pack: center;
      justify-content: center;
      text-decoration: none;
      color: rgb(128, 148, 171);
      font-family: TTCommons-Regular;
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 19px;
      transition: all 0.3s ease 0s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
        background-color: rgb(255, 255, 255);
      }
      img {
        margin: 1px 4px 0px -4px;
        height: 15px;
        transform: rotate(180deg);
      }
      span {
        padding-top: 6px;
      }
    }
  }
  &__content {
    max-width: 554px;
    margin: 0 auto;
    text-align: center;
    &__unlogo {
      img {
        max-width: 168px;
        vertical-align: middle;
        max-height: 100px;
      }
    }
    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 35px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
      text-align: center;
      margin: 28px 0 20px;
      padding: 8px 0px 0px;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 30px;
        margin: 16px auto 12px;
      }
    }
    p {
      @include TTCommonsRegular;
      font-weight: 400;
      margin: 0;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      color: var(--primary-font-color);
    }
    h5 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      max-width: 330px;
      margin: 0 auto 20px;
      @media (max-width: 767px) {
        margin: 0 auto 16px;
      }
      a {
        color: var(--link-color);
        margin-left: 4px;
        border-bottom: 1px solid var(--link-color);
        text-decoration: none;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include flex-row;
      align-items: flex-start;
      justify-content: center;
      margin-top: 0px;
      @media (max-width: 767px) {
        margin-top: 0px;
      }
      li {
        margin: 0 19px;
        div {
          @include flex-column;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          color: var(--primary-font-color);
          @media (max-width: 767px) {
            font-size: 16px;
          }
          a {
            width: 50px;
            height: 50px;
            min-width: 50px;
            margin: 0 auto 6px;
            @media (max-width: 767px) {
              width: 48px;
              height: 48px;
              min-width: 48px;
            }
            svg {
              width: 100%;
              height: 100%;
              @media (max-width: 767px) {
                width: 48px;
                height: 48px;
              }
            }
          }
        }
      }
    }
    &__formfield {
      max-width: 325px;
      margin: 0 auto;
      @media (max-width: 767px) {
        margin-top: 24px;
      }
      label {
        padding-left: 0;
      }
      input {
        text-align: center;
        @media (max-width: 767px) {
          height: 50px;
          padding: 16px 16px 12px 16px !important;
        }
      }
      > div {
        + div {
          margin-top: 16px;
        }
      }
      &__keepMeSignin {
        margin: 30px 0 !important;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        &--T-C {
          display: flex;
          justify-content: flex-start;
          text-align: left;
          span {
            margin: 0;
            letter-spacing: 0;
            font-size: 15px;
            line-height: 16px;
            font-family: "TTCommons-Regular";
            color: var(--secondary-font-color);
            font-weight: 400;
            padding: 0;
            a {
              margin: 0;
              letter-spacing: 0;
              font-size: 15px;
              line-height: 16px;
              font-family: "TTCommons-Regular";
              color: var(--secondary-font-color);
              font-weight: 400;
              padding: 0;
              text-decoration: none;
              border-bottom: 2px solid #8094ab;
              display: inline-block;
            }
          }
        }
      }
      button {
        margin: 16px 0 15px;
      }
      a {
        color: var(--secondary-font-color);
        @include TTCommonsRegular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        text-decoration: none;
        border-bottom: 2px solid var(--secondary-font-color);
      }
    }
  }

  h6 {
    margin: 0;
    letter-spacing: 0;
    font-size: 15px;
    line-height: 16px;
    font-family: "TTCommons-Regular";
    font-weight: 400;
    text-align: center;
    color: #607790;
    a {
      color: var(--primary-color);
      text-decoration: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
}
