.m-match-ambassador {
  &__header {
    font-family: "TTCommons-Regular";
    font-size: 30px;
    font-weight: 500;
    line-height: 31px;
    text-align: center;
    color: var(--primary-font-color);
    margin: 0 0 0 0;
    padding-top: 27px;
  }
  &__body {
    &__sub-header {
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: var(--primary-font-color);
      margin: 20px 0 30px 0;
    }
    &__name {
      font-size: 30px !important;
      font-weight: 500 !important;
      line-height: 30px !important;
      margin: 9px 0 0 0 !important;
      overflow-wrap: break-word;
    }
    &__education {
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 17px !important;
      margin: 8px 0 0 0 !important;
    }
    &__country {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 20px;
      margin-top: 8px;
      img {
        border-radius: 2px;
        height: 20px;
        object-fit: contain;
      }
      span {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 17px !important;
        margin-top: 4px;
      }
    }
    &__intro {
      display: flex;
      justify-content: space-between;
      max-width: 285px;
      margin: 0 auto;
      margin-top: 15px;
      &__text {
        transition: all 0.5s;
        margin-top: 15px;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 17px !important;
        text-align: center !important;
        margin-right: 10px !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          color: var(--link-color);
          margin-left: 4px;
          border-bottom: 1px solid var(--link-color);
          text-decoration: none;
          width: fit-content;
        }
      }
      &__icon {
        height: fit-content !important;
        padding-top: 4px;
        transition: transform 0.5s;
        svg {
          transition: transform 0.5s;
          width: 20px;
          height: 25px;
          cursor: pointer;
        }
        &--expanded {
          transition: transform 0.5s;
          transform: rotate(180deg);
        }
      }
    }
    &__button {
      button {
        max-width: 325px;
        height: 60px;
        margin-bottom: 20px;
        margin-top: 30px;
        font-size: 22px;
        line-height: 21px;
        padding-top: 5px;
      }
    }
    &__match {
      font-family: "TTCommons-Medium";
      font-size: 15px !important;
      font-weight: 500 !important;
      line-height: 17px !important;
      color: var(--primary-color) !important;
      width: fit-content;
      margin: 0 auto !important;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-bottom: 2px;
        margin-left: 5px;
        visibility: hidden;
        width: 0;
        height: 0;
      }
    }
    &__match:hover {
      svg {
        visibility: visible;
        height: 15px;
        width: 15px;
      }
    }
  }
}
