@import "../../../assets/styles/global.scss";

.a-custom-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  &__text_one,
  &__text_two {
    display: flex;
    align-items: center;
    span {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 16px;
      color: var(--link-color);
      border-bottom: 2px solid var(--link-color);
    }
    svg {
      filter: brightness(0) invert(8%) sepia(17%) saturate(6529%)
        hue-rotate(213deg) brightness(110%) contrast(115%);
      width: 20px;
      height: 19px;
    }
  }
}
