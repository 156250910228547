@import "../../../assets/styles/global.scss";

.m-widget-resources-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    margin-top: 20px;
    margin-bottom: 0;
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    letter-spacing: 0;
    font-size: 30px;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 24px;
      align-items: center;
    }
    @media (max-width: 767px) {
      min-height: auto;
    }
  }

  &__switch {
  }
}
