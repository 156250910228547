@import "../../../assets/styles/global.scss";

.o-checklists {
  position: relative;
  margin-bottom: 48px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 0 auto;
  }
  @media (max-width: 1170px) {
    padding-bottom: 0px;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &__header {
    margin: 30px 0 15px 0;
    @media (max-width: 890px) {
      margin: 30px 0 20px 0;
    }
    h2 {
      margin: 0;
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
    }
    + div {
      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
      > div {
        @media (max-width: 992px) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
    + div {
      > div {
        &:first-child {
          > div {
            @media (min-width: 993px) {
              margin-top: 65px;
            }
          }
          h2 {
            @media (min-width: 993px) {
              display: none;
            }
          }
        }
      }
    }
  }

  &__category-header {
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
    min-height: 50px;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      min-height: auto;
      align-items: center;
    }
    h2 {
      margin: 0;
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 200px);
      overflow: hidden;
      padding: 2px 0 0 0;
    }
    button {
      background: #fff !important;
      box-shadow: var(--box-shadow) !important;
      color: var(--primary-dark-color);
      width: auto;
      padding: 16px 20px 12px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      border-radius: 100px;
      text-decoration: none;
      transition: all 0.3s;
      display: inline-block;
      text-transform: none;
      margin-right: 0;
      @media (max-width: 767px) {
        padding: 12px 12px 8px;
        min-height: 35px;
      }
      img {
        margin: -4px 0 0 8px;
        transform: none;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
      }
    }
  }
}
