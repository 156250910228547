@import "../../../assets/styles/global.scss";

.m-saved-services {
  &__content {
    &__loader {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -8px;
      > div {
        width: 25%;
        @media (max-width: 1367px) {
          width: 33.33%;
        }
        @media (max-width: 767px) {
          width: 50%;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
    &__placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        margin: 20px 0 0 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
      }
    }
  }
}
