@import "../../../assets/styles/global.scss";

.m-leave-feedback-card {
  @media (max-width: 1367px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  > div {
    padding: 22px 20px 18px;
    display: flex;
    align-items: center;
    @media (max-width: 1367px) {
      padding: 16px;
    }
  }
  &__title {
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      margin: 0 0 6px 0;
    }
    span {
      color: var(--secondary-font-color);
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 19px;
      text-align: center;
      max-width: 260px;
      font-family: "TTCommons-Regular";
    }
  }
  &__button {
    margin-left: auto;
    @media (max-width: 992px) {
      margin-left: auto;
    }
    button {
      width: auto;
      background: transparent;
      padding: 0;
      margin: 0;
      height: auto;
    }
  }
}

.m-leave-feedback-modal-body {
  margin: 16px 0 0 0;
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    form {
      width: 100%;
    }
    &__body {
      &__rate {
        text-align: center;
        margin: 0 0 18px;
        &__label {
          margin: 0 0 4px 0 !important;
          color: var(--secondary-font-color) !important;
          font-family: "TTCommons-Regular" !important;
          font-size: 15px !important;
          letter-spacing: 0 !important;
          line-height: 17px !important;
        }
        > span {
          > span {
            margin: 0 4px;
            > span {
              display: flex;
            }
          }
        }
        .star {
          width: 52px !important;
          height: 52px !important;
          margin: 0 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__comment {
        margin: 16px 0 20px 0;
        width: 100%;
        @media (max-width: 768px) {
          margin: 15px 0 25px 0 !important;
        }
        textarea {
          padding: 20px !important;
        }
        > div {
          > div {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
      }
      &__recommended_rate {
        &__label {
          margin: 0 0 15px !important;
          color: var(--secondary-font-color) !important;
          font-family: "TTCommons-Regular" !important;
          font-size: 15px !important;
          letter-spacing: 0 !important;
          line-height: 12px !important;
          padding: 5px 0 0;
        }
        ul {
          display: flex;
          padding: 0;
          list-style: none;
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0px 0 8px 0;

          li.point {
            height: 30px;
            width: 30px;
            border-radius: 100px;
            line-height: 35px;
            color: var(--primary-font-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            text-align: center;
            margin: 0;
            cursor: pointer;
            background: var(--gray-100);
            &.active {
              background: var(--primary-font-color);
              color: #fff;
            }
            &:hover {
              background-color: #eaeef3;
            }
          }
        }
        &__description {
          display: flex;
          justify-content: space-between;
          margin: 0;

          span {
            margin: 0 !important;
            color: var(--primary-font-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
      }
      &__submit_button {
        margin: 30px 0 30px 0;
        @media (max-width: 767px) {
          margin: 15px 0 15px 0;
        }
        button {
          width: 248px;
        }
        @media (max-width: 768px) {
          margin: 15px 0 15px 0;
          button {
            width: 160px;
            height: 50px;
          }
        }
      }
      &__cancel {
        span {
          color: var(--secondary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 16px;
          border-bottom: 2px solid var(--secondary-font-color);
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.m-leave-feedback-confirmation-modal-body {
  p {
    margin: 20px 0 40px 0;
  }
  &__buttons {
    button {
      width: 248px;
    }
    @media (max-width: 768px) {
      margin: 0 0 30px 0;
      button {
        width: 160px;
        height: 50px;
      }
    }
    button {
      height: 30px;
      width: 124px;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
}
