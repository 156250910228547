@import "../../../assets/styles/global.scss";

.a-widget-sign-up-bar {
  border-radius: 10px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 30px 0;
  padding: 12px 0;
  width: 100%;
  @media (max-width: 930px) {
    margin: 0px 0px 16px 0;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 16px;
  }
  &__title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-family: "TTCommons-Medium";
    color: #fff;
    margin-right: 20px;
    padding-top: 4px;
    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 15px 0;
    }
  }
}
