@import "../../../assets/styles/global.scss";

.m-notifications-list {
  &--small {
    width: 100%;
    padding: 0 !important;
  }

  &--large {
    width: 100%;
    + div {
      @media (max-width: 1367px) {
        display: block !important;
      }
    }
  }

  &--content {
    &--title {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;
      justify-content: space-between;

      h2 {
        margin: 0 !important;
        color: var(--primary-font-color);
      }
    }
  }

  &__empty_message {
    background-color: red;
    padding: 20px;
    box-shadow: var(--box-shadow);
    background: #fff;
    border-radius: 10px;
    margin-bottom: 0;
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      margin: 4px 0 0;
    }
  }

  &__empty {
    display: flex;
    align-items: center;
    img {
      margin-right: 15px;
      margin-top: -2px;
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 19px;
      text-align: center;
      margin: 0px;
      font-family: "TTCommons-Medium";
      color: var(--primary-font-color);
    }
  }
}
