@import "../../../assets/styles/global.scss";

.m-topic-comment-wrapper {
  display: flex;
  position: relative;
  &__delete {
    position: absolute;
    right: 0;
    top: 3px;
    img {
      cursor: pointer;
      height: 40px;
      width: 40px;
      margin: 0 0 0 0 !important;
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    @media (max-width: 1367px) {
      margin-right: 0px;
    }
  }
  .m-topic-comment-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    padding-right: 40px;
    position: relative;
    top: 2px;
    span:first-child {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 15px;
      padding-top: 4px;
      @media (max-width: 1367px) {
        font-size: 16px;
      }
    }
    span:nth-child(2) {
      color: $grey-text-color;
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 15px;
      padding-top: 4px;
      margin: 0px 0px 0px 8px;
      font-family: "TTCommons-Regular";
      @media (max-width: 1367px) {
        font-size: 14px;
      }
    }

    &__comment {
      margin-right: 20px;
      @media (max-width: 1367px) {
        margin-top: 4px;
      }
      p {
        margin: 0;
        font-size: 17px;
        line-height: 19px;
      }
    }
  }
  + div {
    margin-top: 12px;
  }
}

.m-topic-comment-expand-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.m-topic-user-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  &__avatar {
  }
  &__textbox {
    width: 100%;
    margin: 0 8px;
    textarea {
      width: 100%;
      box-sizing: border-box;
      width: 100%;
      height: 35px !important;
      border: 2px solid #d1dde5;
      border-radius: 45px;
      background-color: #ffffff;
      outline: none;
      resize: none;
      padding: 8px 16px;
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular" !important;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 19px;
      vertical-align: middle;
      transition: all 0.3s;
      &:hover {
        border-color: #bfced8;
      }
      &:focus {
        border-color: var(--primary-font-color);
      }
    }
  }
  &__button {
    margin-left: 0 !important;
    button {
      @media (max-width: 480px) {
        width: 60px;
      }
    }
  }
  .m-topic-user-comment-wrapper-image {
    margin-right: 10px;
  }
  .m-topic-user-comment {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 480px) {
      padding: 2px 8px 0;
    }
    input {
      width: 100%;
      border-width: 0px !important;
      font-size: 18px;
      line-height: 24px;
      font-family: "TTCommons-Regular";
      border: 2px solid #d1dde5 !important;
      border-radius: 100px;
      height: 35px;
      padding: 10px 15px 6px;
      margin-right: 10px;
      color: var(--primary-font-color);
      @media (max-width: 480px) {
        font-size: 16px;
        line-height: 17px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--field-label-color);
        opacity: 1 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--field-label-color);
        opacity: 1 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--field-label-color);
        opacity: 1 !important;
      }
      &:hover {
        border-color: #bfced8;
      }
      &:focus {
        border-color: var(--gray-color) !important;
        &:hover {
          border-color: var(--gray-color) !important;
        }
      }
      + button {
        min-height: 35px;
        min-width: 84px;
      }
      /* scroll styling end */
    }
  }
  &__button {
    margin-left: -89px;
  }
}

.delete-post-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__submit-button {
    margin-top: 30px;
  }

  &__cancel-link {
    cursor: pointer;
    color: $grey-text-color;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 17px;
    text-decoration: none;
    margin-top: 30px;
    border-bottom: 1px solid $grey-text-color;
    text-align: center;
  }
}

.m-my-topic-middle-post-detail-hr {
  background: $gray-outline-color !important;
  margin: 16px 0px 16px 0px !important;
  height: 1px;
  border: 0;
}
