@import "/src/assets/styles/global.scss";
.a-mui-textbox {
  input {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    border: 2px solid $color-grey;
    @include radius(45px);
    background-color: $color-white;
    outline: none;
    padding: 10px;
    resize: none;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    overscroll-behavior: none;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: $color-grey transparent; /* scroll thumb and track */
    transition: scrollbar-color 0.3s ease-out;
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular" !important;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
    padding: 16.65px 20px 12.65px !important;
    @media (max-width: 1367px) {
      height: 50px;
    }
    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: transparent !important; /* color of the tracking area */
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 10px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0px;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &:hover {
      border-color: #bfced8;
    }
    &:focus {
      border-color: var(--gray-color) !important;
      &:hover {
        border-color: var(--gray-color) !important;
      }
    }
    &:-webkit-autofill {
      border-radius: 45px;
    }
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    border: 2px solid $color-grey;
    @include radius(10px);
    background-color: $color-white;
    outline: none;
    resize: none;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    overscroll-behavior: none;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: $color-grey transparent; /* scroll thumb and track */
    transition: scrollbar-color 0.3s ease-out;
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular" !important;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
    padding: 10px 10px 12.5px 10px !important;

    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: transparent !important; /* color of the tracking area */
      border-radius: 50px;
    }

    &::-webkit-scrollbar-track-piece:end {
      margin-bottom: 5px;
    }

    &::-webkit-scrollbar-track-piece:start {
      margin-top: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 10px !important;
    }
    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0px;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--field-label-color);
      opacity: 1 !important;
    }
    &:hover {
      border-color: #bfced8;
    }
    &:focus {
      border-color: var(--gray-color) !important;
      &:hover {
        border-color: var(--gray-color) !important;
      }
    }
    &:-webkit-autofill {
      border-radius: 45px;
    }
  }

  &--center-align {
    input {
      justify-content: center;
      text-align: center;
    }
  }
}
