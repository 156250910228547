@import "../../../assets/styles/global.scss";

.o-notifications-container {
  width: 100%;
  margin: 0 auto;
  margin-top: 1.75rem;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  > div {
    > div {
      &:nth-child(2),
      &:nth-child(4) {
        @media (max-width: 1367px) {
          display: none;
        }
      }
      &:nth-child(3) {
        @media (max-width: 1367px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
  .o-notifications-title {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 30px;
    @media (max-width: 1367px) {
      margin-bottom: 24px;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      margin-bottom: 16px;
    }
    button {
      background: #fff;
      box-shadow: var(--box-shadow);
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      width: auto;
      max-width: 100%;
      padding: 18px 20px 12px;
      transition: all 0.3s;
      @media (max-width: 992px) {
        margin: 0 auto;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color);
      }
    }
  }
  .o-notifications-filter-wrapper {
    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      word-break: break-word;
      font-size: 30px;
      line-height: 28px;
      margin: 0;
      margin-bottom: 24px;
      @media (max-width: 1367px) {
        font-size: 22px;
        line-height: 21px;
      }
    }
    .o-notifications-filter-content-wrapper {
      .o-notifications-filter-content-header {
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        margin-bottom: 16px;
        color: var(--primary-color);
        line-height: 17px;
        display: block;
        width: 100%;
        word-break: break-word;
      }
      .o-notifications-filter-content-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        position: relative;
        input {
          margin: 0;
          width: 100%;
          height: 15px;
          position: absolute;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
          left: 0;
          right: 0;
          + span {
            width: 15px;
            height: 15px;
            min-width: 15px;
            min-height: 15px;
            border-radius: 100px;
            background-color: var(--primary-color);
            color: #fff;
            margin: 0;
            &::after {
              content: "";
              left: 5px;
              top: 4px;
              width: 3px;
              height: 7px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              position: absolute;
              display: none;
            }
          }
          &:checked {
            + span {
              &::after {
                display: block;
              }
            }
          }
        }
        span {
          font-family: "TTCommons-Medium";
          color: var(--primary-color);
          font-size: 18px;
          line-height: 15px;
          margin-left: 10px;
          padding-top: 4px;
        }
      }
      .o-notifications-filter-content-button {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        button {
          height: 60px;
          font-size: 22px;
        }
      }
    }
  }
  .o-notifications-listing-wrapper {
    > div {
      + div {
        margin-top: 32px;
        @media (max-width: 1367px) {
          margin-top: 20px;
        }
      }
    }
    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      word-break: break-word;
      font-size: 22px;
      line-height: 20px;
      margin: 0;
      margin-bottom: 15px;
      padding-top: 4px;
    }
    .o-notifications-listing-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0px;
      h3 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 21px;
        margin: 0px;
      }
    }
    .o-notifications-listing-past {
      margin-top: 2.5rem;
      .o-notifications-listing-past-header {
        h2 {
          padding-top: 30px;
          color: var(--primary-font-color);
          @media (max-width: 1367px) {
            font-size: 22px;
            line-height: 21px;
          }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
