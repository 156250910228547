@import "../../../assets/styles/global.scss";

.m-dashboard-checklist-link-card {
  > div {
    padding: 10px 20px 9px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    min-height: 52px;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
    }
    > a {
      width: 100%;
      text-decoration: none;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    > svg {
      width: 32px;
      height: 32px;
    }
  }
  &__title {
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 15px;
    margin-left: 8px;
    padding-top: 5px;
  }

  &__link {
    margin-left: auto;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      display: flex;
    }
  }
}
