@import "../../../assets/styles/global.scss";

.m-help-center-list {
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
  h2 {
    color: var(--primary-font-color);
    justify-content: center;
    font-family: "TTCommons-Medium";
    font-weight: 500;
    letter-spacing: 0;
    font-size: 22px;
    margin: 0px 0px 15px 0px;
    display: flex;
    align-items: flex-end;
    justify-content: left;
    padding-top: 0;
  }

  .card-title {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    line-height: 24px;
  }

  p {
    margin: 0px;
    b {
      font-weight: 600;
      font-family: "TTCommons-DemiBold";
    }
  }

  .media {
    margin: 15px 0 0 0;
    video {
      width: 100%;
    }
  }
}
