@import "../../../assets/styles/global.scss";

.o-confirm-email-wrapper{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .o-confirm-email-content-wrapper{
    display: flex;
    align-items: center;
    .o-confirm-email-logo{
      display: flex;
      align-items: center;
      margin-right: 0.5erm;
    }
  }
}
