@import "../../../assets/styles/global.scss";

.m-dashboard-user-profile-card {
  > div {
    padding: 30px 20px 20px;
    @media (max-width: 767px) {
      padding: 30px 16px 16px;
    }
  }
  &__content {
    text-align: center;
    &__image {
      width: 70px;
      height: 70px;
      margin: -65px auto 8px;
      background: #fff;
      border-radius: 100px;
      overflow: hidden;
      @media (max-width: 767px) {
        width: 60px !important;
        height: 60px !important;
      }
      img,
      svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      > div {
        > div {
          @media (max-width: 767px) {
            width: 60px !important;
            height: 60px !important;
          }
          > div {
            @media (max-width: 767px) {
              width: 60px !important;
              height: 60px !important;
            }
            span {
              @media (max-width: 767px) {
                font-size: 22px;
                line-height: 64px;
              }
            }
          }
        }
      }
    }

    &__user-name {
      margin-bottom: 8px;
      @media (max-width: 767px) {
        margin-bottom: 4px;
      }
      h3 {
        color: var(--primary-font-color);
        font-family: "TTCommons-DemiBold";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0;
      }
    }

    &__country-info {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      @include TTCommonsRegular;
      font-weight: 400;
      margin-bottom: 10px;
      margin-top: 0;
      @media (max-width: 767px) {
        margin-bottom: 4px;
      }
      &__country-flag {
        width: 28px;
        overflow: hidden;
        height: 20px;
        border-radius: 2px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &__country-name {
        margin-top: 4px;
      }
    }

    &__education-info {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      margin: 0;
      padding-top: 3px;
    }

    &__intro {
      color: var(--primary-font-color);
      @include TTCommonsRegular;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      margin-top: 10px;
    }
  }
}
