@import "../../../assets/styles/global.scss";

.m-resource-modal {
  margin-top: 20px;
  &__pdf {
    iframe {
      border: 0;
      width: 100%;
      min-height: 400px;
      vertical-align: top;
    }
  }
  &__player {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 aspect ratio*/

    &__react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__interstride-video {
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 100%;
      max-height: 385px;
    }
  }

  h3 {
    color: var(--primary-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
}
