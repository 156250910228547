@import "/src/assets/styles/global.scss";

.a-empty-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin: 20px 0 0 0;
    color: #000c3d;
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
  }
  h3 {
    margin: 8px 0 18px 0;
    color: #000c3d;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }
}
