.o-campus-map {
  margin-top: 30px;
  margin-bottom: 80px;
  &__title {
    font-family: "TTCommons-DemiBold";
    color: var(--primary-font-color);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0 0 35px 0;
    text-align: center;
  }
  &__body {
    &__list {
      margin: 0 auto 30px;
      &__loader {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        &__item {
          margin: 0 0 0 0 !important;
          border-radius: 25px;
        }
      }
      &__categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        &__item {
          &:hover {
            box-shadow: 0 1px 14px 3px var(--light-gray-color);
            @media (max-width: 992px) {
              box-shadow: none !important;
            }
          }
          &:focus {
            box-shadow: 0 1px 14px 3px var(--light-gray-color);
            @media (max-width: 992px) {
              box-shadow: none !important;
            }
          }
          border-radius: 25px;
          width: fit-content;
          > div {
            margin: 0 0 0 0 !important;
            height: 50px !important;
            padding: 0 0 0 0 !important;
            cursor: pointer;
            border-radius: 50px;
          }
          &__body {
            display: flex;
            align-items: center;
            gap: 10px;
            height: 50px;
            padding: 10px 15px;
            img {
              width: 35px;
              height: 35px;
              border-radius: 100%;
              display: block;
            }
            p {
              margin: 5px 0 0 0;
              color: var(--primary-font-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              max-width: 85%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow-wrap: break-word;
            }
          }

          &--selected {
            > div {
              background: var(--primary-color);
              p {
                color: #ffffff;
              }
            }
          }
        }
      }
      &__no-categories {
        h3 {
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          margin: 2px 0 0 0;
          text-align: center;
        }
      }
    }
    &__map {
      height: 745px;
    }
  }
}
