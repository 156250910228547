@import "../../../assets/styles/global.scss";

.a-categories-flush {
  margin-bottom: 3rem;
  @media (max-width: 1367px) {
    margin-bottom: 15px;
  }
  @media (max-width: 992px) {
    max-height: 242px;
    overflow-y: auto;
    border-radius: 0 0 10px 10px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        box-shadow: var(--box-shadow);
        border-radius: 100px;
        background-color: var(--white-color);
        padding: 14px 15px 10px;
        min-height: 50px;
        @media (max-width: 992px) {
          border-radius: 0;
          box-shadow: none !important;
          padding: 10px 15px 8px;
          min-height: auto;
        }
      }
      + li {
        margin-top: 10px;
        @media (max-width: 992px) {
          margin-top: 0;
          border-top: 1px solid var(--light-gray-color);
        }
      }
    }
    li:last-child {
      margin-bottom: 10px;
    }
  }

  &--community {
    margin-top: 51px;
    @media (max-width: 992px) {
      margin-top: 0;
    }
  }
}

.a-categories-flush__list_resource {
  a {
    padding: 22px 24px 20px;
    @media (max-width: 1367px) {
      padding: 16px 16px 12px;
    }
    box-shadow: 0 1px 7px 3px rgba(209, 221, 229, 0.4) !important;
  }
  .a-categories-flush__list_resource_image_topic {
    margin-right: 10px;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
}

.a-categories-flush__student-services {
  // a {
  //   padding: 11px 20px 14px 15px;
  // }
  a {
    box-shadow: 0 1px 7px 3px rgba(209, 221, 229, 0.4) !important;
  }
  &__image {
    width: 38px;
    min-width: 38px;
    margin: 5px 10px 0px 0px;
    border-radius: 2px;
    @media (max-width: 1367px) {
      width: 32px;
      min-width: 32px;
      margin: 4px 8px 0px 0px;
    }
    img {
      width: 100%;
    }
  }
}

.a-categories-flush__list_notifications {
  .a-categories-flush__list_notifications_image {
    margin-right: 10px;
    img {
      width: 100%;
      max-width: 30px;
      max-height: 30px;
      margin-right: 0 !important;
    }
  }
  span {
    padding-top: 6px;
    color: var(--primary-font-color);
  }
}

.a-categories-flush__list__item__selected {
  a {
    color: var(--white-color);
    background: var(--primary-color) !important;
  }
}

.a-categories-flush__list__item__notifications_selected {
  a {
    color: var(--white-color);
    background: var(--primary-color) !important;

    span {
      color: var(--white-color);
    }
  }
}

.a-categories-not-available {
  padding: 2.5rem 1.5rem;
  h3 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    text-align: center;
    margin: 0px;
    font-family: "TTCommons-Medium";
    color: var(--primary-font-color);
  }
}

.a-categories-flush__list_notification_count {
  background-color: var(--primary-color);
  margin-left: 10px;
  color: #fff;
  white-space: nowrap;
  border-radius: 100px;
  min-width: 60px;
  text-align: center;
  min-height: 20px;
  padding-top: 4px;
  font-size: 15px;
  line-height: 15px;
}

.a-categories-flush__list_topics {
  li {
    a {
      display: flex;
      align-items: center;
      border-radius: 10px !important;
      padding: 25px 15px !important;
      font-family: "TTCommons-Medium", "NotoColorEmoji-Regular";
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      text-decoration: none;
      transition: all 0.3s;
      color: var(--primary-font-color);
      @media (max-width: 992px) {
        padding: 14px 15px 10px !important;
        border-radius: 0 !important;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
        @media (max-width: 992px) {
          box-shadow: none !important;
        }
      }
    }
  }
  &__selected {
    a {
      color: var(--white-color) !important;
      background: var(--primary-color) !important;

      .a-categories-flush__list_topics__counts {
        background-color: #fff;
        color: var(--primary-color);
      }
    }
  }
  &__icons {
    margin-right: 10px;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
  &__counts {
    background-color: var(--primary-color);
    margin-left: auto;
    color: #fff;
    white-space: nowrap;
    border-radius: 100px;
    text-align: center;
    min-height: 20px;
    padding-top: 4px;
    font-size: 15px;
    line-height: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
