@import "../../../../assets/styles/global.scss";

.o-profile-change-password-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 326px;
  margin: 0 auto;
  &-label {
    margin-top: 1rem;
    span {
      font-size: 15px;
      color: var(--primary-color);
    }
  }
  &-input {
    width: 70%;
    margin-top: 0.5rem;
    input {
      padding: 21px 22px 17px;
      text-align: center;
    }
  }
  br {
    display: none;
    + div {
      margin-top: 16px;
    }
  }
  &-submit-button {
    margin-top: 16px;
    button {
      width: 100%;
    }
  }
  > div {
    width: 100%;
    max-width: 350px;
  }
}
