@import "../../../assets/styles/global.scss";
.m-perspective-container {
  height: 100%;
  padding: 80px 16px 16px 16px;
  background-color: var(--primary-color);
  @media (max-width: 767px) {
    padding: 24px 16px 8px 16px;
  }
  > div {
    max-width: 450px;
    margin: 0 auto;
    h3 {
      color: var(--white-color);
      font-family: "TTCommons-DemiBold";
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
      margin: 0 0 24px 0;
      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 22px;
        margin: 0 0 16px 0;
      }
    }
  }
}
