// @font-face {
//   font-family: "TTCommons-DemiBold";
//   src: url("../fonts/ttcommons-demibold.woff2") format("woff2"),
//     url("../fonts/ttcommons-demibold.woff") format("woff"),
//     url("../fonts/TTCommons-DemiBold.ttf") format("ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "TTCommons-Medium";
//   src: url("../fonts/ttcommons-medium.woff2") format("woff2"),
//     url("../fonts/ttcommons-medium.woff") format("woff"),
//     url("../fonts/TTCommons-Medium.ttf") format("ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "TTCommons-DemiBold";
//   src: url("../fonts/ttcommons-demibold.woff2") format("woff2"),
//     url("../fonts/ttcommons-demibold.woff") format("woff"),
//     url("../fonts/TTCommons-DemiBold.ttf") format("ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "TTCommons-Regular";
//   src: url("../fonts/ttcommons-regular.woff2") format("woff2"),
//     url("../fonts/ttcommons-regular.woff") format("woff"),
//     url("../fonts/TTCommons-Regular.ttf") format("ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "brandon_grotesquebold";
//   src: url("../fonts/brandon_bld.woff2") format("woff2"),
//     url("../fonts/brandon_bld.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "TTCommons-DemiBold";
  src: url("../fonts-preview/TT_Commons_Classic_Trial-web_DemiBold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Medium";
  src: url("../FinalFont/ttcommons-medium.woff2") format("woff2"),
    url("../FinalFont/TTCommons-Medium.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTCommons-Regular";
  src: url("../FinalFont/ttcommons-regular.woff2") format("woff2"),
    url("../FinalFont/TTCommons-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoColorEmoji-Regular";
  src: url("../fonts/NotoColorEmoji-Regular.ttf");
}

body {
  margin: 0;
  font-family: "TTCommons-Regular";
  background: "transparent";
  overflow-x: hidden;
  color: var(--primary-font-color);
}
