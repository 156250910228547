@import "../../../assets/styles/global.scss";
.m-ambassador-perspective {
  background-color: var(--primary-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 0 0;

  &__content {
    max-width: 420px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h1 {
      color: #fff;
      font-family: "TTCommons-DemiBold";
      font-size: 42px;
      line-height: 38px;
      font-weight: 600;
      margin: 30px auto 20px auto;
    }

    h2 {
      color: #fff;
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      line-height: 24px;
      font-weight: 600;
      margin: 0px;
      margin-bottom: 10px;
    }

    &__middle {
      &__title {
        color: #fff;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
      }

      &__benefits {
        color: #fff;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        line-height: 19px;
        margin: 20px auto 20px auto;

        &__item {
          display: flex;
          margin: 2px auto 8px auto;

          &__image {
            margin-right: 10px;
            margin-top: -4px;
            img {
              border-radius: 50%;
              height: 15px;
              width: 15px;
            }
          }
        }
      }
    }

    @media (max-width: 959px) {
      margin: 20px;
    }

    @media (max-width: 1366px) {
      margin-top: 15px;
      height: 100%;

      img {
        height: 180px;
        width: 100%;
      }

      h1 {
        font-size: 30px;
        line-height: 30px;
        font-weight: 500;
        margin-top: 20px;
      }

      h2 {
        font-size: 18px;
        line-height: 19px;
      }
    }

    @media (min-width: 1367px) {
      justify-content: center;
    }
  }
}
