@import "../../../assets/styles/global.scss";

.m-widget-faded-banner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 159px;
  bottom: 14px;
  gap: 0px;
  background: radial-gradient(
    circle,
    rgba(241, 244, 247, 0.8) 0%,
    rgba(249, 250, 252, 0.6) 20%
  );
  backdrop-filter: blur(2px);
  border-radius: 10px;
  button {
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: fit-content;
    padding: 3px 25px 0 25px !important;
  }
}
