@import "../../../assets/styles/global.scss";

.a-user-profile-card {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  overflow: hidden;
  max-width: 325px;
  width: 100%;
  padding: 20px;
  position: relative;
  @include center;
  overscroll-behavior: none;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $color-grey transparent; /* scroll thumb and track */
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0;
    min-height: calc(100vh - 128px);
    max-height: calc(100vh - 128px);
    padding: 16px;
  }
  &__deactivated {
    font-family: "TTCommons-Medium";
    color: var(--primary-font-color);
    font-weight: 500;
    line-height: 15px;
  }
  &__content {
    &__close {
      margin-top: 16px;
      position: absolute;
      left: 16px;
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      @media (min-width: 767px) {
        display: none !important;
      }
    }
    &__close {
      margin-top: 16px;
      display: none;
      float: right;
      @include flex-column-center;
      margin-top: -18px;
      border: 0;
      padding: 0;
      background: transparent;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    &__image {
      width: 70px;
      height: 70px;
      overflow: hidden;
      @include mx($left: auto, $right: auto);
      border-radius: 100px;
      margin-top: 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__name {
      margin-top: 10px;
      h3 {
        @include TTCommonsMedium;
        color: var(--primary-font-color);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        margin: 0;
        padding-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__tag {
      margin-top: 8px !important;
      span {
        display: inline-block;
        color: var(--primary-font-color);
        background-color: var(--gray-400);
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        height: 25px;
        border-radius: 100px;
        padding: 7px 8px 0;
        text-transform: capitalize;
        min-width: 60px;
      }
    }
    &__subject {
      margin-top: 10px;
      h3 {
        @include TTCommonsMedium;
        color: var(--primary-font-color);
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
      }
    }
    &__flag {
      margin-top: 12px;
      > div {
        @include flex-row-center;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        font-family: "TTCommons-Regular";
        font-weight: 400;
        margin: 0;
        span {
          width: 28px;
          overflow: hidden;
          height: 20px;
          border-radius: 2px;
          margin-right: 8px;
          margin-top: -4px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
    &__description {
      margin-top: 10px !important;
      margin-bottom: 10px;
      p {
        margin: 10px 0 0 0 !important;
        @include TTCommonsRegular;
        color: var(--primary-font-color);
        margin: 0;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
    &__social {
      margin-top: 16px;
      @include flex-column-center;
      @include TTCommonsRegular;
      color: var(--secondary-font-color);
      > span {
        width: 39px;
        height: 39px;
        overflow: hidden;
        border-radius: 100px;
        margin-bottom: 4px;
        svg {
          cursor: pointer;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

.a-user-profile-card::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.a-user-profile-card::-webkit-scrollbar-track {
  background: transparent !important; /* color of the tracking area */
  border-radius: 50px;
}

.a-user-profile-card::-webkit-scrollbar-thumb {
  background-color: $color-grey !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 10px !important;
}

.a-user-profile-card::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.a-user-profile-card::-webkit-scrollbar-thumb:hover {
  border: 0px;
}
