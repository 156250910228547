.footer {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    padding-bottom: 16px;
  }
  .button-wrapper {
    max-width: 320px;
    margin: 0 0 20px 0;
    display: flex;
    @media (min-width: 320px) {
      width: 200px;
      height: 60px;
    }
    @media (min-width: 375px) {
      width: 200px;
      height: 60px;
    }
    @media (min-width: 425px) {
      width: 325px;
      height: 60px;
    }
  }
  .cancel-label {
    color: var(--secondary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 16px;
    border-bottom: 2px solid var(--secondary-font-color);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}

.m-report-chat-textbox-wrapper {
  margin-top: 18px;
  margin-bottom: 15px;
  div {
    padding: 0;
  }
  textarea {
    padding: 20px !important;
    color: var(--secondary-font-color);
  }
}
