@import "../../../assets/styles/global.scss";

.m-service-list {
  &__loader-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 775px) {
      justify-content: center;
    }
  }
  &__list {
    &__accessible {
      margin-top: 20px;
    }
    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -8px;
      @media (max-width: 775px) {
        justify-content: center;
      }
    }
    &__loader-secondary {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;
      justify-content: flex-start;
      @media (max-width: 775px) {
        justify-content: center;
      }
    }
    &__placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        margin: 20px 0 0 0;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }
      h3 {
        margin: 8px 0 18px 0;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
      }
    }
  }
}
