@import "../../../assets/styles/global.scss";

.a-switch {
  display: flex;

  .a-switch-item {
    border-radius: 40px;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    width: 148px;
    height: 50px;
    padding: 18px 10px 15px;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: var(--primary-dark-color);
    text-transform: capitalize;
    text-align: center;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
    }
  }

  .a-switch-selected {
    background-color: var(--primary-color);
    color: var(--white-color) !important;
  }
}
