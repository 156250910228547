@import "/src/assets/styles/global.scss";

.o-hub {
  margin-top: 30px;
  margin-bottom: 80px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  @media (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0px;
  }
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    &__title {
      @include TTCommonsDemiBold;
      color: var(--primary-font-color);
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__info-icon {
      max-width: 16px;
      margin: -2px 0 0 8px;
      vertical-align: middle;
    }
  }
  &__body {
    margin-top: 30px;
  }
}
