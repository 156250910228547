/**
  
  Author: Muhammad Atif
  Date added: 12th August,2022
  Email: atif@interstride.com

**/
@mixin flex-column() {
  display: flex;
  flex-direction: column;
}
@mixin flex-column-center() {
  @include flex-column();
  justify-content: center;
  align-items: center;
}

@mixin flex-row() {
  display: flex;
  flex-direction: row;
}

@mixin flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

@mixin flex-row-center {
  @include flex-row();
  justify-content: center;
}

@mixin flex-row-align-center {
  @include flex-row-center;
  align-items: center;
}

@mixin flex-row-reverse-center {
  @include flex-row-reverse();
  justify-content: center;
}

@mixin flex-row-space-between {
  @include flex-row();
  justify-content: space-between;
}
@mixin flex-row-space-evenly {
  @include flex-row();
  justify-content: space-evenly;
}
