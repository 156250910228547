@import "../../../assets/styles/global.scss";

.o-university-chat {
  background-color: #fff;
  max-width: 906px;
  margin: 0 auto;
  @media (max-width: 930px) {
    padding: 16px;
  }
  .MuiTabs-flexContainer {
    justify-content: center !important;
  }
  .MuiTabs-root {
    background-color: red !important;
  }

  &__tab-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 23px;
      text-align: center;
      font-family: "TTCommons-regular";
      color: var(--primary-font-color);
      text-transform: capitalized !important;
    }

    &--active {
      span {
        color: var(--primary-color);
      }
      svg {
        path,
        polygon,
        polyline {
          stroke: var(--primary-color);
        }
      }
    }
  }

  &__tab-content {
    &__footer-branding {
      @include flex-row;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 24px;
      h3 {
        @include TTCommonsRegular;
        color: var(--primary-font-color);
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-weight: 400;
        margin: 1px 8px 0 0;
      }
      img {
        max-width: 100px;
      }
    }
  }

  &__invalid-token {
    height: 100%;
    min-height: 100vh;
    padding: 48px 16px 114px 16px;
    position: relative;
    @media (max-width: 767px) {
      padding: 48px 16px 48px 16px;
    }
    &__content {
      max-width: 480px;
      margin: 0 auto;
      text-align: center;
      &__sitelogo {
        img {
          max-width: 234px;
          max-height: 100px;
          vertical-align: middle;
        }
      }
      h2 {
        color: var(--primary-font-color);
        @include TTCommonsMedium;
        font-size: 42px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;
        margin: 30px 0 16px;
        margin-top: 100px;
      }
    }
    h6 {
      color: var(--secondary-font-color);
      font-family: "TT Commons";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      @include TTCommonsRegular;
      font-weight: 400;
      max-width: 500px;
      margin: 80px auto 0;
      a {
        color: var(--primary-color);
        text-decoration: none;
        border-bottom: 1px solid var(--primary-color);
      }
      @media (max-width: 767px) {
        margin-top: 80px;
      }
    }
  }
}
