@import "../../../assets/styles/global.scss";

.a-menu-item {
  display: flex;
  flex-direction: row;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 30px;
  position: relative;

  @media (max-width: 1367px) {
    margin: 0 16px;
  }
  @media (max-width: 992px) {
    margin: 0 8px;
  }
  @media (max-width: 767px) {
    margin: 0 12px;
  }

  &:last-child {
    ul {
      @media (max-width: 767px) {
        right: 0;
        left: auto;
        &:before {
          @media (max-width: 767px) {
            right: 10px;
            left: auto;
          }
        }
        &:after {
          @media (max-width: 767px) {
            right: 10px;
            left: auto;
          }
        }
      }
    }
  }

  &--active {
    > a {
      span:first-of-type {
        border-bottom: 1px solid var(--primary-font-color);
      }
    }
  }

  &__link {
    text-decoration: none;
    color: var(--primary-font-color);
    @include flex-row-center();
    align-items: center;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    position: relative;
    border-bottom: 1px solid transparent;
    span {
      padding-top: 4px;
      margin-top: 4px;
    }
    &:hover {
      border-color: var(--primary-font-color);
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: 992px) {
      font-size: 18px;
      line-height: 20px;
    }
    > div {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      @include flex-column-center();
      background: var(--gray-100);
      margin-right: 11px;
      padding: 8px;
      @media (max-width: 1280px) {
        width: 38px;
        height: 38px;
        margin-right: 4px;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  &__notification_badge {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    min-width: 6px;
    background-color: var(--main-color);
    margin-left: 4px;
  }
}
