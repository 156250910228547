.button-wrapper {
  padding-top: 15px;
  max-width: 320px;
  margin: auto;
}

.button-cancel {
  margin-top: 30px !important;
  cursor: pointer;
  span {
    color: var(--secondary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 16px;
    border-bottom: 2px solid var(--secondary-font-color);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
