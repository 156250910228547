@import "../../../assets/styles/global.scss";
.a-topic-card {
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  overflow: hidden;
  max-width: 375px;
  padding: 16px 16px;
  padding-bottom: 0;
  position: relative;
  min-height: 124px;
  background-color: #fff;
  @media (max-width: 1280px) {
    max-width: 320px;
    padding: 16px 12px;
    min-height: 118px;
  }
  @media (max-width: 767px) {
    min-height: 92px;
  }
  &__profile {
    display: flex;
    align-items: center;
    position: absolute;
    left: 16px;
    top: 16px;
    @media (max-width: 1280px) {
      left: 12px;
      top: 16px;
    }
    &__image {
      width: 73px;
      height: 73px;
      min-width: 73px;
      border-radius: 100px;
      overflow: hidden;
      @media (max-width: 767px) {
        width: 60px;
        height: 60px;
        min-width: 60px;
        > div {
          > div {
            width: 60px !important;
            height: 60px !important;
            > div {
              width: 60px !important;
              height: 60px !important;
              > div {
                font-size: 18px !important;
              }
            }
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &__description {
    margin: 0;
    padding-left: 81px;
    padding-top: 4px;
    padding-bottom: 0;
    @media (max-width: 767px) {
      padding-left: 70px;
      padding-top: 0;
    }
    h2 {
      @include TTCommonsMedium;
      margin: 0 0 0px 0;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      color: var(--primary-font-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--tag {
      background-color: var(--gray-100);
      padding: 4px 10px 0px;
      display: inline-block;
      border-radius: 45px;
      font-size: 14px;
      height: 20px;
      margin-bottom: 6px;
      margin-top: 2px;
    }
    &__body_wrapper {
      position: relative;
      p {
        margin: 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        min-height: 45px;
        display: inline;
        // Line Clamping
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  &__btns-wrapper {
    display: flex;
    &__btn-secondary {
      margin-top: 15px;
      background-color: var(--white-color);
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      width: 100%;
      justify-content: center;
      cursor: pointer;
      margin-top: 8px;
    }
    button {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      font-family: "TTCommons-Medium";
      border-radius: 100px;
      padding: 2px 16px 0;
      min-height: 30px;
      display: flex;
      align-items: center;
      min-width: 101px;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        margin-bottom: 8px;
      }
      + button {
        margin-left: 8px;
      }
    }
  }
}
