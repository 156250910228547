@import "../../../assets/styles/global.scss";
.m-prospect-sign-in-form-container {
  h2 {
    color: var(--primary-font-color);
    @include TTCommonsMedium;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 26px 0 10px 0;
  }
  &__formfields {
    max-width: 325px;
    margin: 0 auto;
    > div {
      + div {
        margin-top: 15px;
      }
    }
  }
  button {
    max-width: 325px;
  }
}
.m-prospect-sign-in-form-container__formfields {
  max-width: 325px;
  margin: 0 auto;

  &__user-profile {
    text-align: center;
    > label {
      color: var(--field-label-color);
      font-family: "TTCommons-Regular";
      font-weight: 400;
      display: block;
      padding: 5px 0 0px 0px;
      margin: 0 0 7px 0;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 12px;
    }
    > div {
      justify-content: center;
    }
  }
  &--group {
    margin-top: 15px;
    input {
      text-align: center;
    }
  }
  &--checkbox {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    label {
      text-align: left;
      font-size: 16px !important;
      line-height: 17px !important;
    }
  }
  &--textarea div div div div {
    padding: 0px !important;
  }
}
