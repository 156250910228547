@import "../../../assets/styles/global.scss";
.o-login-container {
  background-color: #fff;
  min-height: calc(100vh - 0px);
  @media (max-width: 992px) {
    margin: 0;
  }
  > div {
    @media (max-width: 992px) {
      padding: 0;
    }
  }
}
