@import "/src/assets/styles/global.scss";
.m-card-container {
  display: flex;
  flex-direction: column;
  min-height: 180px;
  padding: 20px;
  @include center;
  @include my($top: 15px);
  background-color: $color-white;
  @include radius(8px);
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  img {
    margin-left: auto;
    @media (max-width: 992px) {
      margin: 0 auto;
    }
  }
  &__heading {
    font-weight: 500;
    font-size: 22px;
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    margin: 0;
    margin-top: auto;
  }
  &__description {
    font-weight: 500;
    font-size: 18px;
    font-family: "TTCommons-Regular";
    color: var(--primary-font-color);
    margin-bottom: auto;
  }
}
