@import "../../../assets/styles/global.scss";
.a-modal {
  &.a-modal__center {
    align-items: center;
    justify-content: center;
  }
  transition: opacity 0.15s linear;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  overflow: hidden;
  background-color: rgba(128, 148, 171, 0.4);
  padding: 0 16px;
  overflow-y: auto;
  // Scroll styling start
  scrollbar-width: thin !important;
  -ms-overflow-style: auto;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $color-grey transparent; /* scroll thumb and track */
  transition: all ease-in-out 500ms;
  &:hover {
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 50px;
      transition: all ease-in-out 500ms;
    }
  }
  &::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
    height: 50px;
    transition: all ease-in-out 500ms;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    width: 7px;
    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 10px 0 10px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-grey !important; /* color of the scroll thumb */
    border-radius: 50px; /* roundness of the scroll thumb */
    height: 150px !important;
  }
  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 0;
    background-color: $color-dark-gray !important; /* color of the scroll thumb */
  }
  // Scroll styling end
  &__dialog {
    margin: 1.75rem auto;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
    // on modal open add below two css
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 100%;
    flex-basis: initial !important;

    &--dashboard-post {
      max-width: 800px !important;
      margin: auto 0;
      > div {
        > div {
          padding: 4px 30px 1px;
          @media (max-width: 767px) {
            padding: 4px 15px 1px;
          }
          > div {
            @media (max-width: 767px) {
              margin-bottom: 14px;
              margin-top: 32px;
            }
          }
        }
      }
      > button {
        right: 28px;
        @media (max-width: 767px) {
          right: 14px;
          top: 16px;
        }
      }
    }

    &--resources-list {
      max-width: 1080px !important;
      > div {
        > button {
          right: 24px;
          top: 20px;
          @media (max-width: 767px) {
            right: 16px;
            top: 12px;
          }
        }
        &:first-child {
          > div {
            padding: 48px 24px;
            @media (max-width: 767px) {
              padding: 32px 16px;
            }
            > div {
              margin: 0;
              iframe {
                min-height: 75vh;
              }
            }
          }
        }
        + button {
          top: 18px;
          right: 22px;
        }
      }
    }

    &__content {
      border-radius: 10px;
      box-shadow: var(--box-shadow);
      background-color: var(--white-color);

      &__close {
        border: 0;
        background: transparent;
        cursor: pointer;
        padding: 0;
        margin-top: -2px;
        position: absolute;
        z-index: 3;
        right: 30px;
        top: 34px;
        display: flex;
        img {
          width: 18px;
        }
        @media (max-width: 767px) {
          right: 15px;
        }

        &--dashboard-post {
          right: 30px;
          top: 30px;
          @media (max-width: 767px) {
            right: 15px;
            top: 15px;
          }
        }

        &--feedback-confirmation {
          top: 30px;
          right: 30px;
        }
      }
      &__header {
        @include flex-row;
        align-items: flex-start;
        padding: 30px 32px 4px 32px;
        &__title {
          @include TTCommonsMedium;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          padding: 2px 0 0 0;
          text-align: center;
          max-width: 354px;
          margin: 0 auto;
          color: var(--primary-font-color);
          @media (max-width: 767px) {
            max-width: 300px;
          }
          &--feedback-confirmation {
            max-width: none;
            padding-bottom: 6px;
            font-size: 22px;
            padding-top: 0;
            line-height: 24px;
          }
        }

        &__small-title {
          @include TTCommonsMedium;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          max-width: 354px;
          margin: 0 auto;
          color: var(--primary-font-color);
          padding-top: 4px;
          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 20px;
            padding: 0 16px;
            padding-top: 2px;
          }
        }
        &__close {
          border: 0;
          background: transparent;
          cursor: pointer;
          padding: 0;
          margin-top: 0px;
          img {
            width: 18px;
          }
        }
      }
      &__body {
        @include center;
        padding: 4px 30px 26px;
        min-height: 144px;
        @media (max-width: 767px) {
          padding: 4px 16px 30px;
        }
        p {
          @include TTCommonsRegular;
          color: var(--primary-font-color);
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          margin: 0;
          + p {
            margin-top: 24px;
          }
        }
        &.feedback-confirmation-body {
          padding: 4px 30px 30px !important;
          min-height: fit-content !important;
        }
      }
      &__footer {
        @include flex-column-center();
        padding: 4px 60px 30px 60px;
        @media (max-width: 1280px) {
          padding: 4px 30px 30px 30px;
        }
        @media (max-width: 767px) {
          padding: 16px 16px 30px 16px;
        }
      }

      // &--dashboard-post {
      //   background-color: black;
      // }
    }

    // &--dashboard-post {
    //   background-color: red;
    // }
  }
}
