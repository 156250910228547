@import "../../../assets/styles/global.scss";

.a-header-user-profile-icon {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  border: 3px solid var(--light-gray-color);
  overflow: hidden;
  &--closeicon {
    display: flex;
    width: 37px;
    height: 37px;
    align-items: center;
    justify-content: center;
    svg {
      width: 28px;
      height: 28px;
      position: relative;
      top: -1px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &--active {
    border-color: var(--primary-color);
  }
  &--close {
    border: 3px solid var(--light-gray-color);
  }
  > a {
    width: 100%;
    height: 100%;
  }
  &__initial-avtar {
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;
      > div {
        width: 100% !important;
        height: 100% !important;
        > div {
          width: 100% !important;
          height: 100% !important;
          > div {
            font-size: 18px !important;
            line-height: 19px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px !important;
              line-height: 19px !important;
              > span {
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }

  &__menu {
    position: absolute;
    border-radius: 10px 1px 10px 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: var(--box-shadow);
    width: 200px;
    padding: 0px 16px;
    transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 2;
    right: 15px;
    margin-top: 8px;
    span {
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      text-align: center;
      text-decoration: none;
      display: block;
      padding: 18px 0px 16px;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      line-height: 18px;
      -webkit-box-pack: center;
      justify-content: center;
      span {
        padding: 0;
        border-bottom: 2px solid transparent;
        display: inline-block;
      }
      &:hover {
        > span {
          border-bottom-color: var(--primary-dark-color);
        }
      }
    }
    span:nth-child(2) {
      border-top: 2px solid #eaeef3;
    }
  }
}
