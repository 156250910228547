@import "../../../../assets/styles/global.scss";

.o-profile-edit-email-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 326px;
  margin: 0 auto;
  &-label {
    margin-top: 1rem;
    span {
      font-size: 15px;
      color: var(--primary-color);
    }
  }
  &-input {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    input {
      padding: 21px 22px 17px;
      text-align: center;
    }
  }
  &-note {
    margin-bottom: 10px;
    p {
      color: var(--primary-font-color);
      margin-top: 0px !important;
      margin-bottom: 1rem;
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      margin: 0 0 10px 0;
      line-height: 17px;
    }
  }
  button {
    width: 100%;
  }
}
