@import "../../../assets/styles/global.scss";

.m-resource-categories {
  display: flex;
  justify-content: center;
  &__loader {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 31px;
      text-align: center;
      margin: 0px 0px 10px 0px;
    }
    img {
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -10px;
    width: 100%;
    @media (max-width: 600px) {
      margin: 0;
      width: 100%;
    }
    &__card {
      padding: 0 10px;
      width: 20%;
      margin: 0 0 20px 0;
      @media (max-width: 1366px) {
        width: 25%;
      }
      @media (max-width: 991px) {
        width: 33.33%;
      }
      @media (max-width: 600px) {
        width: 50%;
        padding: 0 8px;
      }
      > div {
        margin-bottom: 0;
        padding: 16px 16px 24px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--light-gray-color);
        }
      }
      > div > div > div {
        padding: 0px !important;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 600px) {
          padding: 15px !important;
        }
      }
      &__item {
        width: 100%;
        &__image {
          width: 100%;
          height: 140px;
          margin-bottom: 16px;
          @media (max-width: 600px) {
            height: 100px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        span {
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          display: block;
        }
      }
    }
  }
}
