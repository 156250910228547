@import "/src/assets/styles/global.scss";
.o-chat-container {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 30px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &__swipe-indication {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
      margin-bottom: 15px;
    }
    .left-section {
      display: flex;
    }
    &-settings {
      position: relative;
    }
    &-heading {
      display: flex;
      align-items: center;
      span {
        margin: 0;
        color: var(--primary-dark-color);
        font-family: "TTCommons-DemiBold";
        font-size: 22px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
      }
      img {
        max-width: 16px;
        margin: 0 0 0 8px !important;
        vertical-align: middle;
      }
    }
    &-button {
      margin-left: 17px;
      margin-top: -4px;
      @media (max-width: 320px) {
        button {
          font-size: 16px !important;
        }
      }
      @media (max-width: 767px) {
        margin-top: -2px;
        width: auto;
      }
    }
  }
}
