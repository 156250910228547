@import "../../../assets/styles/global.scss";
.chat-widget {
  max-width: 375px;
  margin-left: auto;
  padding: 16px;
  &__card {
    height: 250px !important;
    width: 400px !important;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    overflow: hidden;
    padding: 25px 20px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__loader {
    width: 100%;
    height: 360px;
    display: flex;
    align-items: flex-end;
  }
  &__invalid-token {
    height: 100% !important;
    width: 100% !important;

    position: relative;
    &__content {
      max-width: 480px;
      margin: 0 auto;
      text-align: center;
      &__sitelogo {
        img {
          height: 50px;
        }
      }
      h2 {
        color: var(--primary-font-color);
        @include TTCommonsMedium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin: 16px 0 16px 0;
      }
    }
    h6 {
      color: var(--secondary-font-color);
      font-size: 18px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      @include TTCommonsRegular;
      font-weight: 400;
      max-width: 500px;
      margin: 0 !important;
      a {
        color: var(--primary-color);
        text-decoration: none;
        border-bottom: 1px solid var(--primary-color);
      }
    }
  }
  &__content {
    width: 100%;
    height: 360px;
    display: flex;
    align-items: flex-end;
    &__no-chats {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h2 {
        color: var(--primary-font-color);
        @include TTCommonsMedium;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin: 10px 0 10px 0;
      }
      img {
        width: 170px;
      }
    }
    &__student {
      position: relative;
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      max-height: 360px;
      overflow-y: auto;
      padding: 16px;
      width: 100%;
      background: #fff;
      scrollbar-width: thin !important;
      -ms-overflow-style: auto;
      scrollbar-width: thin; /* "auto" or "thin" */
      scrollbar-color: $color-grey transparent; /* scroll thumb and track */
      transition: all ease-in-out 500ms;
      &:hover {
        overflow-y: auto;
        &::-webkit-scrollbar {
          height: 50px;
          transition: all ease-in-out 500ms;
        }
      }
      &::-webkit-scrollbar {
        width: 7px; /* width of the entire scrollbar */
        height: 50px;
        transition: all ease-in-out 500ms;
      }
      &::-webkit-scrollbar-track {
        background: transparent !important; /* color of the tracking area */
        border-radius: 50px;
        width: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px 29px
          0px;
        margin: 10px 0 10px 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-grey !important; /* color of the scroll thumb */
        border-radius: 50px; /* roundness of the scroll thumb */
        height: 150px !important;
      }
      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }
      &::-webkit-scrollbar-thumb:hover {
        border: 0;
        background-color: $color-dark-gray !important; /* color of the scroll thumb */
      }
      @media (max-width: 375px) {
        width: 340px;
        margin-left: auto;
      }
      &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        svg {
          height: 18px;
          width: 18px;
          cursor: pointer;
        }
        + div {
          padding: 0;
          box-shadow: none;
        }
      }
    }
  }
}
