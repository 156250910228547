@import "../../../assets/styles/global.scss";

.m-auth-role-selection {
  height: 100%;
  min-height: 100vh;
  padding: 24px 0 48px 0;
  position: relative;
  @media (max-width: 767px) {
    padding: 24px 16px 24px 16px;
  }
  &__back-btn {
    position: absolute;
    left: 30px;
    top: 80px;
    @media (max-width: 767px) {
      position: initial;
      margin-bottom: 8px;
    }
    a {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 25px;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
      height: 50px;
      width: 90px;
      -webkit-box-pack: center;
      justify-content: center;
      text-decoration: none;
      color: rgb(128, 148, 171);
      font-family: TTCommons-Regular;
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 19px;
      transition: all 0.3s ease 0s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
        background-color: rgb(255, 255, 255);
      }
      img {
        margin: 1px 4px 0px -4px;
        height: 15px;
        transform: rotate(180deg);
      }
      span {
        padding-top: 6px;
      }
    }
  }
  &__content {
    max-width: 554px;
    margin: 0 auto;
    text-align: center;
    &__unlogo {
      img {
        max-width: 168px;
        vertical-align: middle;
        max-height: 100px;
      }
    }
    &__sitelogo {
      @include flex-row;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      h3 {
        @include TTCommonsRegular;
        color: var(--primary-font-color);
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-weight: 400;
        margin: 4px 8px 0 0;
      }
      img {
        max-width: 100px;
      }
    }
    &__select {
      margin: 30px 0px 30px 0px;
    }
  }

  h2 {
    color: var(--primary-font-color);
    font-family: "TTCommons-DemiBold";
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin: 28px auto 20px;
    padding: 8px 0px 0px;
    max-width: 330px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 30px;
      margin: 16px auto 12px;
    }
  }

  h5 {
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin: 0 auto 20px;
    max-width: 330px;
    a {
      color: var(--link-color);
      margin-left: 4px;
      border-bottom: 1px solid var(--link-color);
      text-decoration: none;
    }
  }
  button {
    max-width: 325px;
    margin: 0 auto;
  }
}
