@import "/src/assets/styles/global.scss";

.a-password-textbox {
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }

  &__start-adornment {
    width: 42px;
  }
}
