@import "/src/assets/styles/global.scss";

.message {
  margin-bottom: 25px;
  &__own {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: 11px;
      width: 100%;
      &__title {
        margin: 0 8px 4px 0;
        span {
          display: flex;
          align-items: center;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 15px;
          line-height: 14px;
          color: var(--secondary-font-color);
          strong {
            font-family: "TTCommons-Medium";
            color: var(--primary-font-color);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 30px;
            height: 24px;
            margin-right: 6px;
            position: relative;
            top: -3px;
          }
        }
      }
      &__item {
        border: 1.5px solid #d1dde5;
        border-radius: 10px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 78%;
        position: relative;
        &__parent-message {
          border-left: 2px solid #000485;
          border-radius: 6px;
          margin: 0 0 5px 0;
          max-width: 100%;
          min-width: 100%;
          cursor: pointer;
          background-color: #f8f8f8;
          padding: 5px 8px;
          &__header {
            margin: 0 0 0 0;
            font-family: "TTCommons-Medium";
            color: var(--primary-font-color);
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
          }
          &__body {
            margin: 0 0 0 0;
            color: #51688b;
            font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
            font-weight: 400;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: auto !important;
          }
        }
        &__message {
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
          white-space: pre-line;
          overflow-wrap: anywhere;
          padding-inline: 8px;
          display: block;
          margin-top: 4px;
        }
        &__no-message-attachment {
          color: $grey-text-color;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
          padding-inline: 8px;
        }
        &__attachment {
          padding-inline: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 5px;
          text-decoration: none;
          cursor: pointer;
          width: 100%;
          span {
            font-family: "TTCommons-Regular";
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #0030b5;
            margin-left: 3px;
            height: 17px;
            width: fit-content;
            // Text truncate
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            margin-top: 3px;
            min-width: 21px;
          }
        }
      }
      &__item__system {
        border: none !important;
        padding: 8px 10px 5px 18px !important;
        .message__own__content__item__message {
          color: $color-grey-2 !important;
          font-size: 15px !important;
        }
      }
    }
    &__info {
      &__image {
        border: 1.5px solid #bfced8;
        border-radius: 100%;
        margin-bottom: 3px;
      }
    }
  }
  &__other {
    display: flex;
    align-items: flex-end;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-left: 11px;
      width: 100%;
      &__title {
        margin-left: 7px;
        margin: 0 0 4px 8px;
        span {
          display: flex;
          align-items: center;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 15px;
          line-height: 14px;
          color: var(--secondary-font-color);
          strong {
            font-family: "TTCommons-Medium";
            color: var(--primary-font-color);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 30px;
            height: 24px;
            margin-right: 6px;
            position: relative;
            top: -3px;
          }
        }
      }
      &__item {
        background: #ebf1f7;
        border-radius: 10px;
        padding: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 78%;
        position: relative;
        &__parent-message {
          border-left: 2px solid #000485;
          border-radius: 6px;
          margin: 0 0 5px 0;
          max-width: 100%;
          min-width: 100%;
          cursor: pointer;
          background-color: #f8f8f8;
          padding: 5px 8px;
          &__header {
            margin: 0 0 0 0;
            font-family: "TTCommons-Medium";
            color: var(--primary-font-color);
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
          }
          &__body {
            margin: 0 0 0 0;
            color: #51688b;
            font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
            font-weight: 400;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: auto !important;
          }
        }
        &__message {
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
          white-space: pre-line;
          overflow-wrap: anywhere;
          padding-inline: 8px;
          display: block;
          margin-top: 4px;
        }
        &__no-message-attachment {
          color: $grey-text-color;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          font-size: 18px;
          line-height: 19px;
          padding-inline: 8px;
        }
        &__attachment {
          padding-inline: 8px;
          display: flex;
          align-items: center;
          margin-top: 5px;
          text-decoration: none;
          cursor: pointer;
          width: 100%;
          span {
            font-family: "TTCommons-Regular";
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #0030b5;
            margin-left: 3px;
            height: 17px;
            width: fit-content;
            // Text truncate
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          svg {
            margin-top: 3px;
            min-width: 21px;
          }
        }
      }
      &__item__system {
        background: none !important;
        padding: 8px 18px 5px 10px !important;
        .message__other__content__item__message {
          color: $color-grey-2 !important;
          font-size: 15px !important;
        }
      }
    }
    &__info {
      &__image {
        margin-bottom: 3px;
        border: 1.5px solid #bfced8;
        border-radius: 100%;
      }
    }
  }
}

.reaction {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 8px;
  position: relative;
  margin: 5px 44px 0 44px;
  &--own {
    flex-direction: row-reverse;
  }
  &__icon {
    cursor: pointer;
  }
  &__picker {
    position: absolute;
    top: 45px;
    left: 0px;
    z-index: 1;
    width: 100%;
    &--own {
      right: 0px;
      left: initial;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__item {
    &__emoji {
      cursor: pointer;
      display: flex;
      height: 35px;
      min-width: 61px;
      padding: 10.25px;
      border-radius: 45px;
      background: #f5f7f9;
      span {
        font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
        color: var(--primary-font-color);
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
      &--own {
        background: #e8eef2;
      }
    }
  }
}

.menu-ui {
  position: absolute;
  top: -36px;
  height: 36px;
  border: 1.5px solid #d1dde5;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  padding-inline: 10px;
  &__item {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &__emoji {
      width: 21px;
      height: 21px;
      font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
      font-size: 18px;
      margin: 0 0 0 0;
      &:hover {
        margin-top: -4px;
      }
    }
    &__icon-emoji-expand {
      width: 21px;
      height: 21px;
      margin-right: -4px;
    }
    &__icon-chat-reply {
      width: 21px;
      height: 21px;
    }
  }
  &__right {
    right: 0px;
  }
  &__left {
    left: 0px;
  }
}
