@import "/src/assets/styles/global.scss";

.m-calculator-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
  @media (max-width: 1200px) {
    margin-top: 0;
  }

  &__funding {
    margin: 0 0 16px 0;
    width: 100%;
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        margin: 0 0 10px 0;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin: 0;
      }
      &--tag {
        text-align: center;
        span {
          background: var(--gray-100);
          padding: 15px 16px;
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          border-radius: 100px;
          min-width: 134px;
          margin: 5px 0 15px 0;
          display: inline-block;
        }
      }
    }
  }

  &__scholarships {
    width: 100%;
  }
}
