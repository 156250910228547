@import "../../../assets/styles/global.scss";
.m-unsubscribe-notifications {
  height: 100%;
  min-height: 100vh;
  padding: 48px 16px 114px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    padding: 48px 16px 48px 16px;
  }

  &__unlogo {
    display: contents;

    img {
      max-width: 234px;
      max-height: 70px;
      vertical-align: middle;
    }
  }

  h2 {
    color: var(--primary-font-color);
    @include TTCommonsMedium;
    font-size: 42px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
    margin: 30px 0 16px;
  }

  h5 {
    color: var(--primary-font-color);
    @include TTCommonsMedium;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin: 15px 0px 40px 0;
  }
}
