@import "../../../../assets/styles/global.scss";
.a-pois-list-wrapper {
  background: white;
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 10px;
  padding: 30px 10px 30px 20px;
  max-height: 665px;
  width: 440px;
  @media (max-width: 1023px) {
    display: none;
  }
  .a-pois-list {
    max-height: 625px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    // Scrollbar style - START
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 100px;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d1dde5 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
    }

    &::-webkit-scrollbar-thumb:vertical {
      height: 10px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0px;
    }
    // Scrollbar style - END
  }
  > div {
    padding-right: 20px;
  }
}

.a-poi-marker {
  position: relative;
  &__info-window {
    position: absolute;
    bottom: 70px;
    left: 23px;
    background: #ffffff;
    border-radius: 10px 10px 10px 0px;
    padding: 20px;
    width: 410px;
  }
  &__pin {
    width: 48px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    svg {
      width: 36px;
      height: 43px;
    }
    img {
      position: absolute;
      top: 15px;
      left: 8px;
      width: 30px;
      height: 30px;
    }
    &--expanded {
      svg {
        width: 48px !important;
        height: 58px !important;
      }
      img {
        top: 0px;
        left: 3px;
        width: 40px;
        height: 40px;
      }
    }
    &--hidden {
      display: none;
    }
  }
}
