@import "/src/assets/styles/global.scss";
.m-group-profile-container {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin-left: 10px;
  position: relative;
  max-width: 100%;
  margin-left: 0;
  overscroll-behavior: none;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $color-grey transparent; /* scroll thumb and track */
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0;
    min-height: calc(100vh - 128px);
    max-height: calc(100vh - 128px);
  }
  &__close {
    position: absolute;
    left: 16px;
    background: transparent;
    border: 0;
    padding: 0;
    display: flex;
    @media (min-width: 993px) {
      display: none;
    }
  }
  .group-profil__container--actions {
    width: 100%;
    position: relative;
  }
  .profile-shimmer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // min-width: 300px;
    &__title {
      width: 200px;
      height: 20px;
    }
    &__text {
      width: 200px;
      height: 8px;
    }
    &__avatar {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
    &__line {
      width: 80%;
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      height: 25px;
      border-radius: 4px;
    }
  }
  .group-profile__title {
    margin-top: 10px;
    > span {
      color: var(--primary-font-color);
      font-size: 18px;
      margin: 0;
      @include TTCommonsMedium;
      display: block;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 15px;
      padding-top: 4px;
    }
  }
  .group-description {
    margin-top: 4px;
    color: var(--primary-font-color);
    font-size: 15px;
    font-weight: 400;

    p {
      color: var(--primary-font-color);
      font-size: 15px;
      letter-spacing: 0;
      line-height: 19px;
      margin: 8px 0 0 0;
    }
  }
  .group-participants--count {
    @include TTCommonsMedium;
    color: var(--primary-font-color);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    margin: 16px 0 8px 0;
  }
  .participants {
    max-height: 165px;
    overflow-y: auto;
    @media only screen and (min-width: 1024px) {
      max-height: 250px;
    }
    @media only screen and (min-width: 1440px) {
      max-height: 300px;
    }
    @media only screen and (min-width: 2560px) {
      max-height: 450px;
    }

    &::-webkit-scrollbar {
      width: 4px; /* width of the entire scrollbar */
      background-color: transparent !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 55px;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important; /* color of the tracking area */
      border-radius: 60px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-grey-4 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      height: 70px !important;
      border-right: none;
      border-left: none;
      box-shadow: inset 0 0 5px $color-grey-4;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
    }

    &::-webkit-scrollbar-button {
      width: 50px; //for horizontal scrollbar
      height: 50px; //for vertical scrollbar
    }
  }
}

.m-group-profile-container::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.m-group-profile-container::-webkit-scrollbar-track {
  background: transparent !important; /* color of the tracking area */
  border-radius: 50px;
}

.m-group-profile-container::-webkit-scrollbar-thumb {
  background-color: $color-grey !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 10px !important;
}

.m-group-profile-container::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.m-group-profile-container::-webkit-scrollbar-thumb:hover {
  border: 0px;
}
