@import "../../../assets/styles/global.scss";

.m-widget-sign-up-overlay {
  background-color: rgba(241, 244, 247, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1;
  box-shadow: var(--box-shadow);
  &__prompt {
    &__close {
      display: flex;
      margin: 20px 20px 20px auto;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: flex-end;
      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      margin-bottom: 150px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      margin-top: 0;
      margin-bottom: 7px;
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      line-height: 31px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 27px;
      font-family: "TTCommons-Regular";
      font-size: 22px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    button {
      width: 100%;
      max-width: 325px;
      font-family: "TTCommons-Medium";
    }
  }
  &__signup-view {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 54px 3px rgba(128, 148, 171, 0.4);
    max-width: 669px;
    margin: 30px auto 30px auto;
    padding: 50px 10px;
    text-align: center;
    @media (max-width: 767px) {
      padding: 24px 16px;
      margin-top: 16px;
    }
    &__close {
      display: flex;
      margin: -20px 20px 20px auto;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: flex-end;
      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 34px;
      padding-top: 4px;
      margin: 0 0 30px 0;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 31px;
        margin: 0 0 16px 0;
      }
    }

    h5 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin: 0 0 30px 0;
      padding-top: 4px;
      @media (max-width: 767px) {
        margin: 0 0 16px 0;
      }
      a {
        color: var(--link-color);
        margin-left: 4px;
        border-bottom: 1px solid var(--link-color);
        text-decoration: none;
      }
    }

    &__select {
      margin: 0px 0px 30px 0px;
    }

    button {
      width: 100%;
      max-width: 325px;
      font-family: "TTCommons-Medium";
    }
  }
}
