.masterclass-index-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 2.5rem;
  .record-container {
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white-color);
    border-radius: 10px;
    box-shadow: 0px 1px 14px 3px #000c3d1a;
    .record-content-wrapper {
      display: flex;
      gap: 5px;
      align-items: center;
      .record-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 85px;
          width: 130px;
          object-fit: contain;
          margin: 7.5px 0;
        }
      }
      .record-details {
        h3 {
          margin: 0 0 3px 0;
          font-family: "TTCommons-DemiBold";
          font-weight: 400;
        }
        h2 {
          margin: 3px 0 0 0;
          font-family: "TTCommons-Medium";
          font-weight: 500;
        }
        h5 {
          font-size: 18px;
          font-family: "TTCommons-Regular";
          line-height: 19px;
          width: 100%;
          color: var(--primary-font-color);
          font-weight: 400;
          margin: 2px 0 0 0;
        }
      }
    }
    .action-div {
      margin-right: 15px;
      a {
        padding: 15px 15px 10px 15px;
        text-decoration: none;
        box-shadow: var(--box-shadow);
        background-color: var(--primary-color);
        color: var(--white-color);
        border-radius: 100px;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        font-family: "TTCommons-Medium";
      }
      visibility: hidden;
    }
    &:hover {
      .action-div {
        visibility: visible;
      }
    }
  }
}
