@import "../../../assets/styles/global.scss";

.a-widget-chatlist-card {
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.6);
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background: #fff;
  width: 100%;
  @media (max-width: 767px) {
    padding: 16px;
  }
  &__profile {
    display: flex;
    align-items: center;
    &__pic {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    &__info {
      margin: 0 0 0 14px;
      width: 100%;
      overflow: hidden;
      &__line_one {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        width: 100%;
        span:nth-child(1) {
          margin: 0 25px 0 0 !important;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span:nth-child(2) {
          margin: 0 0 6px 0 !important;
          border-radius: 10.5px;
          background-color: #e5eaf7;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
          color: var(--primary-font-color);
          display: flex;
          align-items: center;
          padding: 3px 7px 0px;
          min-height: 20px;
        }
      }
      &__line_two {
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        max-width: 161.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 0 8px 0 !important;
        @media (min-width: 321px) {
          max-width: 200px;
        }
      }
      &__line_three {
        display: flex;
        align-items: center;
        margin-top: 6px;
        img {
          width: 30px;
          height: 19px;
          border-radius: 2px;
          background-color: #d8d8d8;
        }
        span {
          height: 15px;
          margin: 0 0 0 10px !important;
          color: var(--secondary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          line-height: 17px;
          letter-spacing: 0;
          max-width: 122.5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (min-width: 321px) {
            max-width: 162px;
          }
        }
      }
    }
  }
  &__body {
    margin: 20px 0 0 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 13px;
    &__intro {
      margin: 0;
      min-height: 35px;
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      line-height: 17px;
      letter-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    &__bottom {
      &__active-text {
        min-height: 17px;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        display: flex;
        &__bullet {
          width: 6px;
          height: 6px;
          background-color: #3ec460;
          border-radius: 50%;
          margin: 4px 5px 4px 0px;
        }
      }
      &__button {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }
  }
}
