/**
  
  Author: Muhammad Atif
  Date added: 12th August,2022
  Email: atif@interstride.com

**/
@mixin TTCommonsRegular {
  font-family: "TTCommons-Regular";
}
@mixin TTCommonsMedium {
  font-family: "TTCommons-Medium";
}

@mixin TTCommonsDemiBold {
  font-family: "TTCommons-DemiBold";
}
