@import "/src/assets/styles/global.scss";

.m-calculator-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 5px 0;
    h3 {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      margin: 0;
    }
    img {
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
  &__fixed {
    text-align: center;
    span {
      background: var(--gray-100);
      padding: 15px 23px;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      border-radius: 100px;
      min-width: 140px;
      margin: 5px 0 0 0;
      display: inline-block;
    }
  }
}
