.followed-community {
  &__no-community-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      color: var(--primary-font-color);
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center;
      max-width: 354px;
      font-family: "TTCommons-Medium";
      margin: 30px 0 20px 0;
    }
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      max-width: 336px;
      margin: 0 0 0 0;
      font-weight: 400;
    }
    &__button {
      margin-top: 50px;
      button {
        height: auto;
        padding: 20.85px 25px 14.85px;
        border-radius: 45px;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24.3px;
        text-align: center;
        font-family: "TTCommons-Medium";
        width: 250px;
        height: 60px;
        background: var(--primary-color);
        color: #ffffff;
      }
    }
  }
}
