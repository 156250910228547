@import "../../../assets/styles/global.scss";

.o-student-services {
  margin: 30px 0 50px 0;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 30px 15px 30px;
    margin: 0 auto;
  }
  @media (max-width: 1170px) {
    padding-bottom: 0px;
  }
  @media (max-width: 992px) {
    padding: 0;
    margin-top: 16px;
  }
  &__title {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      color: #000c3d;
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
      @media (max-width: 600px) {
        margin-bottom: 16px;
      }
    }
  }
  &__listing-services {
    display: flex;
    align-items: center;
    @media (max-width: 1199px) {
      justify-content: center;
    }
    &__button {
      cursor: pointer;
      background: #fff !important;
      box-shadow: var(--box-shadow) !important;
      color: var(--field-label-color);
      width: auto;
      padding: 18px 20px 12px;
      height: auto;
      min-height: 50px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Regular";
      border-radius: 100px;
      text-decoration: none;
      transition: all 0.3s;
      display: inline-block;
      text-transform: none;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
      }
      img {
        width: 8px;
        transform: rotate(180deg);
        vertical-align: middle;
        margin: -2px 8px 0 0;
      }
      span {
        height: 17px;
        margin-left: 5px;
        color: #8094ab;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  &__saved-services {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 991px) {
      justify-content: center;
    }
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 20px;
      width: 285px;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      span {
        margin-inline: 5px;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        height: 17px;
      }
    }
  }
  &__title-search-spacer {
    margin-top: 17px;
    @media (max-width: 767px) {
      margin-top: 16px;
    }
    @media (max-width: 600px) {
      margin-top: 0px;
    }
  }
  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      margin-bottom: 0px;
      width: 100%;
      max-width: 285px;
      input {
        border-radius: 45px;
        background-color: #fff;
        padding: 16.65px 20px 12.65px !important;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 19px;
        box-sizing: border-box;
        min-height: 50px;
        margin: 0;
        height: 50px;
      }
    }
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 10px;
      &:focus {
        opacity: 0.9;
        box-shadow: 0 0 0px 3px var(--light-gray-color) !important;
        border-radius: 45px;
      }
    }
    &__clear {
      position: relative;
      span {
        position: absolute;
        top: -10px;
        left: 20px;
        @media (max-width: 767px) {
          position: initial;
          margin-left: 8px;
        }
        letter-spacing: 0;
        font-size: 18px;
        line-height: 16px;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--secondary-font-color);
        cursor: pointer;
        padding-top: 3px;
        white-space: nowrap;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
      &:focus {
        span {
          color: var(--link-color) !important;
          border-color: var(--link-color) !important;
          opacity: 0.9 !important;
          outline: 2px solid var(--primary-font-color) !important;
        }
      }
    }
  }

  &__categories--loader {
    > div > div {
      margin-bottom: 10px;
    }
  }
  &__listing--loader {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    @media (max-width: 775px) {
      justify-content: center;
    }
  }

  &__search-deals-spacer {
    margin-top: 30px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  > div {
    &:last-child {
      > div {
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
        &:last-child {
          @media (max-width: 992px) {
            padding-top: 0;
          }
        }
      }
    }
  }
}
