.a-info-window {
  max-width: 373px;
  max-height: 116px;
  display: flex;
  cursor: pointer;
  &__body {
    flex-grow: 1;
    &__title {
      font-family: "TTCommons-Regular";
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      color: var(--primary-font-color);
      margin: 0 0 3px 0;
      word-break: break-word;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &__description {
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: var(--primary-font-color);
      margin: 0 0 3px 0;
      word-break: break-word;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &__actions {
      display: flex;
      gap: 7px;
    }
  }
  &__image {
    width: 107px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      width: 107px;
      height: 83px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}
