@import "/src/assets/styles/global.scss";

.a-categories-flush__list__item__link {
  @include flex-row;
  @include TTCommonsMedium;
  align-items: center;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  color: var(--primary-font-color);
  text-decoration: none;
  transition: all 0.3s ease 0s;
  img {
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
  }
}

.a-categories-flush__list__item__link_notifications {
  @include flex-row;
  @include TTCommonsMedium;
  align-items: center;
  padding: 10px 15px !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding-top: 5px;
  cursor: pointer;
  color: var(--primary-font-color);
  text-decoration: none;
  transition: all 0.3s ease 0s;
  @media (max-width: 1367px) {
    font-size: 18px;
    line-height: 19px;
  }
  > div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      @media (max-width: 1367px) {
        margin-right: 8px;
        width: 48px !important;
      }
    }
  }
  span {
    color: var(--primary-color);
  }
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
    @media (max-width: 992px) {
      box-shadow: none !important;
    }
  }
}

.a-topics-flush__list__item__link {
  @include flex-row;
  @include TTCommonsMedium;
  align-items: center;
  padding: 20px 12px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  color: var(--primary-font-color);
  text-decoration: none;
  @media (max-width: 992px) {
    padding: 12px 12px;
  }
  span {
    width: 50px;
    height: 50px;
    min-width: 50px;
    @include flex-column-center;
    border-radius: 100px;
    border: 2px solid var(--primary-font-color);
    margin-right: 10px;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
    padding-top: 8px;
    @media (max-width: 992px) {
      width: 44px;
      height: 44px;
      min-width: 44px;
      font-size: 24px;
    }
    @media (max-width: 767px) {
      width: 38px;
      height: 38px;
      min-width: 38px;
    }
  }
  img {
    margin-right: 10px;
    @media (max-width: 992px) {
      width: 44px;
    }
    @media (max-width: 767px) {
      width: 38px;
    }
  }
  h6 {
    margin: 0;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border-radius: 10.5px;
    background-color: #8c1e3f;
    min-width: 60px;
    margin-left: 4px;
    @include TTCommonsRegular;
    color: var(--white-color);
    font-weight: 400;
    padding: 3px 4px 2px;
  }
  &:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    span {
      border-color: var(--white-color);
      color: var(--white-color);
    }
  }
}
