@import "../../../assets/styles/global.scss";

.m-widget-chats-tab {
  &__user-type-switch {
    width: fit-content;
    margin: 0 auto 30px;
  }
  &__title {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    letter-spacing: 0;
    font-size: 30px;
    text-align: center;
    margin: 0 0 20px 0;
    line-height: 25px;
    padding-top: 6px;
    @media (max-width: 930px) {
      font-size: 22px;
      line-height: 22px;
      align-items: center;
      margin: 0 0 16px 0;
    }
  }
}
