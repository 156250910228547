@import "../../../assets/styles/global.scss";

.a-header-notification-icon {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border: 2px solid var(--light-gray-color);
  background-color: var(--light-gray-color);
  border-radius: 100px;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  &:hover {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    max-width: 24px;
  }
  &__badge {
    height: 16px;
    width: 16px;
    background-color: #f76155;
    color: var(--white-color);
    border-radius: 100px;
    position: absolute;
    font-family: "TTCommons-Medium";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    top: 2px;
    right: 4px;
    margin: 0 !important;
    > span {
      margin: 0;
      color: var(--white-color);
    }
    @media (max-width: 1280px) {
      top: 4px;
      right: 5px;
    }
    span {
      position: relative;
      top: 2px;
    }
  }
  &--active {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
}
