@import "/src/assets/styles/global.scss";

.a-circular-avatar {
  &__image {
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }
}
