@import "/src/assets/styles/global.scss";

.m-empty-chat-view {
  padding: 10px;
  margin-left: 0px;
  background-color: $color-white;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  h4 {
    color: var(--primary-font-color);
    font-size: 30px;
    font-family: "TTCommons-DemiBold";
    margin: 30px 0 30px 0;
  }
  p {
    color: var(--primary-font-color);
    font-size: 18px;
    font-family: "TTCommons-Regular";
    max-width: 400px;
    margin: 30px 0 30px 0;
  }
  img {
    max-width: 100%;
  }
}
@media screen and (max-width: 786px) {
  .empty-chat-view {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 0px;
    img {
      width: 250px;
    }
    p {
      width: 90%;
    }
    h4 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
}
