@import "../../../assets/styles/global.scss";

.m-widget-services {
  width: 100%;
  padding: 15px 0 0px 0px;
  > .col-sm-3 {
    &:nth-child(2) {
      max-width: 35%;
      flex-basis: 35%;
    }
    &:last-child {
      display: none;
    }
  }
  > .col-sm-6 {
    max-width: 65%;
    flex-basis: 65%;
  }
  &__content {
    display: flex;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    &__left {
      width: 100%;
      max-width: 210px;
      min-width: 210px;
      @media (max-width: 767px) {
        max-width: 100%;
      }
      &__title {
        @media (min-width: 993px) {
          display: none;
        }
      }
      &__categories {
        @media (min-width: 993px) {
          margin-top: 0 !important;
        }
      }
      ul {
        li {
          a {
            padding: 10px 12px 9px 12px;
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            min-height: 55px;
            > div {
              margin: 0px 8px 0 0;
              display: flex;
            }
          }
        }
      }
      > div {
        > div {
          &:first-child {
            @media (min-width: 767px) {
              display: none !important;
            }
          }
        }
      }
      &__categories {
        @media (min-width: 767px) {
          display: block !important;
        }
        > div {
          @media (min-width: 767px) {
            border-radius: 10px !important;
            max-height: 578px;
            overflow-y: auto;
            padding: 15px 10px 10px;
          }
        }
      }
    }
    &__right {
      width: 100%;
      padding-left: 16px;
      @media (max-width: 767px) {
        padding-left: 0px;
      }
      &__content {
        position: relative;
        min-height: 300px;
        > div:first-child {
          top: 0;
        }
      }
    }
  }

  .m-widget-services-list-wrapper {
    position: relative;
    &__overlay {
      background-color: rgba(241, 244, 247, 0.9);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      box-shadow: var(--box-shadow);
    }
    > div {
      padding: 20px 30px 20px 20px;
      margin-bottom: 20px;

      @media (max-width: 480px) {
        padding: 16px;
      }
    }

    .m-widget-services-list-item-wrapper {
      display: flex;
      align-items: center;

      @media (max-width: 480px) {
        flex-wrap: wrap;
        align-items: flex-start;
      }
      .m-widget-services-list-item-img-left {
        width: 122px;
        min-width: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        @media (max-width: 480px) {
          width: 100px;
          min-width: 100px;
        }
        img {
          max-height: 100px; //Tania's design change on image stretching
          width: 100%;
        }
      }
      .m-widget-services-list-item-content-wrapper {
        padding-left: 16px;
        width: calc(100% - 250px);
        @media (max-width: 480px) {
          width: calc(100% - 100px);
          margin-bottom: 8px;
        }
        h3 {
          font-size: 18px;
          line-height: 19px;
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          letter-spacing: 0;
          display: inline-block;
          text-align: left;
          margin: 0;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          span {
            margin-left: 6px;
            cursor: pointer;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
        p {
          @include TTCommonsRegular;
          margin: 0;
          color: var(--secondary-font-color);
          font-weight: 400;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
        }
      }
      button {
        @include TTCommonsMedium;
        padding: 5px 10px 0 10px;
        font-size: 18px;
        font-weight: 500;
        min-width: 113px;
        width: auto;
        margin-left: auto;
        @media (max-width: 480px) {
          margin-left: 116px;
        }
      }
    }
  }

  .m-widget-services-empty {
    text-align: center;
    width: 100%;
    h2 {
      font-size: 30px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      margin: 0 0 30px 0;
      color: var(--primary-font-color);
    }
    svg {
      max-width: 100%;
    }
  }
}
