.recom-details-card {
  position: relative;
  &__close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &__message-btn {
    margin-top: 20px;
  }
}
