@import "../../../assets/styles/global.scss";
.o-ambassador-signup-container {
  @media (max-width: 992px) {
    margin: 0;
  }
  > div {
    @media (max-width: 992px) {
      padding: 0;
    }
  }
}
