/**
  
  Author: Muhammad Atif
  Date added: 12th August,2022
  Email: atif@interstride.com

**/
$color-white: #ffffff;
$color-black: #000000;

$color-dark-gray: #607790;
$color-grey: #d1dde5;
$color-grey-2: #607790;
$color-grey-4: #bfced8;
$color-grey-6: #ececff;
$color-grey-8: #e5eaf7;
$color-grey-10: #8094ab;

$color-grey-10: #ccc;
$color-blue-2: #0030b5;
$color-orange: #f76155;
$grey-text-color: #607790;
$gray-outline-color: #d1dde5;

$color-blue: #000485;
