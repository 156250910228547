.Toastify {
  background-color: var(--primary-font-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1301;
  .Toastify__toast-container--bottom-center {
    width: 100% !important;
    max-width: 709px !important;
    bottom: 31px;
    min-width: 709px !important;
    @media (max-width: 767px) {
      min-width: auto !important;
      max-width: 100% !important;
      width: calc(100% - 24px) !important;
      left: 12px;
      transform: none;
      bottom: 40px;
    }
    .Toastify__toast {
      box-shadow: none;
      border: 0;
      min-height: auto;
      border-radius: 10px;
      background-color: #000485;
      margin: 0;
      padding: 20px 40px 17.3px 30px;
      transform: none !important;
      @media (max-width: 767px) {
        padding: 20px 16px 17.3px 16px;
      }
      + .Toastify__toast {
        margin-top: 10px;
      }
      .Toastify__toast-body {
        color: #ffffff;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        margin: 0;
        padding: 3px 0 0;
        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 16px;
        }
        .Toastify__toast-icon {
          @media (max-width: 767px) {
            display: none;
          }
        }
        svg {
          display: none;
        }
      }
      button {
        opacity: 1;
        svg {
          width: 16px;
          height: 16px;
          right: -10px;
          position: relative;
          color: #ffffff;
        }
      }
    }
    .Toastify__progress-bar {
      visibility: hidden;
    }
  }
}
