@import "../../../assets/styles/global.scss";

.a-header-help-center-icon {
  a {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border: 2px solid var(--light-gray-color);
    background-color: var(--light-gray-color);
    border-radius: 100px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    &:hover {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
    }

    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &--active {
    a {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
    }
  }
}
