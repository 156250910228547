.m-invite-friend {
  margin-bottom: 15px;
  h3 {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin: 0;
    padding-top: 4px;
    text-align: center;
  }
  h4 {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    margin: 12px 0 20px 0;
    padding-top: 4px;
    text-align: center;
  }
  &__button {
    button {
      width: 100% !important;
      margin: 0 0 0 0 !important;
    }
  }
  &__modal {
    > div {
      > div {
        > div {
          > div:nth-child(3) {
            padding: 4px 70px 30px !important;
          }
        }
      }
    }
  }
}
