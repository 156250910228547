@import "/src/assets/styles/global.scss";

.a-radio-button {
  margin: 0;
  .MuiRadio-root {
    padding: 0;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    outline: 0;
    box-shadow: none;
    background-color: #f1f4f7;
    &:hover {
      background-color: #f1f4f7 !important;
    }
    .MuiSvgIcon-fontSizeSmall {
      display: none;
    }
    &.Mui-checked {
      border: 0;
      svg {
        display: block;
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0px 0 8px 0 !important;
  }
}
