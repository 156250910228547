@import "/src/assets/styles/global.scss";

.a-expand-toggle{
  display: inline;
  color: var(--primary-color);
  margin-inline-start: 0.5rem;
  font-family: 'TTCommons-Medium';
  cursor: pointer;
}

.a-expand-toggle:hover{
  text-decoration: underline;
}