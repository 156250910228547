.star {
  width: 22px;
  height: 22px;
  min-width: 22px;
  img {
    opacity: 0.8;
    cursor: pointer;
    &:focus,
    &:hover {
      opacity: 1;
    }
  }
  &--filled {
    img {
      opacity: 1;
    }
  }
}
