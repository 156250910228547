@import "../../../assets/styles/global.scss";

.m-widget-resources {
  width: 100%;
  padding: 30px 0 0px 0px;

  > .col-sm-3 {
    &:nth-child(2) {
      max-width: 35%;
      flex-basis: 35%;
    }
    &:last-child {
      display: none;
    }
  }
  > .col-sm-6 {
    max-width: 65%;
    flex-basis: 65%;
  }

  &__content {
    display: flex;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    &__left {
      width: 100%;
      max-width: 210px;
      min-width: 210px;
      @media (max-width: 767px) {
        max-width: 100%;
      }

      &__title {
        @media (min-width: 993px) {
          display: none;
        }
      }
      &__categories {
        @media (min-width: 993px) {
          margin-top: 0 !important;
        }
      }

      ul {
        li {
          a {
            padding: 10px 12px 9px 12px;
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            min-height: 55px;
            > div {
              margin: 0px 8px 0 0;
              display: flex;
            }
          }
        }
      }
      > div {
        > div {
          &:first-child {
            @media (min-width: 767px) {
              display: none !important;
            }
          }
        }
      }
      &__categories {
        @media (min-width: 767px) {
          display: block !important;
        }
        > div {
          @media (min-width: 767px) {
            border-radius: 10px !important;
            max-height: 578px;
            overflow-y: auto;
            padding: 15px 10px 10px;
          }
        }
      }
    }
    &__right {
      width: 100%;
      padding-left: 16px;
      @media (max-width: 767px) {
        padding-left: 0px;
      }
      &__content {
        position: relative;
        min-height: 300px;
        > div:first-child {
          top: 0;
        }
      }
    }
  }

  .m-widget-resources-list-wrapper {
    position: relative;
    &__overlay {
      background-color: rgba(241, 244, 247, 0.9);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      box-shadow: var(--box-shadow);
    }

    > div {
      margin-bottom: 20px;
      @media (max-width: 480px) {
        max-height: initial;
      }
      + div {
        margin-top: 20px;
      }
    }
    .m-widget-resources-item-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: 480px) {
        flex-wrap: wrap;
      }
      .m-widget-resources-thumbnail {
        width: 174px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        background: rgba(241, 244, 247, 0.5);
        @media (max-width: 480px) {
          min-height: 120px;
        }
        @media (max-width: 480px) {
          width: 100%;
          height: auto;
          min-height: 120px;
        }
        .m-widget-resources-thumbnail-video {
          object-fit: cover !important;
          height: 100% !important;
          width: 100% !important;
        }
        .m-widget-resources-thumbnail-video-play-icon {
          width: 60px !important;
          height: 60px;
          position: absolute;
          cursor: pointer;
        }
        .m-widget-resources-thumbnail-non-video {
          max-height: 62px;
        }
        .m-widget-resources-thumbnail-image {
          object-fit: cover !important;
          height: 100% !important;
          width: 100% !important;
        }
      }
      .m-widget-resources-description {
        width: calc(100% - 306px);
        padding-left: 1rem;
        @media (max-width: 480px) {
          width: 100%;
          padding-left: 0;
          padding: 16px;
        }
        .m-widget-resources-description-header-primary {
          h3 {
            font-size: 18px;
            line-height: 19px;
            color: var(--primary-font-color);
            font-family: "TTCommons-Medium";
            font-weight: 500;
            letter-spacing: 0;
            display: inline-block;
            text-align: left;
            margin: 0;
            display: flex;
            align-items: flex-start;
            span {
              margin-left: 6px;
              cursor: pointer;
              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
          p {
            @include TTCommonsRegular;
            margin: 0;
            color: var(--secondary-font-color);
            font-weight: 400;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
          }
        }
        .m-widget-resources-description-header-secondary {
          font-size: 15px;
          letter-spacing: 0px;
          line-height: 15px;
          color: var(--secondary-font-color);
          font-family: "TTCommons-Regular";
          width: 100%;
          word-break: break-word;
        }
      }
      .m-widget-resources-link {
        margin-left: auto;
        margin-right: 30px;
        @media (max-width: 480px) {
          text-align: right;
          padding: 0 16px 16px 16px;
          margin: 0;
          width: 100%;
        }
        button {
          @include TTCommonsMedium;
          padding: 4px 10px 0 10px;
          font-size: 18px;
          font-weight: 500;
          min-width: 85px;
          width: auto;
        }
      }
    }
  }

  .m-widget-resources-empty {
    text-align: center;
    width: 100%;
    h2 {
      font-size: 30px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      margin: 0 0 30px 0;
      color: var(--primary-font-color);
    }
    svg {
      max-width: 100%;
    }
  }
}
