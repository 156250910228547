@import "/src/assets/styles/global.scss";

.a-file-upload-wrapper {
  cursor: pointer;
  background: var(--gray-100);
  width: 35px;
  height: 35px;
  border-radius: 100px;
  min-width: 35px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-right: 8px;
  input {
    display: none;
  }
  span {
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    left: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .a-file-upload-label {
    display: flex;
    cursor: pointer;
    img {
      width: 22px;
    }
  }
}
