@import "../../../assets/styles/global.scss";

.m-header-container {
  border-bottom: 2px solid var(--gray-200);
  background-color: #fafbfd;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 10;
  &__main {
    width: 100%;
    padding: 8px 15px;
    margin: 0 auto;
    @include flex-row-center();
    max-width: 1300px;
    align-items: center;
    position: relative;
    // @media (max-width: 1400px) {
    //   padding: 9px 24px;
    // }
    @media (max-width: 1367px) {
      max-width: 1030px;
      padding: 9px 15px;
    }
    @media (max-width: 992px) {
      padding: 8px 16px;
    }
    @media (max-width: 992px) {
      justify-content: space-between;
      padding-bottom: 42px;
      position: relative;
    }

    @media (max-width: 767px) {
      flex-direction: initial;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 8px;
    }
  }

  &__nav-menu {
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 8px;
    }
    @media (max-width: 767px) {
      position: initial;
      transform: none;
      justify-content: center;
    }
    &__menu-list {
      @include flex-row-center();
      align-items: center;
      padding: 0;
      margin: 0;
    }
    @media (max-width: 767px) {
      order: 3;
      margin-top: 5px;
      width: 100%;
    }
  }

  &__sub-menu-list {
    @include flex-column-center();
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 218px;
    border: 2px solid var(--gray-200);
    border-radius: 4px;
    background-color: var(--gray-300);
    top: 77%;
    z-index: 1;
    padding: 0;
    padding: 16px 0;
    margin-top: 28px;
    left: 0%;
    @media (max-width: 1280px) {
      padding: 8px 0;
      margin-top: 24px;
    }
    @media (max-width: 767px) {
    }
    &:before {
      content: "";
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 14px solid var(--gray-300);
      top: -12px;
      left: 15px;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 14px solid var(--gray-200);
      top: -15px;
      left: 15px;
    }

    &--show {
      display: block;
    }
  }

  &__actions-menu {
    @include flex-row-center();
    min-width: 192px;
    @media (max-width: 1280px) {
      min-width: auto;
    }
    @media (max-width: 480px) {
      width: 100%;
      margin-top: 8px;
    }
    > div {
      a {
        @include flex-column-center();
        position: relative;
      }
      + div {
        margin-left: 8px;
      }
    }
    .m-notifications__sidebar {
      width: 100%;
      height: calc(100% - 56px);
      max-width: 100%;
      position: fixed;
      top: 56px;
      right: -100%;
      background: #ffffff;
      padding: 0;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      z-index: 99;
      max-width: 595px;
      border: 2px solid #ebf1f7;
      @media (max-width: 1280px) {
        top: 56px;
      }
      @media (max-width: 992px) {
        top: 98px;
      }
      @media (max-width: 480px) {
        top: 140px;
      }
    }
    .m-notifications__sidebar_active {
      right: 0% !important;
    }
  }

  &__subheader {
    display: flex;
    justify-content: center;
    border-top: 2px solid var(--gray-200);
    margin: 0;
    padding: 10px 0px;
    @media (max-width: 767px) {
      padding: 8px 0;
      min-height: 40px;
    }
    li {
      position: relative;
      &:before {
        content: "";
        width: 22px;
        height: 22px;
        position: absolute;
        left: 50%;
        bottom: -30px;
        transform: translateX(-50%);
        z-index: 1;
        background: url("../../../assets/svg/f-subheader-active-arrow.svg");
        background-repeat: no-repeat;
        display: none;
        @media (max-width: 767px) {
          width: 22px;
          height: 16px;
          bottom: -18px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        width: 22px;
        height: 22px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -14px;
        z-index: 0;
        display: none;
        @media (max-width: 767px) {
          bottom: -18px;
        }
      }
      a {
        padding: 0;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          margin: 0 8px 0 0;
          @media (max-width: 991px) {
            min-width: 28px;
            width: 28px;
            height: auto;
          }
          @media (max-width: 767px) {
            margin-right: 0;
          }
        }
        span {
          border-bottom: 0 !important;
          padding: 4px 0 0 0;
          @media (max-width: 991px) {
            font-size: 16px;
            line-height: 18px;
          }
          @media (max-width: 767px) {
            font-size: 0;
            line-height: normal;
          }
        }
        &:hover {
          span {
            color: initial;
          }
        }
      }
      + li {
        margin-left: 82px;
        @media (max-width: 991px) {
          margin-left: 30px;
        }
      }
    }
  }
}
