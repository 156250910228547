@import "../../../assets/styles/global.scss";

.m-webinars-calendar-sidebar-header {
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
  .m-webinars-calendar-sidebar-title-wrapper {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .m-webinars-calendar-sidebar-title-back-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -6px 8px 0px 0px;
      svg {
        width: 21px;
        height: 21px;
        fill: var(--primary-color);
      }
    }
    h2 {
      color: var(--primary-font-color);
      justify-content: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      font-size: 22px;
      margin: 0;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      padding-top: 0;
      line-height: 16px;
      padding-top: 4px;
    }
    a {
      margin-left: auto;
      font-size: 18px;
      line-height: 16px;
      color: var(--link-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      text-decoration: none;
      border-bottom: 2px solid var(--link-color) !important;
      cursor: pointer;
      padding-top: 3px;
      padding-left: 0;
      padding-right: 0;
      transition: all 0.3s;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .m-webinars-calendar-sidebar-item-wrapper {
    .m-webinars-calendar-sidebar-item-date {
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      margin: 0px 0px 1px;
      font-family: "TTCommons-Medium";
      line-height: 19px;
    }
    .m-webinars-calendar-sidebar-item-time {
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      margin: 0px 0px 1px;
      font-family: "TTCommons-Medium";
      line-height: 19px;
    }
    .m-webinars-calendar-sidebar-item-title {
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 19px;
      color: var(--primary-dark-color);
      margin: 11px 0px 0px;
    }
    .m-webinars-calendar-sidebar-item-time-detail-wrapper {
      display: flex;
      justify-content: space-between;
      .m-webinars-calendar-sidebar-item-details {
        color: var(--primary-color);
        font-size: 18px;
        font-family: "TTCommons-Regular";
        cursor: pointer;
        line-height: 19px;
      }
    }
  }
  .m-webinars-calendar-sidebar-view-all {
    display: flex;
    justify-content: center;
    a {
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
      text-decoration: none;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      font-family: "TTCommons-Medium";
    }
  }

  .m-webinars-calendar-sidebar-modal-date {
    margin-bottom: 0.2rem;
  }
  .m-webinars-calendar-sidebar-modal-time {
    margin-bottom: 0.2rem;
    p {
      color: $grey-text-color;
    }
  }
  .m-webinars-calendar-sidebar-modal-presenter {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2rem;
    svg {
      width: 15px;
      height: 15px;
      margin-right: 0.25rem;
      fill: $grey-text-color;
    }
    p {
      color: $grey-text-color;
    }
  }
  .m-webinars-calendar-sidebar-modal-description {
    margin-top: 1rem;
  }

  .m-webinars-calendar-sidebar-webinar-not-found {
    padding-top: 0.3rem;
    padding-bottom: 0.25rem;
    p {
      color: var(--primary-color);
      text-align: center;
      font-family: "TTCommons-Medium";
      font-size: 22px;
      line-height: 21px;
      font-weight: 500;
      letter-spacing: 0;
      word-break: break-word;
      margin: 0;
    }
  }

  .m-webinars-calendar-sidebar-title-wrapper-card {
    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      margin: 0px;
      @media (max-width: 992px) {
        margin-bottom: 12px;
      }
    }
  }
}
