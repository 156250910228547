@import "../../../assets/styles/global.scss";

.m-widget-community-tab {
  &__title {
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    letter-spacing: 0;
    font-size: 30px;
    text-align: center;
    @media (max-width: 930px) {
      font-size: 22px;
      line-height: 22px;
      align-items: center;
      margin: 0 0 16px 0;
    }
  }

  &__listing {
    position: relative;
    > div:first-child {
      top: 0;
    }
    &__loader {
      margin-inline: 25px !important;
      margin-top: 15px !important;
    }

    &__topic-cards {
      margin: 0px 0px 50px 0px;
      > div {
        margin: -5px -5px -5px -5px;
        width: auto;
        > div {
          padding: 5px;
          width: 33.33%;
          margin: 0;
          @media (max-width: 930px) {
            width: 50%;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }

    &__empty {
      text-align: center;
      width: 100%;
      h2 {
        font-size: 30px;
        font-weight: 500;
        font-family: "TTCommons-Medium";
        margin: 0 0 30px 0;
        color: var(--primary-font-color);
      }
      svg {
        max-width: 100%;
      }
    }
  }
}
