@import "../../../assets/styles/global.scss";

.m-service-card {
  width: 33.33%;
  height: 360px;
  position: relative;
  z-index: 1;
  margin-bottom: 28px;
  padding: 0 8px;
  @media (max-width: 1366px) {
    width: 33.33%;
  }
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  &:hover {
    .m-service-card__wrapper {
      opacity: 0.6;
    }
    .m-service-card__favorite,
    .m-service-card__learn-more {
      visibility: visible;
      opacity: 1;
    }
  }
  &__wrapper {
    transition: 0.5s ease;
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__header {
        width: 100%;
        min-height: 25px;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        &__label {
          height: 25px;
          max-width: 200px;
          display: flex;
          justify-content: flex-start;
          span {
            display: block;
            height: 25px;
            width: fit-content;
            max-width: 100%;
            color: #000c3d;
            background-color: #ffffff;
            border: 2px solid #f1f4f7;
            border-radius: 12.5px;
            padding: 5px 15px;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &__star {
          position: absolute;
          right: 0px;
          top: 2px;
        }
      }
      &__image {
        width: 100%;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        position: relative;
        img {
          display: block;
          object-fit: contain;
          width: 100%;
          max-height: 100%;
        }
      }
      &__title {
        margin-bottom: 10px;
        h4 {
          margin: 0;
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          max-height: 36px;
          overflow-y: hidden;
        }
      }
      &__description {
        height: fit-content;
        max-height: 118px;
        overflow: hidden;
        margin: 0;
        color: #607790;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        font-weight: 500;
        width: 100%;
        p {
          margin: 0;
        }
        h3 {
          font-weight: 500;
          margin: 0;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }
  &__favorite {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease;
    &--active {
      visibility: visible;
      opacity: 1;
      transition: 0.5s ease;
    }
  }
  &__learn-more {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease;
    @media (max-width: 1024px) {
      visibility: visible;
      opacity: 1;
    }
    span,
    a {
      position: absolute;
      bottom: 0px;
      display: block;
      cursor: pointer;
      width: 113px;
      height: 30px;
      background-color: var(--primary-color);
      color: white;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 17px;
      text-align: center;
      padding: 8px 8px 0px;
      border-radius: 100px;
      text-decoration: none;
    }
  }
  &__showActions {
    visibility: visible;
    opacity: 1;
  }
}
