@import "../../../assets/styles/global.scss";

.m-dashboard-saved-post-card {
  &__empty {
    display: flex;
    flex-direction: column;
    @media (max-width: 1367px) {
      margin-bottom: 16px;
    }
    > div {
      padding: 16px 20px 14px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      min-height: 52px;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    width: 100%;
    &__title {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 19px;
    }
    &__illustration {
      margin-top: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #607790;
      font-size: 15px;
      font-family: "TTCommons-Regular";

      &__right-arrow {
        margin: 0 10px;
        display: flex;
        svg {
          path {
            stroke: var(--primary-color);
          }
        }
      }
      &__menu-icon {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border: 0;
        border-radius: 45px;
        background-color: var(--gray-100);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
          fill: var(--primary-font-color);
        }
      }
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    @media (max-width: 1367px) {
      margin-bottom: 16px;
    }
    > div {
      padding: 10px 20px 9px;
      display: flex;
      align-items: center;
      border-radius: 100px;
      min-height: 52px;
      width: 100%;
      margin-bottom: 0;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
      }
    }
    &__title {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 15px;
      display: flex;
      align-items: center;
      img {
        margin: 0px 10px 0px 0px;
      }
      span {
        padding-top: 4px;
      }
    }

    &__link {
      margin-left: auto;
      a {
        text-decoration: none;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        display: flex;
      }
    }
  }
}
