@import "../../../assets/styles/global.scss";

.a-prospect-header-logos {
  display: flex;
  align-items: center;
  min-width: 192px;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    min-width: auto;
  }
  &__school-logos {
    max-width: 200px;
    position: relative;
    width: 100%;
    @media (max-width: 480px) {
      min-width: auto;
      width: auto;
    }
    &__container {
      display: flex;
      align-items: center;
      > a {
        @include flex-row;
        align-items: center;
        justify-content: center;
        svg,
        img {
          max-height: 36px;
          width: auto;
          height: auto;
          vertical-align: middle;
          max-width: 100%;
        }
      }
      &__dropdown {
        margin-left: 20px;
        img {
          cursor: pointer;
          width: 15px;
          margin-bottom: -4px;
          transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        &--active {
          img {
            transform: rotate(180deg);
            transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
        }
      }
    }
    &__list {
      border-radius: 10px;
      @include flex-column-center();
      display: none;
      list-style: none;
      position: absolute;
      width: 229px;
      box-shadow: var(--box-shadow);
      background-color: var(--gray-300);
      top: 65%;
      z-index: 10;
      padding: 16px 18px;
      margin-top: 32px;
      left: -10%;
      li {
        width: 100%;
        a {
          @include flex-row;
          align-items: center;
          padding: 0 16px;
          justify-content: center;
          img,
          svg {
            max-width: 100%;
            vertical-align: middle;
            max-height: 36px;
          }
        }
        + li {
          border-top: 2px solid #eaeef3;
          margin-top: 15px;
          padding-top: 12px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 14px solid var(--gray-300);
        top: -10px;
        left: 15%;
        // transform: translateX(-50%);
        z-index: 1;
      }

      &:after {
        content: "";
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 14px solid var(--gray-200);
        top: -10px;
        left: 15%;
        // transform: translateX(-50%);
      }

      &--show {
        display: block;
      }
    }
    + a {
      margin-left: 16px;
      padding-left: 16px;
      border-left: 2px solid var(--light-gray-color);
      @include flex-row;
      align-items: center;
      @media (max-width: 1280px) {
        margin-left: 12px;
        padding-left: 12px;
      }
      svg,
      img {
        max-height: 36px;
        min-width: 170px;
        width: auto;
        height: auto;
        vertical-align: middle;
      }
    }
  }
}
