@import "/src/assets/styles/global.scss";

.a-usd-textbox {
  display: flex;
  align-items: center;
  &__button {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    min-width: auto;
    box-shadow: none;
    flex: 0 0 25px;
    height: 25px;
    width: 25px;
    border-radius: 100px;
    background-color: var(--gray-100);
    transition: all 0.3s;
    opacity: 1;
    &:hover {
      background-color: #eaeef3;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
  &__input {
    width: 140px;
    text-align: center;
    margin: 0 15px;
  }
}
