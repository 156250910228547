@import "../../../assets/styles/global.scss";

.a-n-switch {
  display: flex;
  .a-n-switch-item {
    -webkit-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    cursor: pointer;
    @include TTCommonsMedium;
    border: 2px solid var(--light-gray-color);
    border-right: 1px solid var(--light-gray-color);
    border-left: 1px solid var(--light-gray-color);
    font-size: 22px;
    line-height: 21px;
    height: 40px;
    min-width: 147px;
    text-align: center;
    padding: 10px 16px 9px;
    color: var(--primary-font-color);
    @media (max-width: 767px) {
      font-size: 18px;
      height: 34px;
      min-width: 104px;
      padding: 8px 8px;
    }
    @media (max-width: 425px) {
      font-size: 16px;
      height: 34px;
      min-width: 70px;
      padding: 6px 6px;
    }
    &:first-child {
      border-radius: 100px 0 0 100px;
      border-left: 2px solid var(--light-gray-color);
      &.a-n-switch-selected {
        border-right: 2px solid transparent !important;
      }
    }
    &:last-child {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      border-right: 2px solid var(--light-gray-color);
      &.a-n-switch-selected {
        border-left: 2px solid transparent !important;
      }
    }
    &--active {
      background-color: var(--primary-font-color);
      color: var(--white-color) !important;
      border: 2px solid var(--primary-font-color) !important;
    }
  }
}

.a-switch {
  display: flex;
  gap: 10px;
  @media (max-width: 767px) {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .a-switch-item {
    border-radius: 40px;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    width: 148px;
    height: 50px;
    padding: 18px 10px 15px;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    color: var(--primary-dark-color);
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
    }
  }

  .a-switch-selected {
    background-color: var(--primary-color);
    color: var(--white-color) !important;
  }
}
