@import "../../../assets/styles/global.scss";

.m-resource-card {
  position: relative;
  > div > div > div > div {
    padding: 20px;
    width: 100%;
    padding-bottom: 20px !important;
  }
  > div > div > div {
    &:hover {
      box-shadow: 0 1px 14px 3px var(--light-gray-color);
    }
  }
  &:hover {
    .m-resource-card__star {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
  &__wrapper {
    cursor: pointer;
    > div {
      padding: 16px 16px 24px 16px;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color);
      }
    }
    &__content {
      position: relative;
      height: 315px;
      &__image {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 145px;
        margin-bottom: 10px;
        background: #ebf1f7;
        border-radius: 10px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &__secondary {
          position: absolute;
          width: 215px;
          height: 125px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            opacity: 0.7;
            width: 72px;
            height: 72px;
          }
        }
      }
      &__title {
        margin-bottom: 8px;
        span {
          display: block;
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          max-height: 57px;
          overflow: hidden;
        }
      }
      &__description {
        min-height: 52px;
        margin-bottom: 8px;
        span {
          display: block;
          color: var(--secondary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          max-height: 52px;
          overflow: hidden;
        }
      }
      &__school {
        position: absolute;
        left: 0;
        bottom: 16px;
        height: 20px;
        &__super-admin {
          display: flex;
          align-items: center;
          height: 20px;
          width: fit-content;
          span {
            height: 20px;
            color: var(--primary-font-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 24px;
          }
          img {
            height: 20px;
            margin-right: 6px;
          }
        }
        &__school-admin {
          width: fit-content;
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 23px;
          text-align: center;
          padding-inline: 10px;
          height: 20px;
          border-radius: 12.5px;
          background-color: #0030b5;
        }
      }
      &__footer {
        position: absolute;
        bottom: -8px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        &__date {
          span {
            display: block;
            height: 14px;
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
        &__type {
          background-color: var(--gray-100);
          color: var(--primary-font-color);
          min-width: 88px;
          height: 20px;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          border-radius: 10px;
          padding: 4px 10px 4px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }
      }
    }
  }
  &__star {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 30px;
    right: 38px;
    &--active {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
  &__label {
    position: absolute;
    top: 31px;
    left: 30px;
    height: 20px;
    background: #ffffff;
    box-shadow: var(--box-shadow);
    padding: 3px 10px 2px 10px;
    border-radius: 10px;
    span {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      display: block;
      max-width: 135px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__showActions {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
