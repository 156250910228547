@import "../../../assets/styles/global.scss";

.m-student-service-categories {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
  @media (max-width: 992px) {
    margin-top: 0;
    border-radius: 10px !important;
    overflow: hidden;
    max-height: 280px;
    overflow-y: auto;
  }
  &__wrapper {
    width: 100%;
    &__item {
      cursor: pointer;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      border-radius: 25px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      padding: 10px 15px;
      text-decoration: none;
      transition: all 0.3s;
      @media (max-width: 992px) {
        margin: 0;
        border-radius: 0;
        box-shadow: none !important;
        height: auto;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--light-gray-color);
        @media (max-width: 992px) {
          box-shadow: none !important;
        }
      }
      &:focus {
        box-shadow: 0 1px 14px 3px var(--light-gray-color);
        @media (max-width: 992px) {
          box-shadow: none !important;
        }
      }
      + a {
        @media (max-width: 992px) {
          border-top: 1px solid #d1dde5;
        }
      }
      &__image-wrapper {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        background-color: #f1f4f7;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      span {
        height: 16px;
        width: 80%;
        color: #000c3d;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__active {
        background-color: var(--primary-color);
        span {
          color: #ffffff;
        }
      }
    }
  }

  @media (min-width: 320px) {
    justify-content: center;
    > div {
      width: 90%;
    }
  }
  @media (min-width: 375px) {
    justify-content: center;
    > div {
      width: 85%;
    }
  }
  @media (min-width: 375px) {
    justify-content: center;
    > div {
      width: 100%;
    }
  }
  @media (min-width: 500px) {
    justify-content: center;
    > div {
      width: 100%;
    }
  }
  @media (min-width: 600px) {
    justify-content: center;
    > div {
      width: 100%;
    }
  }
  @media (min-width: 1366px) {
    justify-content: flex-end;
    > div {
      width: 100%;
    }
  }
}
