@import "/src/assets/styles/global.scss";

.a-phone-number-select {
  .react-tel-input {
    border: 0px solid #d1dde5 !important;
    border-radius: 45px !important;
    background-color: #ffffff !important;
    outline: none !important;

    .phone-number-select-input {
      box-sizing: border-box !important;
      width: 100% !important;
      height: 50px !important;
      border: 2px solid #d1dde5 !important;
      border-radius: 45px !important;
      background-color: #ffffff !important;
      outline: none !important;
      font-family: "TTCommons-Regular";
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 19px;
      text-align: left;
      padding-left: 54px;
      padding-top: 4px;
    }

    .flag-dropdown {
      border: none !important;
      border-radius: 0px !important;
      background-color: transparent !important;
      outline: none !important;
      input.search-box {
        width: 95%;
        margin: 0 !important;
        border: 2px solid #d1dde5 !important;
        border-radius: 45px !important;
        background-color: #ffffff !important;
        text-align: left;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px !important;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px !important;
        padding: 12px 22px 12px 22px !important;
        color: #000c3d;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--secondary-font-color);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--secondary-font-color);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--secondary-font-color);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--secondary-font-color);
        }
      }
      .country-list {
        width: 325px;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        .country {
          display: flex;
          padding: 10px 20px 10px;
          color: #000c3d;
          font-family: "TTCommons-Medium";
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          height: 40px;
          &:hover {
            background-color: #f1f4f7;
          }
        }
        .no-entries-message {
          padding: 15px !important;
          span {
            color: var(--primary-font-color);
            font-family: "TTCommons-Medium";
            font-size: 18px !important;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px !important;
          }
        }
      }
    }
    .selected-flag {
      background-color: transparent !important;
      padding: 0 0 0 20px !important;

      .flag {
        position: absolute !important;
        top: 50% !important;
      }
    }
  }
}
