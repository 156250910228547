@import "../../../assets/styles/global.scss";

.m-discover-network-slide {
  text-align: center;
  margin-bottom: 0;
  &__title {
    @media (max-width: 992px) {
      max-width: 300px;
      margin: 0 auto;
    }
    h3 {
      text-align: center;
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 25px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
      word-wrap: break-word;
    }
  }

  &__images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
    @media (max-width: 992px) {
      max-width: 300px;
      margin: 15px auto 22px;
    }
    img {
      width: 50px;
      height: 50px;
      margin: 0 4px;
      min-width: 50px;
    }
  }
  &__button {
    @media (max-width: 992px) {
      max-width: 300px;
      margin: 0 auto;
    }
    button {
      width: 100%;
      margin-right: 0;
      @media (max-width: 767px) {
        height: 50px;
      }
    }
  }
}
