@import "../../../assets/styles/global.scss";

.a-login-social-icon {
  a {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    overflow: hidden;
  }
}
