@import "/src/assets/styles/global.scss";

.m-calculator-summary {
  height: fit-content;
  position: sticky;
  top: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
  @media (max-width: 1200px) {
    margin-top: 0px;
  }

  &__title {
    color: var(--primary-font-color);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    text-align: center;

    &--second {
      margin-top: 20px;
    }
  }

  &__value {
    color: var(--primary-font-color);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    font-family: "TTCommons-DemiBold";
    text-align: center;
    margin-top: 6px;
  }
}
