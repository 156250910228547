@import "../../../assets/styles/global.scss";

.a-button {
  display: inline-flex;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include defaultBrandButton;
  &:hover {
    opacity: 0.9;
    transition: all 0.3s;
  }
  // Shadow styling on button clicks
  // &:focus {
  //   box-shadow: 0 0 8px 1px var(--primary-color) !important;
  // }

  &__loader {
    border: 3px solid #f2f4f7;
    border-radius: 100px;
    border-top: 3px solid var(--primary-font-color);
    width: 18px;
    height: 18px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: -2px;
    margin-left: 12px;
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    &--with-spacing {
      margin-left: 10px;
    }
  }
}

.a-button--chat-send {
  border-radius: 20px;
  width: 80px;
  height: 30px;
  border: none;
  background-color: var(--primary-color);
  color: $color-white;
  font-weight: 500;
  cursor: pointer;
  @include TTCommonsRegular;
  align-items: center;
}
.a-button--chat-send--disabled {
  border-radius: 20px;
  width: 80px;
  height: 30px;
  border: none;
  // background-color: $color-orange;
  color: $color-white;
  font-weight: 500;
  cursor: not-allowed;
  @include TTCommonsRegular;
  align-items: center;
  background-color: $color-grey-4;
}
.a-button--primary-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.a-button--webinar {
  height: 30px;
  font-size: 18px;
  padding-inline: 1rem;
  @media (max-width: 1367px) {
    height: 25px;
    font-size: 16px;
  }
}
.a-button--notifications-manage {
  height: 50px;
  max-width: 210px;
  font-size: 16px;
}
.a-button--notifications-clear {
  background: #fff;
  box-shadow: var(--box-shadow);
  color: var(--primary-font-color);
  font-size: 18px;
  font-weight: 500;
  font-family: "TTCommons-Medium";
  width: auto;
  max-width: 100%;
  padding: 18px 20px 12px;
  transition: all 0.3s;
  min-width: 140px;
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color);
  }
}
.a-button--resource {
  height: 30px;
  width: 100px;
  font-size: 16px;
  padding-inline: 1rem;
}
.a-button--student-services {
  height: 30px;
  font-size: 16px;
  width: 113px;
}
.a-button--webinar-search-filter {
  height: 18px;
  width: 25%;
  font-size: 15px;
  background: none !important;
  color: var(--primary-color);
  &:focus {
    box-shadow: none !important;
  }
}
.a-button--checklist-add {
  background: #fff !important;
  box-shadow: var(--box-shadow) !important;
  text-decoration: none;
  color: var(--primary-font-color);
  width: 100%;
  padding: 18px 20px 12px;
  height: auto;
  min-height: 50px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Regular";
  border-radius: 100px;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
  text-transform: initial;
  margin-right: 4px;
  + button {
    margin-left: 4px;
  }
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
  }
  img {
    transform: rotate(180deg);
    vertical-align: middle;
    margin: -2px 8px 0 0;
    width: 14px;
  }
}

.a-button--checklist-save {
  height: 35px;
  background: var(--primary-color);
  padding-top: 4px;
  min-width: 114px;
  width: 94px;
  font-size: 18px;
  opacity: 1 !important;
  visibility: visible !important;
  @media (max-width: 767px) {
    min-width: 100px;
  }
}

.a-button--webinar-detail-modal {
  height: 30px;
  width: 175px;
  font-size: 16px;
}

.a-button---invite {
  @include TTCommonsMedium;
  @include flex-column-center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  border-radius: 100px;
  padding: 6px 16px 0;
  min-height: 60px;
  min-width: 101px;
  width: 100%;
  max-width: 352px;
  cursor: pointer;
  @media (max-width: 1367px) {
    height: 50px;
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    min-height: 44px;
    padding: 2px 16px 0;
    max-width: 280px;
  }
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
}
.a-button--skip {
  background-color: transparent;
  border: 0;
  color: var(--secondary-font-color);
  min-height: auto !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0 !important;
  font-size: 18px !important;
  letter-spacing: 0 !important;
  min-width: auto !important;
  height: auto !important;
  color: var(--secondary-font-color);
  font-family: "TTCommons-Medium";
  line-height: 16px !important;
  border-bottom: 2px solid var(--secondary-font-color);
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.a-button--discover-network-button {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  height: 30px;
  width: 80px;
  color: var(--primary-color);
  @include TTCommonsMedium;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}
.a-button--discover-network-to-messages {
  width: 210px;
  height: 60px;
  @media (max-width: 1367px) {
    height: 50px;
    font-size: 18px;
  }
}
.a-button--discover-network-clear-filters {
  background-color: $color-grey-4;
  width: 220px;
  @include xs() {
    max-width: 90%;
  }
}
.a-button--discover-network-card-to-messages {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);

  @include discoverNetworkCardButton;
  width: auto !important;
  font-size: 15px;
  line-height: 17px;
}

.a-button--notifications-drawer-header {
  height: 30px;
  width: 100px;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 45px;
}

.a-button--notifications-drawer-view {
  height: 30px;
  width: 80px;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 45px;
}

.a-button--secondary {
  background-color: $color-grey-10;
  color: $color-white;
}

.a-button--topics-participant-list-view {
  height: 30px;
  width: 165px;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  background: $color-dark-gray;
  color: #ffffff;
  border-radius: 45px;
  padding-top: 3px;
}

.a-button--profile-header-primary {
  height: 50px;
  width: 175px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 45px;
  margin-right: 25px;
  @media (max-width: 767px) {
    margin-right: 10px;
  }
}

.a-button--profile-header-secondary {
  height: 50px;
  width: 130px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  background: #8094ab;
  color: #ffffff;
  border-radius: 45px;
  @media (max-width: 1367px) {
    height: 50px;
    font-size: 18px;
  }
}

.a-button--profile-img-upload {
  height: 37px;
  width: 120px;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 45px;
  padding-top: 3px;
}
.a-button--topics-unfollow {
  max-width: 320px;
  letter-spacing: 0;
  text-align: center;
  font-family: "TTCommons-Medium";
  margin: 0;
  height: 50px;
  margin-bottom: 4px;
}

.a-button--profile-submit {
  height: 50px;
  width: 191px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 45px;
}

.a-button--notifications-action-small {
  height: 30px;
  padding: 5px 16px 0px;
  font-size: 18px;
  min-width: 80px;
}

.a-button--notifications-action {
  height: 30px;
  padding: 5px 12px 0px;
  font-size: 18px;
}

.a-button--notifications-delete {
  display: flex;
  align-items: center;
  width: 35px;
  height: 35px;
  min-width: 35px;
  background: var(--gray-100) !important;
  padding: 0px !important;
  margin-left: 12px;
  &:hover {
    background: var(--gray-200) !important;
  }
}

.a-button--widget-sign-up-bar {
  border-radius: 45px;
  background-color: $color-white;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 12px 16px 8px;
  max-width: 188px;
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "TTCommons-Medium";
}

.a-button--widget-card-button {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
  @include discoverNetworkCardButton;
  width: auto !important;
  width: 100% !important;
  height: 40px;
  max-width: 189px;
  font-family: "TTCommons-Medium";
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.a-button--widget-chatlist-card-button {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
  @include discoverNetworkCardButton;
  width: auto !important;
  min-width: 150px;
  height: 40px;
  font-family: "TTCommons-Medium";
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.a-button--widget-sign-up-overlay {
  width: 100%;
  max-width: 188px;
  height: 47px;
  border-radius: 45px;
  color: $color-white;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  font-family: "TTCommons-Medium";
  cursor: pointer;
  align-items: center;
}

.a-button--user-profile-request-meeting {
  width: 100%;
  padding: 5px 24px 0px;
  font-size: 18px;
  margin-top: 12px;
}

.a-button--webinar-tab {
  height: 50px;
  width: 148px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  background: #ffffff;
  color: var(--primary-font-color);
  border-radius: 40px;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
  }
}

.a-button--webinar-tab--active {
  height: 50px;
  width: 148px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 40px;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
  }
}

.a-button--webinar-list-upcoming-past {
  height: 50px;
  width: 160px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  background: var(--primary-color);
  color: #ffffff;
  border-radius: 40px;
}

.a-button--webinar-list-registered {
  height: 50px;
  width: 160px;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  background: var(--secondary-font-color);
  color: #ffffff;
  border-radius: 40px;
}

.a-button--slider-action {
  width: auto !important;
  margin: 0px auto 0;
  padding: 8px 22px 4px;
  height: auto;
  min-height: 39px;
  font-family: "TTCommons-Medium";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  background: #00043c;
  .MuiButton-label {
    .content {
      min-width: auto;
    }
  }
}

.a-button--white {
  background: #fff !important;
  box-shadow: var(--box-shadow) !important;
  text-decoration: none;
  color: var(--primary-font-color);
  width: 100%;
  padding: 12px 15px 9px;
  height: auto;
  min-height: 40px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: "TTCommons-Medium";
  border-radius: 100px;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
  text-transform: initial;
  margin-right: 10px;
  + button {
    margin-right: 0;
  }
}

.a-button-community--discover {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
  width: 100%;
  padding: 0 20px !important;
  height: auto;
  min-height: 50px;
  border-radius: 25px;
  transition: all 0.3s;
  &:hover {
    box-shadow: var(--box-shadow);
  }
  span {
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: initial !important;
    margin-top: 3px;
  }
}

.a-button-community--saved {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 21px;
  width: 100%;
  padding: 0 20px !important;
  height: auto;
  min-height: 50px;
  border-radius: 25px;
  transition: all 0.3s;
  &:hover {
    box-shadow: var(--box-shadow);
  }
  span {
    color: var(--field-label-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: initial !important;
    margin-top: 3px;
  }
  img {
    width: 16px;
    height: 15px;
    margin: 0 5px 0 0;
  }
}

// Common button disable style
button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
