/**
  
  Author: Muhammad Atif
  Date added: 12th August,2022
  Email: atif@interstride.com

**/

@mixin mx($left: inherit, $right: inherit) {
  margin-left: $left;
  margin-right: $right;
}

/**
    top: margin top
    bottom: margin bottom
**/
@mixin my($top: inherit, $bottom: inherit) {
  margin-top: $top;
  margin-bottom: $bottom;
}
@mixin px($left: inherit, $right: inherit) {
  padding-left: $left;
  padding-right: $right;
}
@mixin py($top: inherit, $bottom: inherit) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin text-color($color: #fff) {
  color: $color;
}
@mixin background-color($color: #fff) {
  background-color: $color;
}

@mixin border($width: 0px, $color: transparent) {
  border: $width solid $color;
}

@mixin radius($radius: 0px) {
  border-radius: $radius;
}
@mixin wh($width: inherit, $height: inherit) {
  width: $width;
  height: $height;
}
@mixin center() {
  text-align: center;
}
@mixin right() {
  text-align: right;
}
@mixin left() {
  text-align: left;
}
@mixin justify() {
  text-align: justify;
}
@mixin card-shadow() {
  // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  //   0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
}

@mixin pointer {
  cursor: pointer;
}

@mixin backgroundImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  overflow: hidden;
}
