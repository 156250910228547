@import "../../../assets/styles/global.scss";

.a-sub-menu-item {
  display: block;
  cursor: pointer;

  &__link {
    display: flex;
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: none;
    padding: 10px 24px 10px 30px;

    &__notification_count {
      margin-left: auto;
      height: 21px;
      width: 21px;
      display: flex;
      min-width: 21px;
      justify-content: center;
      align-items: center;
      background: var(--primary-color);
      color: #fff;
      border-radius: 100px;
      padding-top: 4px;
      font-size: 15px;
      margin-top: -3px;
    }
    @media (max-width: 1280px) {
      padding: 8px 24px 8px 24px;
      left: 16px;
      font-size: 16px;
      line-height: 15px;
    }
    @media (max-width: 767px) {
      padding: 8px 16px 8px 16px;
    }
    &:hover {
      color: var(--primary-color);
    }
  }

  &--active {
    > a {
      span:first-of-type {
        border-bottom: 2px solid var(--primary-font-color);
      }
    }
    &::before,
    &::after {
      display: block !important;
    }
  }
}
