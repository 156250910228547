@import "../../../assets/styles/global.scss";

.m-my-topic-wrapper {
  margin-top: 0;
  width: 100%;
  @media (max-width: 1367px) {
    margin-top: 0px;
  }
  > div {
    > div:nth-child(1) {
      @media (max-width: 1367px) {
        max-width: 35%;
        flex-basis: 35%;
      }
      @media (max-width: 992px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      .collapsible-section {
        @media (max-width: 1367px) {
          margin-top: 40px;
        }
        @media (max-width: 992px) {
          margin-top: 0px;
        }
      }
    }
    > div:nth-child(2) {
      @media (max-width: 1367px) {
        max-width: 65%;
        flex-basis: 65%;
      }
      @media (max-width: 992px) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
    > div:nth-child(3) {
      @media (max-width: 1367px) {
        max-width: 65%;
        flex-basis: 65%;
        margin-left: auto;
      }
      @media (max-width: 992px) {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
  &__empty {
    text-align: center;
    width: 100%;
    h2 {
      font-size: 22px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      margin: 20px 0 0 0;
      color: var(--primary-font-color);
    }
    svg {
      max-width: 400px;
      width: 100%;
    }
  }

  .a-categories-flush {
    margin-top: 50px;
  }
  .m-my-topic-middle-wrapper {
    .m-my-topic-middle-heading {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 10px;
      position: relative;
      h2 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0;
        padding-top: 4px;
      }
      .m-my-topic-middle-heading-menu-button-wrapper {
        display: flex;
        align-items: center;
        box-shadow: none;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        background-color: var(--gray-100);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
          background-color: #eaeef3;
        }
      }
      .m-my-topic-middle-heading-menu-button-wrapper-disabled {
        svg {
          fill: $gray-outline-color !important;
          cursor: default !important;
          pointer-events: none;
        }
      }
      .m-my-topic-middle-heading-menu-wrapper {
        animation: fadeIn 0.4s;
        z-index: 999;
        position: absolute;
        right: 0;
        min-width: 250px;
        padding: 0 20px;
        text-align: center;
        border-radius: 10px 1px 10px 10px;
        background-color: #ffffff;
        box-shadow: var(--box-shadow);
        top: 50px;
        color: var(--primary-font-color);
        @media (max-width: 768px) {
          width: 200px;
        }
        h5 {
          cursor: pointer;
          color: #000c3d;
          @include TTCommonsMedium;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          padding: 22px 0 18px;
          margin: 0;
          @media (max-width: 767px) {
            padding: 18px 0 14px;
          }
          span {
            border-bottom: 2px solid transparent;
          }
          &:hover {
            span {
              border-color: #000c3d;
            }
          }
          + h5 {
            border-top: 2px solid #eaeef3;
          }
        }
      }
    }
  }
  .m-my-topic-right-placeholder-text {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    text-align: center;
    margin: 0px;
    font-family: "TTCommons-Medium";
  }

  .m-my-topic-unfollow-modal-title {
    color: var(--primary-font-color);
    font-size: 22px;
    font-family: "TTCommons-Medium";
    font-weight: 500;
    line-height: 24px;
    max-width: 354px;
    margin: 28px auto 25px;
    @media (max-width: 767px) {
      max-width: 270px;
    }
  }
  .m-my-topic-unfollow-modal-description {
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
  .m-my-topic-unfollow-modal-cancel {
    color: var(--secondary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 16px;
    border-bottom: 2px solid var(--secondary-font-color);
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  .m-my-topic-report-modal {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    div > div > div {
      padding: 0 !important;
    }
    button {
      margin-top: 10px;
    }
    &__cancel {
      margin-top: 25px;
      span {
        color: var(--secondary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 16px;
        border-bottom: 2px solid var(--secondary-font-color);
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    textarea {
      text-align: center;
    }
  }
  .m-my-topic-report-confirmation-modal {
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
    button {
      margin-top: 30px;
      width: 125px;
      height: 30px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
}
