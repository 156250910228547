@import "/src/assets/styles/global.scss";

.m-chat-list-view {
  &__users {
    @include my($top: 20px);
    &_no_network {
      margin-top: 70px;
      margin-bottom: 50px;
      h3 {
        margin: 0 0 20px 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        line-height: 31px;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 24px !important;
          line-height: 24px !important;
        }
      }
      h3:nth-child(2) {
        margin-bottom: 50px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        @media (min-width: 426px) {
          width: 80%;
        }
        @media (min-width: 560px) {
          width: 60%;
        }
        @media (min-width: 768px) {
          width: 400px;
        }
      }
      &_primary-text {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 30px;
        font-weight: 500;
        line-height: 31px;
        margin-bottom: 10px;
      }
      &_secondary-text {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 20px;
        font-weight: 500;
        line-height: 21px;
        margin: 0;
      }
    }
    &__cards {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 48px;
      justify-content: center;
      margin: 0 -8px;
      > div {
        width: 25%;
        padding: 0 8px 16px;
        @media (max-width: 1367px) {
          width: 33.33%;
        }
        @media (max-width: 992px) {
          width: 50%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        > div {
          width: 100%;
        }
      }
      &--widget {
        > div {
          width: 33.33%;
          padding: 0 8px 16px;
          @media (max-width: 992px) {
            width: 50%;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
          > div {
            width: 100%;
          }
        }
      }
    }
  }
}
