@import "../../../assets/styles/global.scss";

.m-checklist-categories {
  @media (max-width: 992px) {
    max-height: 253px;
    overflow-y: auto;
  }
  &__container {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &__item {
      list-style: none;
      a {
        padding: 16px 20px 14px;
        text-decoration: none;
        background: #fff;
        display: flex;
        align-items: center;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        box-shadow: var(--box-shadow);
        border-radius: 100px;
        min-height: 50px;
        transition: all 0.3s;
        @media (max-width: 992px) {
          border-radius: 0;
          padding: 12px 16px 10px;
          min-height: auto;
        }
        @media (max-width: 991px) {
          box-shadow: none;
        }
        span {
          padding-top: 3px;
        }
        &:focus {
          background-color: var(--primary-color);
          color: #fff;
        }
        &:hover {
          box-shadow: 0 1px 14px 3px var(--light-gray-color);
        }
      }
      &--active {
        a {
          background-color: var(--primary-color);
          color: #fff;
        }
      }
      + li {
        margin-top: 10px;
        @media (max-width: 991px) {
          border-top: 1px solid var(--light-gray-color);
          margin-top: 0px;
        }
      }
    }
  }
}
