@import "../../../assets/styles/global.scss";

.m-reset-password-container {
  height: 100%;
  min-height: 100vh;
  padding: 48px 16px 114px 16px;
  position: relative;
  @media (max-width: 767px) {
    padding: 48px 16px 48px 16px;
  }
  &__back-btn {
    position: absolute;
    left: 18px;
    top: 72px;
    a {
      @include TTCommonsMedium;
      color: var(--primary-font-color);
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-decoration: none;
      display: flex;
      align-items: center;
      svg {
        width: 22px;
        margin-top: -6px;
        margin-right: 10px;
      }
    }
  }
  &__content {
    max-width: 325px;
    margin: 0 auto;
    text-align: center;
    &__unlogo {
      img {
        max-width: 234px;
        max-height: 70px;
        vertical-align: middle;
      }
    }
    h2 {
      color: var(--primary-font-color);
      @include TTCommonsMedium;
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center;
      margin: 60px -30px 16px;
      width: 385px;
    }
    p {
      margin: 0;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
    }
    h5 {
      color: var(--primary-font-color);
      @include TTCommonsMedium;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin: 30px 0;
      @media (max-width: 767px) {
        margin: 24px 0;
      }
      a {
        color: var(--link-color);
        margin-left: 4px;
        border-bottom: 1px solid var(--link-color);
        text-decoration: none;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include flex-row;
      align-items: flex-start;
      justify-content: center;
      li {
        margin: 0 19px;
        div {
          @include flex-column;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          color: var(--primary-font-color);
          a {
            width: 65px;
            height: 65px;
            min-width: 65px;
            margin: 0 auto 6px;
          }
        }
      }
    }
    &__formfield {
      margin-top: 30px;
      @media (max-width: 767px) {
        margin-top: 24px;
      }
      label {
        padding-left: 0;
      }
      input {
        text-align: center;
      }
      > div {
        + div {
          margin-top: 16px;
        }
      }

      button {
        margin: 50px 0 30px;
        @media (max-width: 767px) {
          margin: 0px 0 24px;
        }
      }
      a {
        color: var(--secondary-font-color);
        @include TTCommonsRegular;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        text-decoration: none;
        border-bottom: 1px solid var(--secondary-font-color);
      }
    }
  }

  h6 {
    color: var(--secondary-font-color);
    font-family: "TT Commons";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    @include TTCommonsRegular;
    font-weight: 400;
    max-width: 500px;
    margin: 20px auto 0;
    a {
      color: var(--primary-color);
      text-decoration: none;
      border-bottom: 1px solid var(--primary-color);
    }
    @media (max-width: 767px) {
      margin-top: 80px;
    }
  }
}
