@import "/src/assets/styles/global.scss";

.a-empty-resources {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  img {
    width: 256px;
  }
  &__heading {
    margin: 19px 0 9px 0;
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }
}
