@import "../../../assets/styles/global.scss";

.a-card {
  background-color: $color-white;
  @include radius(10px);
  @include py(20px, 20px);
  @include px(20px, 20px);
  @include card-shadow;
  margin-bottom: 1rem;
  transition: all 0.3s;
  @media (max-width: 1367px) {
    padding: 20px;
  }
  @media (max-width: 992px) {
    padding: 16px;
    margin-bottom: 12px;
  }

  &--notification {
    padding: 15px !important;
    margin-bottom: 10px;
  }
}

.a-card--full-width {
  max-width: 100%;
  width: 100%;
}

.a-card--resources {
  padding: 0 !important;
  overflow: hidden;
  min-height: 6rem;
  max-height: 6rem;
}

.a-card--notifications-drawer {
  overflow: hidden;
  padding: 1.5rem 2rem;
}

.a-card--create-topic-post {
  padding: 20px;
  @media (max-width: 992px) {
    padding: 12px;
  }
}

.a-card--listing-effect {
  @include py(10px, 0px);
}

.a-card--resource-listing-effect {
  padding: 0px;
  margin-bottom: 20px;
  height: 96px;
}

.a-card--student-service {
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4) !important;
  height: 370px;
  padding: 16px 16px 26px 16px;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color) !important;
  }
}
