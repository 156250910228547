@import "../../../assets/styles/global.scss";

.m-footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(128, 148, 171, 0.4);
  display: flex;
  align-items: flex-end;
  &__content {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    background: #fff;
    padding: 50px 40px 30px;
    max-width: 100% !important;
    flex-basis: 100% !important;
    @media (max-width: 992px) {
      padding: 16px 16px 16px;
    }
    &__main {
      display: flex;
      flex-direction: row;
      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
      &__logo {
        @media (max-width: 992px) {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        img {
          width: 200px;
          height: auto;
          @media (max-width: 992px) {
            width: 120px;
            height: auto;
          }
        }

        &__text {
          margin-left: 52px;
          width: 150px;
          font-size: 22px;
          font-family: "TTCommons-Medium";
          color: rgb(0, 4, 133);
          @media (max-width: 992px) {
            width: 100%;
            text-align: center;
            margin: 8px 0 24px 0;
            font-size: 18px;
          }
        }
      }

      &__links {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        @media (max-width: 767px) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        &__menu {
          display: flex;
          flex-direction: column;
          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            @media (max-width: 767px) {
              width: 33.33%;
              margin-bottom: 15px;
            }
          }
          &:nth-child(5) {
            @media (max-width: 767px) {
              width: 66%;
            }
          }
          span {
            color: #000c3d;
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            margin-bottom: 10px;
            @media (max-width: 767px) {
              font-size: 15px;
              margin-bottom: 4px;
            }
          }

          a {
            color: #000c3d;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: left;
            text-decoration: none;
            margin: 0 0 15px 0;
            @media (max-width: 767px) {
              margin: 4px 0px;
              font-size: 15px;
              line-height: 12px;
            }
          }
        }
      }
    }

    &__close {
      display: flex;
      margin-left: auto;
      width: 25px;
      height: 25px;
      align-items: center;
      justify-content: center;
      @media (max-width: 992px) {
        margin-bottom: -30px;
        position: relative;
      }
      svg {
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    &__copyright {
      color: $grey-text-color;
      text-align: left;
      @media (max-width: 992px) {
        text-align: center;
      }
    }
  }
}
