.m-filters-container {
  &__heading {
    margin: 30px 0 20px 0;
    font-family: "TTCommons-Regular";
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    &--widget {
      flex-direction: column;
      margin-bottom: 20px;
    }
    &__left {
      &__filters {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
        }
        &__title {
          font-family: "TTCommons-Regular";
          font-weight: 500;
          font-size: 18px;
          line-height: 19px;
          color: var(--secondary-font-color);
        }
        > div {
          width: 180px;
        }
        &__clear {
          font-family: "TTCommons-Regular";
          font-weight: 500;
          font-size: 18px;
          line-height: 16px;
          color: var(--secondary-font-color);
          cursor: pointer;
          border-bottom: 1px solid var(--secondary-font-color);
        }
      }
    }
    &__right {
      display: flex;
      gap: 10px;
      &__button {
        button {
          min-width: 128px;
          padding: 0 0 0 0 !important;
          background-color: #ffffff !important;
          box-shadow: var(--box-shadow);
        }
        &__body {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-inline: 15px;
          span {
            font-family: "TTCommons-Regular";
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            color: var(--primary-font-color);
            display: inline-block;
            margin: 5px 0 0 8px;
          }
          &--active {
            span {
              color: #ffffff;
            }
          }
        }
        &--active {
          button {
            background-color: var(--primary-color) !important;
          }
        }
      }
    }
  }
}
