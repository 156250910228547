@import "/src/assets/styles/global.scss";
.a-chat-preview-container {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: #fff;
  display: flex;
  align-content: flex-start;
  @include left;
  padding: 20px 16px;
  align-items: center;
  cursor: pointer;
  margin: 0 0 10px;
  transition: all 0.3s;
  min-height: 100px;
  @media (max-width: 1366px) {
    padding: 16px !important;
  }
  @media (max-width: 992px) {
    min-height: auto;
  }
  &:hover {
    box-shadow: 0 1px 14px 3px var(--light-gray-color);
  }
  &:last-child {
    border: none;
  }
  &__profile {
    width: 48px;
    height: 48px;
    div {
      > div {
        width: 48px !important;
        height: 48px !important;
        border-radius: 100px;
        overflow: hidden;
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover;
          object-position: center;
          border-radius: 0 !important;
        }
      }
    }
  }

  &__details {
    margin-left: 10px;
    margin-top: 0;
    flex-grow: 1;
    max-width: calc(100% - 58px);
    .title {
      color: var(--primary-font-color);
      margin-top: 0;
      margin-bottom: 0;
      @include TTCommonsMedium;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      display: flex;
      justify-content: space-between;
      strong {
        @include TTCommonsMedium;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      span {
        font-size: 15px;
        color: var(--secondary-font-color);
        @include TTCommonsRegular;
        font-weight: normal;
        white-space: nowrap;
        margin: 0 4px;
      }
      &__count {
        background-color: var(--main-color);
        color: #fff !important;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-weight: 400 !important;
        transition: ease-in-out;
        margin-left: 4px !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        min-width: 25px !important;
        padding-top: 5px;
        text-align: center;
        margin-top: -5px !important;
      }
    }
    &__tags {
      &__meeting {
        height: 20px;
        width: 60px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        border-radius: 10.5px;
        background: #e5eaf7;
      }
    }
    .description {
      display: flex;
      align-items: center;
      margin: 4px 0 0 0;
      &__text {
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
        font-size: 15px;
        font-weight: 200;
        // Text truncate
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 12px;
        padding-top: 4px;
      }
    }
  }
}

.a-chat-preview-container--active {
  background-color: var(--primary-color);
  .a-chat-preview-container__details {
    .title {
      color: $color-white;
    }
    .description {
      &__text {
        color: $color-white;
      }
    }
    .title {
      span {
        color: $color-white;
      }
    }
    .description {
      &__count {
        background-color: $color-white;
        color: var(--primary-color);
      }
    }
  }
  .a-chat-preview-container__stats {
    .time-ago {
      color: $color-grey-4;
    }
    .count {
      background-color: $color-white;
      color: var(--primary-color);
    }
  }
}

@media screen and (max-width: 786px) {
  .a-chat-preview-container {
    .group-icon {
      padding-bottom: 20px;
      img {
        margin-right: 1px;
      }
    }
    .a-chat-preview-container__stats {
      .count {
        color: $color-white;
      }
    }
  }
}
