@import "/src/assets/styles/global.scss";

.o-discover-network {
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 0 auto;
  }
  @media (max-width: 1170px) {
    padding-bottom: 0px;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &__header {
    margin: 30px 0 15px 0;
    @media (max-width: 1206px) {
      margin: 8px 0 16px 0px;
    }
  }
  &__user-details-wrapper {
    padding: 30px;
  }
}
