@import "../../../assets/styles/global.scss";

.m-my-topic-middle-post-detail {
  position: relative;
  &__delete-post {
    position: absolute;
    right: 0;
    display: flex;
    img {
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
  }
  .m-my-topic-middle-post-detail-header {
    display: flex;
    align-items: center;
    .m-my-topic-middle-post-detail-header-posted-by-img {
      margin-right: 8px;
    }
    .m-my-topic-middle-post-detail-header-text-wrapper {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      span:first-child {
        display: block;
        padding: 0px 0px 0px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      span:nth-child(2) {
        display: block;
        color: #607790;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0;
        margin-left: 6px;
        @media (max-width: 767px) {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  .m-my-topic-middle-post-detail-subheader {
    margin: 10px 0 0px;
    color: var(--primary-font-color);
    font-family: "TTCommons-Regular";
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0;
    word-break: break-word;
    white-space: pre-line;
    @media (max-width: 1367px) {
      margin: 12px 0 8px;
    }
    .topic-middle-post-detail-container {
      margin: 10px 0 10px 0;
      background: #f1f4f7;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      .text-detail-wrapper {
        padding: 0 10px 10px 10px;
        h3 {
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          line-height: 24px;
          text-decoration: none;
          margin: 10px 0 8px 0;
          a {
            color: var(--primary-font-color);
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            line-height: 24px;
            text-decoration: none;
          }
        }
        h4 {
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-weight: 500;
          font-size: 18px;
          line-height: 19px;
          letter-spacing: 0;
          margin: 0;
        }
      }
      .m-my-topic-middle-post-detail-preview-video {
        width: 100%;
        > div {
          width: 100% !important;
        }
      }
      .m-my-topic-middle-post-detail-preview-image {
        width: 100%;
        margin-top: 10px;
        border-radius: 5px;

        img {
          width: 100%;
          max-height: 500px;
          object-fit: contain;
        }
      }
    }
  }
}
// keeping this out as it is used at two different levels in nested manner
.m-my-topic-middle-post-detail-default-image {
  width: 100%;
  border-radius: 5px;

  img {
    width: 100%;
    vertical-align: middle;
  }
}
