@import "/src/assets/styles/global.scss";
.messages-container {
  overflow-y: auto;
  position: relative;
  height: 100%;
  -ms-overflow-style: auto;
  overscroll-behavior: none;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $color-grey transparent; /* scroll thumb and track */
  transition: scrollbar-color 0.3s ease-out;
  padding-right: 16px;
  overflow-x: hidden;

  &__emoji-picker {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 5;
  }

  &.messages-container__fresh {
    height: auto !important;
  }
  &__loader {
    max-height: 170px;
    overflow-y: hidden;
  }
  .show-older-messages {
    display: flex;
    height: 0px !important;
    margin: 11px !important;
    > span {
      cursor: pointer;
    }
    .date {
      width: 140px !important;
      color: var(--primary-color) !important;
    }
  }
  .divider {
    height: 1px;
    background-color: $color-grey-4;
    position: relative;
    margin: 20px 0;
    .date {
      &.groupByDate {
        min-width: 125px !important;
      }
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-grey-2;
      background-color: $color-white;
      width: 50px;
      font-size: 15px;
      text-align: center;
      margin-top: 2px;
    }
  }
  &__request-meeting {
    &__time {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 5px;
      margin: 0 10px 5px 10px;
      span {
        display: block;
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $grey-text-color;
        height: 12px;
      }
      &__own {
        justify-content: flex-end !important;
      }
    }
    &__banner {
      min-height: 60px;
      border-radius: 10px;
      margin: 0 0 29px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
      gap: 20px;
      span:first-child {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
      }
      span:last-child {
        color: var(--primary-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        cursor: pointer;
      }
    }
  }
}

.messages-container::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.messages-container::-webkit-scrollbar-track {
  background: transparent !important; /* color of the tracking area */
  border-radius: 50px;
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: $color-grey !important; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: none;
  height: 10px !important;
}

.messages-container::-webkit-scrollbar-corner {
  border-radius: 50%;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  border: 0px;
}
