@import "/src/assets/styles/global.scss";
.m-search-container {
  position: relative;
  &__form {
    position: relative;
    &__close-icon-wrapper {
      display: flex;
      width: fit-content;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
  .loading-container {
    background: white;
    text-align: center;
    padding: 10px;
    position: absolute;
    z-index: 999;
    width: 100%;
    top: 55px;
    border-radius: 6px;
  }
}

.dropdown-container {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  position: absolute;
  margin-top: 8px;
  top: 100%;
  width: 100%;
  z-index: 1;
  &__content {
    position: initial !important;
    max-height: 240px;
    overflow-y: scroll;
    -webkit-scrollbar-width: thin;
    -moz-scrollbar-width: thin;
    -ms-scrollbar-width: thin;
    scrollbar-width: thin;
    display: block;
    overflow-y: auto;
    margin: auto;
    width: 100%;
    overflow-x: hidden;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      //   background: ${BLUE_COLOR_02} !important; /* color of the tracking area */
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      // background-color: ${MEDIUM_GRAY_COLOR} !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 150px !important;
    }
    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }
    &::-webkit-scrollbar-thumb:hover {
      border: 0;
    }
    .load-more-search-messages {
      padding: 15px;
      text-align: center;
      cursor: pointer;
      font-family: "TTCommons-Regular";
      font-weight: 600;
      span {
        font-size: 18px;
        line-height: 19px;
        color: var(--link-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        text-decoration: none;
        border-bottom: 2px solid var(--link-color);
        cursor: pointer;
        width: auto;
        height: auto;
        border-radius: 0px;
        background: transparent !important;
        padding: 0;
      }
    }
    .content-container {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #d1dde5;
      &:hover {
        background-color: #ececff;
        cursor: pointer;
      }
      &__message {
        font-size: 14px;
        font-style: normal;
        font-family: "TTCommons-Regular";
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &__title {
        margin-top: 0px;
        text-align: right;
        .divider {
          font-family: "TTCommons-Medium" !important;
          font-weight: 500 !important;
          margin: 0px 3px 0 2px;
          position: relative;
          top: -1px;
          &::before {
            content: "";
          }
          &::after {
            content: "";
          }
        }
        span {
          font-size: 14px;
          color: var(--primary-color);
          font-style: italic;
          font-family: "TTCommons-Medium" !important;
          font-weight: 500;
          &::before {
            content: open-quote;
          }
          &::after {
            content: close-quote;
          }
        }
      }
    }
  }
}
.nempty-message-container {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  position: absolute;
  margin-top: 8px;
  top: 100%;
  width: 100%;
  z-index: 1;
  .dropdown-container__content {
    position: initial !important;
    max-height: 450px;
    overflow-y: scroll;
    -webkit-scrollbar-width: thin;
    -moz-scrollbar-width: thin;
    -ms-scrollbar-width: thin;
    scrollbar-width: thin;
    display: block;
    overflow-y: auto;
    margin: auto;
    width: 100%;
    overflow-x: hidden;
    padding: 24px;
    text-align: center;
    color: #000c3d;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
  }
}

.chat-user-list-item {
  display: flex;
  align-content: space-between;
  align-items: center;
  gap: 1rem;
  overflow-wrap: anywhere;
  &.__avatar-wrapper {
    height: 30px;
  }
  h4 {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.people-container {
  padding: 6.5px;
}

.content-title-container {
  padding: 7.5px 7.5px 5px 7.5px;
  border-bottom: 1px solid #d1dde5;
  font-size: 16px;
  font-style: bold;
  color: var(--gray-color);
  font-family: "TTCommons-Medium";
}
