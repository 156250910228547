@import "../../../assets/styles/global.scss";

.m-widget-messages-tab {
  position: relative;
  @media (max-width: 830px) {
    padding-top: 30px;
  }
  &__chat-view {
    &__post {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: var(--box-shadow);
      max-width: 669px;
      margin: 0 auto;
      height: 265px;
      align-items: flex-start;
      &__content {
        display: flex;
        align-items: center;
        padding: 5px 23px 5px 14px;
        width: 100%;
        pointer-events: none;
        &__pic {
          margin-right: 12px;
          > div {
            width: 50px !important;
            height: 50px !important;
            > div {
              width: 50px !important;
              height: 50px !important;
            }
          }
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
            object-position: center;
          }
        }

        h2 {
          color: var(--primary-font-color);
          @include TTCommonsMedium;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          padding-top: 4px;
        }

        &__menu {
          margin-left: auto;
          position: relative;
          &__icon {
            height: 40px;
            width: 40px;
            border: 2px solid var(--light-gray-color);
            border-radius: 45px;
            background-color: rgb(255, 255, 255);
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            svg {
              width: 24px;
              height: 24px;
              cursor: pointer;
              fill: #607790;
            }
          }
          &__items {
            position: absolute;
            border: 2px solid $gray-outline-color;
            border-radius: 10px 1px 10px 10px;
            background-color: rgb(255, 255, 255);
            box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
            width: 265px;
            padding: 0px 20px;
            transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            z-index: 1;
            right: 0;
            margin-top: 8px;
            span {
              cursor: pointer;
              font-size: 22px;
              font-weight: 500;
              letter-spacing: 0px;
              text-align: center;
              text-decoration: none;
              display: block;
              padding: 28px 0px 21px;
              color: var(--primary-font-color);
              font-family: "TTCommons-Medium";
              line-height: 21px;
              -webkit-box-pack: center;
              justify-content: center;
            }
            span:nth-child(2) {
              border-top: 1px solid $gray-outline-color;
            }
          }
        }
        + div {
          max-width: 630px;
          margin: 30px auto 0;
          textarea {
            background: transparent;
          }
        }
      }
    }

    &__create-account {
      background-color: rgba(241, 244, 247, 0.9);
      padding: 30px 16px 61px;
      margin-top: -200px;
      text-align: center;
      box-shadow: var(--box-shadow);
      position: relative;
      @media (max-width: 767px) {
        padding: 40px 16px 60px;
      }
      &__overlay {
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 1px 54px 3px rgba(128, 148, 171, 0.4);
        max-width: 669px;
        margin: 30px auto;
        padding: 50px 10px;
        text-align: center;
        @media (max-width: 767px) {
          padding: 24px 16px;
          margin-top: 16px;
        }

        &__title {
          font-size: 30px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
          font-family: "TTCommons-Medium";
          color: var(--primary-font-color);
          margin: 0px 0 7px 0px;
          padding-top: 5px;
          @media (max-width: 930px) {
            font-size: 22px;
            line-height: 22px;
            align-items: center;
            margin: 0 0 8px 0;
          }
        }
        &__sub-title {
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          font-family: "TTCommons-regular";
          color: var(--primary-font-color);
          margin-bottom: 30px;
          padding-top: 4px;
          @media (max-width: 930px) {
            font-size: 18px;
            line-height: 18px;
            align-items: center;
            margin: 0 0 16px 0;
          }
        }
        h5 {
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
          text-align: center;
          margin: 0 0 30px 0;
          padding-top: 4px;
          @media (max-width: 767px) {
            margin: 0 0 16px 0;
          }
          a {
            color: var(--link-color);
            margin-left: 4px;
            border-bottom: 1px solid var(--link-color);
            text-decoration: none;
          }
        }

        &__select {
          margin: 0px 0px 30px 0px;
        }

        button {
          width: 100%;
          max-width: 325px;
          font-family: "TTCommons-Medium";
        }
      }
    }
  }

  &__signup-view {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 54px 3px rgba(128, 148, 171, 0.4);
    max-width: 669px;
    margin: 0 auto;
    padding: 50px 10px;
    text-align: center;
    margin-top: 45px;
    @media (max-width: 767px) {
      padding: 24px 16px;
      margin-top: 16px;
    }

    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 34px;
      padding-top: 4px;
      margin: 0 0 30px 0;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 31px;
        margin: 0 0 16px 0;
      }
    }

    h5 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin: 0 0 30px 0;
      padding-top: 4px;
      @media (max-width: 767px) {
        margin: 0 0 16px 0;
      }
      a {
        color: var(--link-color);
        margin-left: 4px;
        border-bottom: 1px solid var(--link-color);
        text-decoration: none;
      }
    }

    &__select {
      margin: 0px 0px 30px 0px;
    }

    button {
      width: 100%;
      max-width: 325px;
      font-family: "TTCommons-Medium";
    }
  }
}
