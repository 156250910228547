@import "../../../assets/styles/global.scss";

.m-contact-us-card {
  @media (max-width: 1367px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  > div {
    padding: 30px 20px;
    background: white;
    @media (max-width: 1367px) {
      padding: 16px;
    }
  }
  &__title {
    h3 {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      margin: 0;
    }
  }
  &__subtitle {
    margin: 6px 0px 15px 0;
    span {
      color: var(--primary-font-color);
      font-size: 18px;
      letter-spacing: 0px;
      line-height: 19px;
      text-align: center;
      max-width: 260px;
      font-family: "TTCommons-Regular";
    }
  }
  &__button {
    button {
      font-size: 18px;
      line-height: 19px;
      color: var(--link-color);
      font-family: "TTCommons-Medium";
      font-weight: 500;
      text-decoration: none;
      border-bottom: 2px solid var(--link-color);
      cursor: pointer;
      width: auto;
      height: auto;
      border-radius: 0px;
      background: transparent !important;
      padding: 0;
    }
  }
}

.m-contact-us-modal-body {
  &__descriptor {
    color: var(--primary-font-color);
    width: 100%;
    font-size: 15px !important;
    line-height: 17px !important;
    text-align: center;
    font-family: "TTCommons-Regular";
  }
  &__form {
    &__body {
      &__issue {
        max-width: 285px;
        margin: 16px auto 0;
      }
      &__comment {
        margin: 16px 0 20px 0;
        width: 100%;
        @media (max-width: 768px) {
          margin: 15px 0 25px 0 !important;
        }
        textarea {
          padding: 20px !important;
        }
        > div {
          > div {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
      }
      &__file_upload {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
          margin: 8px 0 0 0;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          color: var(--primary-font-color);
        }
      }
      &__submit_button {
        margin: 30px 0 30px 0;
        @media (max-width: 767px) {
          margin: 15px 0 15px 0;
        }
        button {
          width: 248px;
        }
        @media (max-width: 768px) {
          margin: 15px 0 15px 0;
          button {
            width: 160px;
            height: 50px;
          }
        }
      }
      &__cancel {
        span {
          color: var(--secondary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 16px;
          border-bottom: 2px solid var(--secondary-font-color);
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
