@import "/src/assets/styles/global.scss";
.m-request-meeting {
  &__header {
    font-family: "TTCommons-Regular";
    font-size: 30px;
    font-weight: 500;
    line-height: 31px;
    text-align: center;
    color: var(--primary-font-color);
    margin: 0 0 0 0;
    padding-top: 27px;
  }
  &__user {
    @include my($top: 20px);
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__title {
    @include my($top: 15px);
    span {
      color: var(--primary-font-color);
      @include TTCommonsMedium;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
    }
  }
  &__textbox {
    @include my($top: 16px);
    textarea {
      height: 234px !important;
      padding: 20px !important;
      color: var(--secondary-font-color);
    }
  }
  &__static-text {
    @include my($top: 16px);
    textarea {
      width: 100%;
      height: 234px;
      padding: 20px;
      border: 1px solid transparent;
      border-radius: 10px;
      background-color: #f1f4f7;
      resize: none;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      // Scroll styling
      overflow-y: auto;
      scrollbar-width: thin;
      -ms-overflow-style: auto;
      overscroll-behavior: none;
      scrollbar-width: thin; /* "auto" or "thin" */
      scrollbar-color: $color-grey transparent; /* scroll thumb and track */
      transition: scrollbar-color 0.3s ease-out;

      &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: transparent !important; /* color of the tracking area */
        border-radius: 50px;
      }

      &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 5px;
      }

      &::-webkit-scrollbar-track-piece:start {
        margin-top: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-grey !important; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: none;
        height: 10px !important;
      }

      &::-webkit-scrollbar-corner {
        border-radius: 50%;
      }

      &::-webkit-scrollbar-thumb:hover {
        border: 0px;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: var(--secondary-font-color);
        opacity: 1 !important;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: var(--secondary-font-color);
        opacity: 1 !important;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: var(--secondary-font-color);
        opacity: 1 !important;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: var(--secondary-font-color);
        opacity: 1 !important;
      }
      &:focus {
        box-shadow: 0 0 8px 0 var(--primary-font-color) !important;
        border-color: var(--primary-font-color) !important;
      }
      &:-webkit-autofill {
        border-radius: 45px;
      }
    }
  }
  &__text {
    @include my($top: 16px);
    p {
      color: var(--primary-font-color);
      @include TTCommonsRegular;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      @include center;
    }
  }
  &__footer {
    button {
      width: 325px;
      height: 60px;
      font-size: 22px;
      line-height: 21px;
      @media (min-width: 320px) {
        width: 200px;
        height: 60px;
      }
      @media (min-width: 375px) {
        width: 200px;
        height: 60px;
      }
      @media (min-width: 425px) {
        width: 325px;
        height: 60px;
      }
    }
  }
}
