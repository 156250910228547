@import "../../../assets/styles/global.scss";

.a-widget-courses-wrapper {
  margin-top: 15px;
  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    color: var(--primary-font-color);
    font-family: "TTCommons-Medium";
    font-weight: 500;
    letter-spacing: 0;
    font-size: 30px;
    text-align: center;
  }
  &__masterclass {
    position: relative;
    &__card {
      display: flex;
      align-items: center;
      box-shadow: var(--box-shadow);
      border-radius: 10px;
      overflow: hidden;
      padding: 15px 15px 15px;
      position: relative;
      background-color: #fff;
      gap: 20px;
      img {
        width: 125px;
        height: 125px;
        object-fit: fill;
      }
      &__details {
        h3 {
          font-family: "TTCommons-Medium";
          font-size: 20px;
          font-weight: 500;
          line-height: 23px;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin: 0;
        }
        p {
          margin: 5px 0;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          font-weight: 400;
          line-height: 17px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        button {
          height: 30px;
          width: 100%;
          max-width: 125px;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }
  }
  &__f1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 10px;
      width: 80%;
      max-width: 960px;
      cursor: pointer;
    }
  }
}
