@import "/src/assets/styles/global.scss";

.a-scroll-to-top-icon {
  position: sticky;
  bottom: 30px;
  right: 100px;
  z-index: 9;
  font-size: 18px;
  margin-left: auto;
  border-radius: 100px;
  padding: 0;
  border: 0;
  margin-right: -25px;
  box-shadow: rgb(209, 221, 229) 0px 1px 14px 3px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  overflow: hidden;
  @media (max-width: 992px) {
    bottom: 30px;
    margin-right: 0;
    margin-left: auto;
    right: 0;
  }
  img {
    width: 50px;
    height: auto;
  }
}
