@import "../../../assets/styles/global.scss";

.m-parent-prospect-invite-form {
  h2 {
    color: var(--primary-font-color);
    @include TTCommonsMedium;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    max-width: 240px;
    margin: 25px auto 12px;
  }
  &__subtitle {
    color: var(--primary-font-color);
    @include TTCommonsRegular;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
  &__formfields {
    max-width: 325px;
    margin: 30px auto 0px;
    > div {
      + div {
        margin-top: 16px;
      }
    }
    &--group {
      margin-top: 15px;
      input {
        text-align: center;
      }
    }
  }
  button {
    max-width: 325px;
    + button {
      margin-top: 20px;
    }
  }
}
