.create-post {
  &__content {
    &__text-area {
      width: 100%;
      position: relative;
      textarea {
        resize: none;
        width: 100%;
        border: 2px solid var(--light-gray-color);
        border-radius: 10px;
        background-color: #ffffff;
        padding: 21px 20px;
        font-size: 18px;
        line-height: 24px;
        font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
        color: var(--primary-font-color);
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--field-label-color) !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--field-label-color) !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--field-label-color) !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--field-label-color) !important;
        }
        // --Focus style
        &:focus {
          border-color: var(--secondary-font-color) !important;
        }
        &:hover {
          border-color: #bfced8;
        }
        /* scroll styling start */
        scrollbar-width: thin !important;
        scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          margin: 10px 0 10px 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(
            --light-gray-color
          ) !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 20px;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: var(
            --secondary-font-color
          ) !important; /* color of the scroll thumb */
        }
        /* scroll styling end */
      }
    }
    &__post-btn {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      position: relative;
      &__left {
        display: flex;
        align-items: flex-start;
        gap: 5px;
        &__emoji {
          height: 35px;
          &__icon {
            cursor: pointer;
          }
          &__picker {
            position: absolute;
            width: 100%;
            left: 0px;
            top: 40px;
            z-index: 10;
            .emoji-picker-react {
              box-shadow: none !important;
            }
          }
        }
        &__attachment {
          display: flex;
          align-items: center;
          &__hidden-input {
            position: absolute;
            top: -99999px;
            left: -342432px;
            visibility: hidden;
            opacity: 0;
          }
          &__add-icon {
            cursor: pointer;
          }
          &__file {
            display: flex;
            align-items: center;
            &__icon {
              margin: 0 8px 0 5px !important;
              background: var(--gray-100);
              display: flex;
              align-items: center;
              width: 35px;
              height: 35px;
              padding: 0 !important;
              min-width: 35px;
              transition: all 0.3s;
              border-radius: 100px;
              justify-content: center;
              cursor: pointer;
              &:hover {
                background: #eaeef3;
              }
            }
            &__name {
              color: var(--primary-font-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              display: inline-block;
              height: 18px;
              white-space: nowrap;
              max-width: 175px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      &__right {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        &__checkbox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          &__text {
            display: inline-block;
            color: var(--gray-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 2px;
          }
          svg {
            color: var(--primary-color);
          }
        }
        button {
          height: 35px;
          width: 78px;
          border-radius: 45px;
        }
      }
    }
  }
  &__preview-loader {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  &__preview-url {
    text-decoration: none;
    &__n-box-wrapper {
      margin: 15px 0 0;
      h3 {
        margin: 15px 0 15px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
      }
      h4 {
        margin: 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
      }
      h5 {
        margin: 0;
        color: var(--secondary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        -webkit-letter-spacing: 0;
        -moz-letter-spacing: 0;
        -ms-letter-spacing: 0;
        letter-spacing: 0;
        line-height: 15px;
      }
      &__video {
        margin: 0 0 30px;
      }
      &__image {
        height: 275px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
