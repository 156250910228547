@import "../../../assets/styles/global.scss";

.m-checklist-list {
  margin: 10px 0 0 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 15px;
    > div {
      padding: 10px 20px;
      transition: all 0.3s ease 0s;
      @media (max-width: 992px) {
        padding: 15px;
      }
      a,
      button {
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease 0s;
        @media (max-width: 992px) {
          opacity: 1;
          visibility: visible;
        }
      }
      &:hover {
        a,
        button {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__loader {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 14px;
    border-radius: 10px;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  }
  ul {
    list-style: none;
    padding: 0px;
    margin-left: 50px;
    margin-top: 15px;
    @media (max-width: 767px) {
      margin-left: 36px;
    }
    li {
      padding: 0;
      div {
        padding: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
