@import "/src/assets/styles/global.scss";

.m-calculator-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &__header {
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 5px 0;
    &__tag {
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
      border-radius: 100px;
      padding: 3px 5px 1px;
      min-height: 20px;
      min-width: fit-content;
      max-width: fit-content;
      white-space: nowrap;
      &--yellow {
        background: #fbeec4;
      }
      &--green {
        background: #ddf4ce;
      }
    }
  }

  &__subtitles {
    display: flex;
    margin: 0 0 20px 0;
    &__item {
      font-size: 17px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      font-family: "TTCommons-Medium";
      + div {
        margin-left: 8px;
        font-weight: 400;
        font-family: "TTCommons-Regular";
      }
    }
  }

  &__details {
    margin: 16px 0;
    &__item {
      display: flex;
      margin: 0 0 8px 0;
      align-items: center;
      &__title {
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
        color: var(--secondary-font-color);
        margin-right: 8px;
        min-width: 50px;
      }
      &__value {
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
      }
    }
  }

  &__description {
    font-size: 15px;
    font-weight: 400;
    line-height: 17px;

    &__link {
      font-family: "TTCommons-Medium";
      font-size: 15px;
      font-weight: 500;
      line-height: 17px;
      display: inline-block;
      border-bottom: 1px solid;
      margin-left: 4px;
      cursor: pointer;
    }
  }
}
