@import "../../../assets/styles/global.scss";

.m-widget-employment {
  &__wrapper {
    display: flex;
    width: 100%;
    position: relative;
    gap: 20px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: 500;
      line-height: 31px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    h2 + div {
      margin-top: 0;
    }

    &__right-sidebar {
      width: 33%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (max-width: 767px) {
        width: 100%;
      }
      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
      &--no-emp-data {
        width: 100%;
        flex-direction: row;
        > div:nth-child(1) {
          width: 66%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          @media (max-width: 767px) {
            width: 100%;
          }
          > div {
            align-items: center;
            button {
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              line-height: 17px;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              padding: 12px 16px 8px;
              min-height: 46px;
            }
          }
        }
        > div:nth-child(2) {
          width: 33%;
          display: flex;
          flex-direction: column;
          gap: 10px;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      &__card {
        display: flex;
        flex-direction: column;
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        overflow: hidden;
        padding: 15px 15px 15px;
        position: relative;
        background-color: #fff;
        gap: 6px;
        @media (max-width: 767px) {
          align-items: center;
        }
        &--shimmer {
          padding: 0;
        }
        h3 {
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin: 0;
        }
        &__scholarships {
          display: flex;
          flex-direction: column;
          gap: 6px;
          &__entry {
            &__header {
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              line-height: 19px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              display: flex;
              align-items: center;
              &__title {
                padding-top: 3px;
                width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                margin-right: 5px;
                @media (max-width: 767px) {
                  width: auto;
                }
              }
              &__tag {
                font-size: 15px;
                font-weight: 400;
                line-height: 17px;
                text-align: center;
                border-radius: 100px;
                padding: 3px 9px 1px;
                min-height: 20px;
                min-width: fit-content;
                max-width: fit-content;
                white-space: nowrap;
                &--yellow {
                  background: #fbeec4;
                }
                &--green {
                  background: #ddf4ce;
                }
              }
              &__value {
                font-family: "TTCommons-Regular";
                font-weight: normal;
                span {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 15px;
                  color: var(--secondary-font-color);
                  margin-right: 8px;
                  min-width: 50px;
                }
              }
            }
          }
        }
        button {
          height: 30px;
          width: 100%;
          max-width: fit-content;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          padding: 0 10px;
        }
        &__sample-cost {
          h3 {
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 19px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
          h4 {
            font-weight: 500;
            margin: 0;
          }
        }
      }
      &__map {
        height: 225px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        &--only-map {
          height: 500px !important;
        }

        &__overlay {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(
            var(--primary-color-r),
            var(--primary-color-g),
            var(--primary-color-b),
            0.8
          );
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
          pointer-events: none;

          button {
            background-color: #fff !important;
            color: var(--primary-color) !important;
            padding: 15px 15px 12px 15px;
            width: fit-content;
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 17px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }

        &:hover {
          .m-widget-employment__wrapper__right-sidebar__map__overlay {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
      &__maps:hover {
        &__overlay {
          visibility: visible !important;
        }
      }
    }
    &__employment-table {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 66%;
      @media (max-width: 767px) {
        width: 100%;
      }
      &__loader {
        > div {
          > div {
            width: 100% !important;
            height: 369px !important;
            margin-bottom: 0px !important;
          }
        }
      }
      &__wrapper {
        position: relative;
        background-color: white;
        box-shadow: var(--box-shadow);
        border-radius: 10px;
        padding-top: 5px;
        &__title {
          text-align: center;
          font-family: "TTCommons-Regular";
          font-size: 22px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        &__filters {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          &__title {
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 19px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: var(--field-label-color);
          }
          &__item {
            width: 160px;
            cursor: pointer;
          }
        }
        &__mui-table > div > div {
          padding: 0 !important;
          table {
            min-width: 580px !important;
            td,
            th {
              font-family: "TTCommons-Regular" !important;
              font-size: 15px !important;
              font-weight: 400 !important;
              line-height: 17px !important;
              text-align: left !important;
              text-underline-position: from-font !important;
              text-decoration-skip-ink: none !important;
              padding: 24px 0 24px 12px !important;
            }
            tbody {
              border-radius: 0 !important;
              box-shadow: none !important;
            }
            th:nth-child(5) {
              text-wrap: auto;
            }
            td:first-child,
            th:first-child {
              padding: 24px 0 24px 18px !important;
            }
          }
        }
      }
    }
  }
}
