@import "../../../assets/styles/global.scss";

.m-privacy-policy-details {
  &__header {
    h2 {
      color: var(--primary-font-color);
      justify-content: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      font-size: 30px;
      margin: 0px 0px 15px 0px;
      min-height: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      padding-top: 0;
      @media (max-width: 1367px) {
        font-size: 22px;
        line-height: 20px;
        min-height: auto;
      }
    }
  }

  &__date {
    h3 {
      margin-top: 0;
      font-family: "TTCommons-Medium";
      color: var(--primary-font-color);
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      font-weight: 400;
    }
  }

  &__content {
    &__paragraph {
      margin: 0;
      color: $grey-text-color;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
      + p {
        margin-top: 20px;
      }
    }
  }
}
