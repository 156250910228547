@import "../../../assets/styles/global.scss";

.m-dashboard-post-slider {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      vertical-align: middle;
    }
  }

  &__video {
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      width: 100%;
    }
  }

  &__pdf {
    width: 100%;
  }
}
