@import "../../../assets/styles/global.scss";
.a-checkbox__default {
  span {
    @include TTCommonsRegular;
    color: var(--primary-font-color);
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    display: block;
    margin: 0 0 6px 0;
  }
  box-sizing: border-box;
  @include wh(100%, 60px);
  @include border(2px, $color-grey);
  @include radius(45px);
  @include background-color($color-white);
  @include TTCommonsMedium;
  color: var(--primary-font-color);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  padding: 22px 40px 18.3px 22px !important;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    @include text-color($color-dark-gray);
  }
  &::-moz-placeholder {
    @include text-color($color-dark-gray);
  }
  &:-ms-input-placeholder {
    @include text-color($color-dark-gray);
  }
  &:-moz-placeholder {
    @include text-color($color-dark-gray);
  }
}

.a-checkbox--disabled {
  //disable
  cursor: not-allowed;
}
