@import "../../../assets/styles/global.scss";

.m-checklist-subtask {
  display: flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    > div:first-child {
      > span {
        padding: 0px;
        margin: 0px 15px 0px 0px;
        padding: 0;
        height: 20px;
        width: 20px;
        border-radius: 100px;
        outline: 0;
        box-shadow: none;
        background-color: #f1f4f7;
        > svg {
          display: block;
          width: 24px;
          height: 24px;
          color: #000485;
        }
        span {
          svg {
            display: none;
          }
        }
      }
    }
    .MuiRadio-root {
      padding: 0;
      margin: 0 15px 0px 0;
    }

    .MuiIconButton-colorPrimary {
      &:hover {
        background-color: transparent;
      }
    }
    .MuiIconButton-root {
      &:hover {
        background-color: transparent;
      }
    }

    & span.Mui-checked + div {
      text-decoration: line-through;
      text-decoration-color: var(--secondary-color);
    }

    &__text {
      font-size: 18px;
      font-family: "TTCommons-Regular";
      line-height: 19px;
      padding: 2px 0 0 0 !important;
      width: 100%;
      color: var(--primary-font-color);
      padding-right: 8px;
      @media (max-width: 767px) {
        width: calc(100% - 35px);
        padding-right: 0;
        margin: 0 0 8px 0;
      }
      input[type="text"] {
        font-size: 18px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        line-height: 19px;
        padding: 0px 0 0 0;
        color: var(--primary-font-color);
        outline: 0 !important;
        width: 100%;
        border: 0;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #8895a9 !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #8895a9 !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #8895a9 !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #8895a9 !important;
        }
      }

      &--completed {
        text-decoration: line-through;
      }
    }

    &__button-edit {
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      cursor: pointer;
      padding: 0;
      min-width: auto;
      box-shadow: none;
      flex: 0 0 35px;
      height: 35px;
      width: 35px;
      border-radius: 100px;
      background-color: var(--gray-100);
      transition: all 0.3s;
      opacity: 1;
      margin-left: 12px;
      &:hover {
        background-color: #eaeef3;
      }
    }

    &__button-close {
      padding: 2px 0 0 0;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      cursor: pointer;
      min-width: auto;
      box-shadow: none;
      flex: 0 0 35px;
      height: 35px;
      width: 35px;
      border-radius: 100px;
      background-color: var(--gray-100);
      transition: all 0.3s;
      opacity: 1;
      margin-left: 12px;
      &:hover {
        background-color: #eaeef3;
      }
    }
  }
}
