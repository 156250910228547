.m-dashboard-post-list-container {
  &__empty {
    text-align: center;
    font-family: "TTCommons-Medium";
    font-size: 22px;
    line-height: 21px;
    font-weight: 400;
    margin: 0;
    h2 {
      margin: 30px 0 30px 0;
      font-size: 30px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      color: var(--primary-font-color);
    }
    svg {
      max-width: 100%;
    }
  }
}
