@import "/src/assets/styles/global.scss";

.a-participant-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__title {
      span {
        margin-left: 12px;
        display: block;
        color: var(--primary-font-color);
        @include TTCommonsRegular;
        text-align: left;
        font-size: 15px;
        line-height: 12px;
      }
    }
    &__tag {
      margin-bottom: 1px;
      span {
        border-radius: 10.5px;
        background-color: #e5eaf7;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        color: var(--primary-font-color);
        display: flex;
        align-items: center;
        padding: 4px 7px 0px;
        min-height: 20px;
        margin-left: 8px;
        @include TTCommonsRegular;
      }
    }
  }
}
