@import "../../../assets/styles/global.scss";

.m-leave-feedback-modal-body {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__body {
      &__rate {
        margin: 40px 0 0 0;
        @media (max-width: 768px) {
          margin: 0 !important;
        }
        &__label {
          margin: 0 0 7px 0 !important;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
        > span {
          > span {
            margin: 0 5px;
          }
        }
        .star {
          width: 52px !important;
          height: 52px !important;
          margin: 0 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__comment {
        margin: 25px 0 25px 0;
        @media (max-width: 767px) {
          margin: 15px 0 15px 0;
        }
        label {
          margin-bottom: -7px !important;
        }
        textarea {
          padding: 20px !important;
          margin-bottom: -15px;
        }
      }
      &__recommended_rate {
        width: fit-content;
        &__label {
          margin: 0 0 7px 0 !important;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
        ul {
          display: flex;
          padding: 0;
          list-style: none;
          justify-content: center;
          flex-wrap: wrap;
          margin: 16px 0 8px 0;
          li.point {
            height: 28px;
            width: 28px;
            border: 2px solid #0030b5;
            border-radius: 100px;
            line-height: 27px;
            color: #000485;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            text-align: center;
            margin: 6px;
            cursor: pointer;
            @media (max-width: 480px) {
              margin: 1px;
            }
            &.active {
              background: #0030b5;
              color: #fff;
            }
          }
        }
        &__description {
          display: flex;
          justify-content: space-between;
          margin: 0 10px 0 10px;

          span {
            margin: 0 !important;
            color: var(--primary-font-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
      }
      &__submit_button {
        margin: 45px 0 30px 0;
        button {
          width: 248px;
        }
        @media (max-width: 768px) {
          margin: 15px 0 20px 0;
          button {
            width: 160px;
            height: 50px;
          }
        }
      }
      &__cancel {
        @media (max-width: 767px) {
          margin-bottom: 0px;
        }
        span {
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 18px;
          border-bottom: 1px solid var(--primary-font-color);
          cursor: pointer;
        }
      }
    }
  }
}

.m-leave-feedback-confirmation-modal-body {
  &__buttons {
    margin-top: 15px;
    button {
      width: 248px;
    }
    @media (max-width: 768px) {
      margin: 0 0 30px 0;
      button {
        width: 160px;
        height: 50px;
      }
    }
    button {
      height: auto;
      width: 124px;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      padding: 0.75rem 20px 8px;
    }
  }
}
