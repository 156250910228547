@import "../../../assets/styles/global.scss";

.a-header-logos {
  display: flex;
  align-items: center;
  min-width: 192px;
  a {
    display: flex;
    align-items: center;
    svg,
    img {
      max-height: 50px;
      width: auto;
      height: auto;
    }
    + a {
      margin-left: 23px;
      padding-left: 20px;
      border-left: 1px solid var(--light-gray-color);
      @media (max-width: 1280px) {
        margin-left: 12px;
        padding-left: 12px;
      }
      @media (max-width: 992px) {
        margin-left: 8px;
        padding-left: 8px;
      }
      svg,
      img {
        max-height: 25px;
        min-width: 112px;
        width: auto;
        height: auto;
      }
    }
  }
}
