@import "../../../assets/styles/global.scss";

.a-selective-card {
  width: 100%;
  max-width: 158px;
  border: 2px solid #d1dde5;
  border-radius: 11px;
  box-shadow: none;
  text-align: center;
  min-height: 121px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 6px;
  cursor: pointer;
  padding: 16px;
  @media (max-width: 767px) {
    max-width: 135px;
  }
  h6 {
    color: #607790;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    font-family: "TTCommons-Medium";
    margin: 0;
  }

  &--active {
    border-color: var(--primary-color);
    h6 {
      color: var(--primary-color);
    }
  }
}
