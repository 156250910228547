@import "/src/assets/styles/global.scss";

.m-alum-majors {
  margin: 20px 0 100px 0;
  @media (max-width: 1367px) {
    margin: 15px 0 15px 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    gap: 20px;
    @media (max-width: 992px) {
      padding: 0 !important;
      border-bottom: 0 !important;
    }
    &__title {
      @include TTCommonsMedium;
      color: var(--primary-font-color);
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      padding-top: 4px;
      margin: 0 0 0 0;
      @media (max-width: 992px) {
        padding: 14px 8px 12px 16px !important;
      }
      @media (max-width: 992px) {
        padding: 14px 8px 12px 16px !important;
      }
      @media (max-width: 767px) {
        font-size: 16px !important;
        line-height: 16px !important;
      }
    }
    a {
      @media (max-width: 992px) {
        position: absolute;
        right: 8px;
        z-index: 1;
        top: 10px;
      }
    }
    &__filters {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: 992px) {
        padding: 16px 16px 8px 16px;
        border-top: 1px solid #d1dde5;
        margin-top: 0 !important;
      }
      > div {
        width: 213px;
      }
      @media (max-width: 1400px) {
        margin-top: 10px;
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      > div {
        @media (max-width: 992px) {
          float: left;
          margin: 0px 8px 8px 0;
        }
        @media (max-width: 767px) {
          float: none;
          margin: 0px 0px 16px 0;
          width: 100% !important;
        }
      }
      &__clear {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 992px) {
          height: 50px;
        }
        @media (max-width: 767px) {
          justify-content: center;
        }
        span {
          cursor: pointer;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 16px;
          font-weight: 500;
          color: var(--secondary-font-color);
          border-bottom: 2px solid var(--secondary-font-color);
          padding-top: 3px;
          transition: all 0.3s;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  &__body {
    margin-top: 15px;
    width: 100%;
    height: 540px;
    position: relative;
    > div {
      height: 100%;
    }
    &__card {
      &__loader {
        > div {
          > div {
            width: 100% !important;
            height: 484px !important;
            margin-bottom: 0px !important;
          }
        }
      }
      &__no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          height: 430px;
          width: 100%;
        }
        h3 {
          margin: 20px 0 0 0;
          font-family: "TTCommons-Medium";
          color: var(--primary-font-color);
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      &__graph {
        height: 484px;
        overflow-y: auto;
        overflow-x: hidden;
        // Scroll styling start
        scrollbar-width: thin;
        -ms-overflow-style: auto;
        overscroll-behavior: none;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: #d1dde5 transparent !important; /* scroll thumb and track */
        transition: scrollbar-color 0.3s ease-out;
        // Scroll styling end
        &::-webkit-scrollbar {
          width: 5px; /* width of the entire scrollbar */
        }
        &::-webkit-scrollbar-track {
          background: $color-grey-8 !important; /* color of the tracking area */
          border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-grey-4 !important; /* color of the scroll thumb */
          border-radius: 20px; /* roundness of the scroll thumb */
          border: none;
          height: 150px !important;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
        }
      }
    }
  }
}
