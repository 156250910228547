@import "/src/assets/styles/global.scss";

.m-notifications__content {
  &_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 30px;
    overflow: auto;
    @media (max-width: 992px) {
      padding: 16px;
    }
  }
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-bottom: 0px;
    &_left {
      display: flex;
      align-items: center;
      h2 {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        padding-top: 4px;
        white-space: pre;
        margin: 0;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      svg {
        width: 22px;
        height: 22px;
        cursor: pointer;
        color: #000485;
        fill: #000485;
        margin-right: 12px;
      }
      button {
        font-size: 18px;
        line-height: 16px;
        color: var(--link-color);
        font-family: ttcommons-medium;
        font-weight: 500;
        text-decoration: none;
        border: 0;
        border-bottom: 2px solid var(--link-color) !important;
        cursor: pointer;
        padding-top: 3px;
        padding-left: 0;
        padding-right: 0;
        transition: all 0.3s;
        border-radius: 0;
        width: auto;
        height: auto;
      }
    }
  }
  &_items_wrapper {
    &__see-more {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      span {
        cursor: pointer;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        border-bottom: 1px solid var(--primary-color) !important;
        color: var(--primary-color) !important;
        height: 17px;
      }
    }
  }
}
