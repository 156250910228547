@import "../../../assets/styles/global.scss";

.a-toggle-switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  &--disabled {
    .a-toggle-switch-slider {
      border-color: var(--secondary-font-color) !important ;
      &:before {
        background-color: var(--secondary-font-color) !important ;
      }
    }
    span {
      color: var(--secondary-font-color) !important;
    }
  }
}

.a-toggle-switch-label {
  margin-left: 10px;
  padding-top: 5px;
  span {
    font-family: "TTCommons-Regular";
    font-size: 18px;
    line-height: 18px;
    color: var(--secondary-font-color);
  }
}

.a-toggle-switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 20px;
}

.a-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.a-toggle-switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--primary-color);
  border-radius: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 20px;
}

.a-toggle-switch-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  background-color: var(--primary-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100px !important;
  top: 50%;
  transform: translateY(-50%);
}

.a-toggle-switch-input:focus + .a-toggle-switch-slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.a-toggle-switch-input:checked + .a-toggle-switch-slider:before {
  -webkit-transform: translate(11px, -50%);
  -ms-transform: translate(11px, -50%);
  transform: translate(11px, -50%);
  transition: 0.4s;
}

.a-toggle-switch-slider.round {
  border-radius: 34px;
}

.a-toggle-switch-slider.round:before {
  border-radius: 50%;
}
