@import "../../../assets/styles/global.scss";

.o-profile {
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 0 auto;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  .o-profile-header {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 24px;
      margin-top: 30px;
      position: relative;
      min-height: 40px;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 16px;
        margin-top: 30px;
      }
    }
    &__title {
      width: 100%;
      h1 {
        font-size: 22px;
        font-weight: 600;
        font-family: "TTCommons-DemiBold";
        color: var(--primary-font-color);
        line-height: 20px;
        margin: 0;
        text-align: center;
      }
    }
    &__control-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 0;
      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        position: initial;
        margin-top: 16px;
      }
      &__invite-link {
        background: #fff;
        box-shadow: var(--box-shadow);
        color: var(--primary-font-color);
        width: auto;
        padding: 18px 20px 12px;
        height: auto;
        min-height: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        border-radius: 100px;
        text-decoration: none;
        transition: all 0.3s;
        display: inline-block;
        text-transform: none;
        margin: 0 12px 0 0;
        min-width: 130px;
        &:hover {
          box-shadow: 0 1px 14px 3px var(--light-gray-color);
        }
      }
      button {
        margin-right: 0;
        width: auto;
        white-space: normal;
        min-width: 130px;
      }
    }
  }
  .o-profile-login-wrapper {
    @include TTCommonsRegular;
    > div {
      padding: 30px;
      @media (max-width: 767px) {
        padding: 16px;
      }
    }
    .o-profile-login-header {
      &__title {
        h2 {
          font-family: "TTCommons-DemiBold" !important;
          color: var(--primary-font-color);
          font-size: 22px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0;
          margin: 0px 0 5px 0;
        }
      }
    }
    .o-profile-login-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -15px;
      &__field {
        margin-top: 16px;
        width: 25%;
        padding-left: 15px;
        padding-right: 15px;
        @media (max-width: 1250px) {
          width: 50%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        &__label {
          display: flex;
          justify-content: space-between;
          margin-left: 4px;
          margin-right: 0px;
          align-items: center;
          h5 {
            color: var(--field-label-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
            font-family: "TTCommons-Regular";
            padding: 5px 0px 0px;
            margin: 0px 0px 6px 4px;
          }
          &_edit {
            color: var(--link-color);
            font-family: "TTCommons-Medium";
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 12px;
            cursor: pointer;
            padding-right: 10px;
          }
        }
        &__input {
          color: var(--primary-font-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          &__placeholder {
            justify-content: flex-start;
          }
          input {
            padding: 21px 22px 17px;
            text-align: left;
          }
        }
        &__select {
          input {
            padding: 16px 22px;
          }
        }
      }
    }
    .o-profile-password-header {
      margin-top: 30px;
      margin-left: 16px;
      @media (max-width: 320px) {
        margin-left: 0px;
      }
      &__title {
        h2 {
          color: var(--primary-font-color);
          font-size: 26px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 28px;
          margin-bottom: 0.5rem;
        }
      }
      &__body {
        span:first-child {
          cursor: pointer;
          color: var(--primary-color);
        }
      }
    }
  }

  .o-profile-personal-wrapper {
    @include TTCommonsRegular;
    > div {
      padding: 30px;
      @media (max-width: 767px) {
        padding: 16px;
      }
    }
    .o-profile-personal-header {
      &__title {
        h2 {
          font-family: "TTCommons-DemiBold" !important;
          color: var(--primary-font-color);
          font-size: 22px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0;
          margin: 0px 0 5px 0;
        }
      }
    }
    .o-profile-personal-form {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 -15px;
      &__field {
        margin-top: 16px;
        width: 25%;
        padding-left: 15px;
        padding-right: 15px;
        @media (max-width: 1250px) {
          width: 50%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        &__label {
          margin-left: 8px;
          h5 {
            color: var(--field-label-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
            font-family: "TTCommons-Regular";
            padding: 5px 0px 0px;
            margin: 0px 0px 6px 0;
          }
        }
        &__input {
          color: var(--primary-font-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          &__placeholder {
            justify-content: flex-start;
          }
          input {
            padding: 21px 22px 17px;
            text-align: left;
          }
        }
        &__textarea div div div div {
          padding: 0 !important;
        }
        &__select {
          input {
            padding: 16px 22px;
          }
        }
      }
      &__file-upload {
        margin-top: 16px;
        padding-left: 15px;
        padding-right: 15px;
        width: 25%;
        @media (max-width: 1250px) {
          width: 50%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        &-header {
          h5 {
            color: var(--field-label-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 16px;
            font-weight: 400;
            font-family: "TTCommons-Regular";
            padding: 5px 0px 0px;
            margin: 0px 0px 6px 4px;
          }
        }
      }
    }
  }

  .o-profile-education-wrapper {
    @include TTCommonsRegular;
    > div {
      padding: 30px;
      @media (max-width: 767px) {
        padding: 16px;
      }
    }
    .o-profile-education-header {
      &__title {
        h2 {
          font-family: "TTCommons-DemiBold" !important;
          color: var(--primary-font-color);
          font-size: 22px;
          line-height: 20px;
          font-weight: 600;
          letter-spacing: 0;
          margin: 0px 0 5px 0;
        }
      }
    }
    .o-profile-education-form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 -15px;
      &__field {
        margin-top: 16px;
        width: 25%;
        padding-left: 15px;
        padding-right: 15px;
        @media (max-width: 1250px) {
          width: 50%;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
        &__label {
          margin-left: 8px;
          h5 {
            color: var(--primary-font-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            margin-bottom: 6px;
            font-weight: 400;
          }
        }
        &__input {
          color: var(--primary-font-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          &__placeholder {
            justify-content: flex-start;
          }
          input {
            padding: 21px 22px 17px;
            text-align: left;
          }
        }
        &__select {
          input {
            padding: 16px 22px;
          }
        }
      }
    }
  }

  .o-profile-actions-bar {
    margin-top: 30px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }

    @include TTCommonsRegular;
    &__left {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        margin-bottom: 16px;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
      }
      &__preview {
        background-color: #fff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        border-radius: 100px;
        padding: 18px 20px 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        position: relative;
        @include pointer;
        @media (max-width: 767px) {
          width: 100%;
        }
        &__icon {
          display: flex;
          margin: -4px 8px 0 0;
          svg {
            fill: var(--primary-font-color);
            g {
              stroke: var(--primary-font-color);
            }
          }
        }
        &__text {
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
      &__close {
        border-radius: 100px;
        padding: 18px 20px 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        position: relative;
        @include pointer;
        &__text {
          color: #e74c3c;
          text-decoration: underline;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }
    &__right-actions {
      display: flex;
      gap: 10px;
    }
    &__accessibility-mode {
      background-color: #fff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 100px;
      padding: 18px 20px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      position: relative;
      @include pointer;
      margin-left: 15px;
      margin-right: auto;
      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-left: 0;
        margin-bottom: 15px;
      }
      label {
        margin-top: -2px;
      }
      div {
        padding-top: 0;
      }
      span {
        border-color: $color-grey-10;
        &:before {
          background-color: $color-grey-10;
        }
      }
      > span {
        font-family: "TTCommons-Medium";
        font-size: 18px;
        line-height: 18px;
        color: var(--primary-font-color);
        padding: 1px 0 0 0;
        margin-left: 10px;
      }
      input:checked + span {
        border-color: var(--primary-font-color);
        &::before {
          background-color: var(--primary-font-color);
        }
      }
      + button {
        @media (max-width: 767px) {
          margin-left: auto;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }

  .o-profile-close-account-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .submit-button {
      margin-top: 30px;
      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }

    .cancel-link {
      color: $grey-text-color;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 17px;
      text-decoration: none;
      margin-top: 30px;
      border-bottom: 1px solid $grey-text-color;
      text-align: center;
      @media (max-width: 767px) {
        margin-top: 24px;
      }
    }
  }
  &__foot-note {
    margin: 0px 0 48px;
    @media (max-width: 1170px) {
      margin-bottom: 0;
    }
    p {
      margin: 0;
      color: var(--field-label-color);
      font-family: ttcommons-regular;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
  &__invite {
    @include TTCommonsRegular;
    &__header {
      &__title {
        h2 {
          font-family: "TTCommons-Medium" !important;
          color: var(--primary-font-color);
          font-size: 30px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 28px;
          margin-bottom: 0;
          margin-top: 0;
          @media (max-width: 767px) {
            font-size: 22px;
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }

    &__table {
      &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -8px;
        &__column {
          margin-top: 16px;
          width: 25%;
          padding-left: 8px;
          padding-right: 8px;
          @media (max-width: 1250px) {
            width: 33.33%;
          }
          @media (max-width: 600px) {
            width: 100%;
          }
          span {
            display: block;
            margin-bottom: 4px;
            @media (min-width: 601px) {
              display: none;
            }
          }
        }
        &--header {
          // border-bottom: 2px solid #d1dde5;
          color: var(--primary-font-color);
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: "TTCommons-Medium";
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 22px;
          @media (max-width: 600px) {
            display: none;
          }
        }
        &--content {
          font-family: "TTCommons-Medium";
          color: var(--primary-font-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }
  }

  &__loader {
    margin-top: 60px;
    &__row {
      margin: 20px 0px;
    }
  }
}
