@import "../../../assets/styles/global.scss";

.o-help-center {
  margin-top: 30px;
  @media (max-width: 1367px) {
    max-width: 1030px;
    padding: 0 15px 30px;
    margin: 30px auto 0;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  &__pageheader {
    text-align: center;
    h2 {
      color: var(--primary-font-color);
      font-family: "TTCommons-DemiBold";
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin: 0 0 30px;
      @media (max-width: 992px) {
        margin: 0 0 16px;
      }
    }
  }
  > div {
    > div {
      &:first-child {
        @media (max-width: 1367px) {
          max-width: 35%;
          flex-basis: 35%;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      &:nth-child(2) {
        @media (max-width: 1367px) {
          max-width: 65%;
          flex-basis: 65%;
          margin-top: 0px !important;
        }
        @media (max-width: 992px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      &:last-child {
        margin-top: 10px;
        @media (max-width: 1367px) {
          display: none;
        }
      }
    }
  }
  &__header {
    display: flex;
    h2 {
      color: var(--primary-font-color);
      justify-content: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      font-size: 30px;
      margin: 0px 0px 15px 0px;
      min-height: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      padding-top: 0;
      @media (min-width: 993px) {
        display: none;
      }
      @media (max-width: 1367px) {
        font-size: 22px;
        min-height: auto;
        line-height: 19px;
      }
      @media (max-width: 992px) {
        min-height: auto;
      }
      @media (max-width: 767px) {
        min-height: auto;
      }
    }
    + div {
      @media (min-width: 993px) {
        margin-top: 40px !important;
      }
      @media (min-width: 1367px) {
        margin-top: 40px !important;
      }
    }
  }
  &__tutorial {
    h2 {
      color: var(--primary-font-color);
      justify-content: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      letter-spacing: 0;
      font-size: 22px;
      margin: 0px 0px 15px 0px;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      padding-top: 0;
    }
    &__title {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      display: flex;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
    &__video {
      display: flex;
      margin-top: 8px;
      video {
        width: 100%;
        border-radius: 4px;
      }
    }
  }
  &__right-menu-spacer {
    margin-top: 30px;
  }
}
