@import "../../../assets/styles/global.scss";

.o-topics-body-card-wrapper {
  width: 25%;
  padding: 0 8px;
  margin: 0 0 16px 0;
  @media (max-width: 1367px) {
    width: 33.33%;
    padding: 0 4px;
    margin-bottom: 8px;
  }
  @media (max-width: 992px) {
    width: 33.33%;
    > div {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 0px;
  }
  > div {
    max-width: 100%;
  }
}

.o-topic-body-shimmer {
  margin-inline: 25px !important;
  margin-top: 15px !important;
}

.o-topic-body-empty-list-card-title {
  text-align: center;
  width: 100%;
  h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: "TTCommons-Medium";
    margin: 0 0 30px 0;
    color: var(--primary-font-color);
  }
  svg {
    max-width: 100%;
  }
}
