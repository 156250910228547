@import "/src/assets/styles/global.scss";

.m-match-me-card {
  &__match-me {
    background: var(--primary-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: space;
    justify-content: space-evenly;
    padding: 0 20px 0 20px !important;
    height: 185px;
    &--widget {
      height: 172px;
    }
    &__title {
      margin: 0 0 0 0;
      font-family: "TTCommons-Regular";
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
    &__body {
      margin: 0 0 0 0;
      font-family: "TTCommons-Regular";
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
    }
    &__button {
      width: 114px !important;
      height: 30px !important;
      border-radius: 45px;
      margin: 0 auto;
      position: relative;
      button {
        color: var(--primary-color);
        margin-left: -2px;
        width: 118px !important;
        height: 30px !important;
        border-radius: 45px;
        background-color: white;
        font-size: 15px;
        line-height: 17px;
      }
    }
    &--dashboard {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 20px 20px !important;
      &__title {
        font-family: "TTCommons-Regular";
        font-size: 30px;
        font-weight: 500;
        line-height: 31px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}
