@import "../../../../assets/styles/global.scss";

.m-webinars-calendar-sidebar {
  margin-bottom: 42px;
  @media (max-width: 1367px) {
    margin-bottom: 20px;
  }
  > div {
    padding: 20px !important;
  }
  &__item {
    display: flex;
    cursor: pointer;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
    &__left {
      width: 50%;
      @media (max-width: 991px) {
        width: 35%;
        min-width: 220px;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      h3 {
        margin: 0 0 0 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-DemiBold";
        font-size: 18px;
        font-weight: 600;

        letter-spacing: 0;
        line-height: 19px;
      }
      &__category {
        border-radius: 12.5px;
        background-color: var(--gray-100);
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        padding: 4px 8px 2px 8px;
        min-width: 69px;
        width: fit-content;
        margin: 5px 0px;
      }
      &__presenter {
        margin: 4px 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          justify-content: center;
        }
        img {
          margin-right: 8px;
        }
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        color: var(--primary-font-color);
        font-family: "TTCommons-Regular";
        margin: 4px 0;
      }
    }
    &__right {
      width: 50%;
      margin-left: 20px;
      padding-left: 20px;
      border-left: 2px solid var(--light-gray-color);
      @media (max-width: 991px) {
        width: 65%;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 16px;
        padding-left: 0px;
        padding-top: 16px;
        border-left: 0px;
        border-top: 2px solid var(--light-gray-color);
      }
      &__title {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        word-break: break-word;
      }
      &__description {
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        color: var(--secondary-font-color);
        margin-top: 4px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
}
