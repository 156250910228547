@import "../../../assets/styles/global.scss";

.m-my-topic-right {
  margin-top: 51px;
  @media (max-width: 1367px) {
    margin-top: 0px;
  }
  .m-my-topic-right-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1367px) {
      max-width: 320px;
      margin: 0 auto;
    }
    .m-my-topic-right-topic-img-wrapper {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
    .m-my-topic-right-title-primary {
      font-family: "TTCommons-Medium";
      color: var(--primary-font-color);
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      margin: 0;
      padding-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px 0px 4px;
      padding: 4px 0px 0px;
    }
    .m-my-topic-right-description {
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 17px;
      text-align: center;
      margin-bottom: 15px;
    }
    .m-my-topic-right-created-by {
      color: #8094ab;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 17px;
      margin: 0px 0px 9px;
      span {
        color: var(--primary-font-color);
      }
    }
    .m-my-topic-right-type {
      height: auto;
      border-radius: 15px;
      background-color: #f1f4f7;
      color: var(--primary-font-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 17px;
      text-align: center;
      padding: 6px 10px 2px;
      min-width: 70px;
      text-transform: capitalize;
      margin: 0 0 15px 0;
    }
    .m-my-topic-right-participants-count {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 16px;
      padding: 5px 0px 0px;
      margin-bottom: 15px;
    }
    .m-my-topic-right-participants-wrapper {
      max-height: 435px;
      padding-right: 4px;
      @media (max-width: 1367px) {
        width: 100%;
      }
      &-overflow {
        overflow-y: scroll;
        overflow-x: hidden;
        margin-right: -6px;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-track {
          background: white;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.2);
          &:hover {
            background: #555;
          }
        }
      }
      .m-my-topic-right-participant {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        .m-my-topic-right-participant-text {
          flex-grow: 1;
          &__name {
            font-size: 15px;
            letter-spacing: 0px;
            line-height: 17px;
            display: block;
            margin: 3px 0 0 0;
            word-break: break-word;
          }
        }
        &__tag {
          white-space: nowrap;
          display: block;
          height: auto;
          border-radius: 10.5px;
          background-color: #f1f4f7;
          color: var(--primary-font-color);
          font-family: "TTCommons-Regular";
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 15px;
          text-align: center;
          padding: 6px 10px 2px;
          width: fit-content;
          text-transform: capitalize;
        }
      }
    }
    .m-my-topic-right-participants-button-wrapper {
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }
  }
}
