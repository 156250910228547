.mytopic {
  &__header {
    &__title {
      color: var(--primary-font-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      padding-top: 4px;
      word-break: break-word;
      margin: 0 0 -16px 0;
    }
  }
  &__categories {
    &__topic {
      display: flex;
      flex-direction: column;
      gap: 15px;
      position: relative;
      padding: 15px 0 15px 0;
      &__card {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 71px;
        border-radius: 35.5px;
        background-color: #ffffff !important;
        cursor: pointer;
        box-shadow: var(--box-shadow) !important;
        padding-inline: 14px !important;
        &:hover {
          box-shadow: var(-box-shadow-hover) !important;
        }
        &__avatar {
          .MuiAvatar-root {
            width: 45px;
            height: 45px;
          }
        }
        &__title {
          flex-grow: 1;
          &__container {
            &__topic-name {
              display: inline-block;
              padding-top: 2px;
              margin: 0 0 0 0;
              color: var(--primary-font-color);
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              &--active {
                color: #ffffff;
              }
            }
            .ambassador-tag-community {
              display: inline-block !important;
              > div {
                margin: 0 0 0 0 !important;
              }
            }
          }
        }
        &__notification {
          > div {
            background-color: #f7645f;
            color: #ffffff;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            height: 25px;
            span {
              padding: 2px 10px 0px 10px !important;
            }
          }
        }
        &__favorite {
          width: 20px !important;
          height: 20px !important;
          .filled-star,
          .border-star {
            width: 20px !important;
            height: 20px !important;
          }
        }
        &--active {
          background-color: var(--primary-color) !important;
        }
      }

      &__empty {
        padding: 20px !important;
        box-shadow: var(--box-shadow) !important;
        background: #ffffff !important;
        border-radius: 10px;
        margin-bottom: 16px;
        @media (max-width: 992px) {
          box-shadow: none !important;
        }
        h3 {
          color: var(--primary-font-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          margin: 4px 0 0 0;
        }
      }
    }
  }
}
