@import "../../../../assets/styles/global.scss";

.o-preview-profile-modal-wrapper {
  > div {
    > div {
      > div {
        max-height: calc(100vh - 50px);
        overflow-y: auto;
        /* scroll styling start */
        scrollbar-width: thin !important;
        -ms-overflow-style: auto;
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: $color-grey transparent; /* scroll thumb and track */
        transition: all ease-in-out 500ms;
        position: relative;
        &:hover {
          overflow-y: auto;
          &::-webkit-scrollbar {
            height: 50px;
            transition: all ease-in-out 500ms;
          }
        }
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          height: 50px;
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          margin: 10px 0 10px 0;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-grey !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 150px !important;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: $color-dark-gray !important; /* color of the scroll thumb */
        }
        /* scroll styling end */
        > div {
          padding: 25px !important; // Modal padding
        }
      }
    }
  }

  .o-preview-profile-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__image {
      margin-bottom: 20px;
    }
    &__name {
      margin-bottom: 7px;
      h2 {
        margin: 0;
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    &__account_type {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      > div {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 15px;
        line-height: 15px;
        span {
          padding-top: 5px;
        }
      }
    }
    &__education {
      margin-bottom: 16px;
      span {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
      }
    }
    &__location {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      span {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 15px;
        font-weight: 500;
        line-height: 17px;
        margin-top: 7px;
        margin-left: 10px;
      }
    }
    &__introduction {
      p {
        color: var(--primary-font-color);
        font-family: "TTCommons-Medium";
        font-size: 15px;
        font-weight: 500;
        line-height: 17px;
      }
    }
    > div:last-child {
      margin-bottom: 0px !important;
    }
  }
}
