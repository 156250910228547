@import "../../assets/styles/global.scss";

.main-layout-container {
  padding-top: 137px;
  @media (min-width: 480px) {
    padding-top: 134px;
  }
  @media (min-width: 498px) {
    padding-top: 96px;
  }
  @media (min-width: 767px) {
    padding-top: 101px;
  }
  @media (min-width: 993px) {
    padding-top: 60px;
  }
  @media (min-width: 993px) {
    padding-top: 56px;
  }
  @media (min-width: 1280px) {
    padding-top: 58px;
  }
  @media (max-width: 1170px) {
    padding-bottom: 20px;
  }
  &__content {
    margin: 0px auto;
    padding: 0px 40px;
    // position: relative;
    max-width: 1300px;
    margin: 0px auto;
    padding: 0 15px;
    @include lt-md() {
      margin: 0px 0px;
    }
    @media (max-width: 992px) {
      padding: 0 16px;
    }
  }
}
// .app-chat-container {
//   margin: 0px 0px;
//   max-width: 100% !important;
// }
